import { useCallback } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Skeleton, Stack } from '@mui/material';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';

import { STEP_DESCRIPTION_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

type StepDescriptionForm = {
  include_description: boolean;
  description: string;
};

const StepDescription = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_DESCRIPTION_FRAGMENT,
    fragmentName: 'StepDescription',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-description',
  );

  const stepDescriptionFormDefinition: FormDefinitionType<StepDescriptionForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'description',
          label: t('description'),
          type: 'rich-text',
          gridProps: { md: 12 },
        },
      ],
      [],
    );

  const update = useCallback(
    async (formData?: Partial<StepDescriptionForm>) => {
      await updateCmaReport({
        description: formData?.description ?? '',
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepDescriptionForm> = formData => {
    debouncedUpdate(formData);
  };

  const onSubmit = useCallback(
    async (formData: StepDescriptionForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  if (!complete) {
    return (
      <Stack sx={{ p: 2 }} justifyContent="start">
        <Skeleton height={240} sx={{ transform: 'none' }} />
      </Stack>
    );
  }

  return (
    <RaForm
      freezeInitialDefaultValues={true}
      formDefinition={stepDescriptionFormDefinition}
      defaultValues={{
        description: data?.description ?? '',
      }}
      onSubmit={onSubmit}
      onChange={onChangeHandler}
      actionButtonsComponent={<FooterActions {...props} updating={updating} />}
      contentScrollable
    />
  );
};

export default StepDescription;
