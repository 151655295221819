/**
 * @generated SignedSource<<c47c8ff4cd63ea30098de6d1e78fc3a6>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadAppraisalInheritanceNextStep = "buy" | "sell" | "share";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadAppraisalSeparationNextStep = "buy" | "sell" | "share";
export type LeadAppraisalStep = "address" | "asset_details" | "asset_type" | "result" | "user_details" | "verification";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadGender = "female" | "male";
export type LeadMortgageBuyingStatus = "identified" | "offer" | "searching" | "visited";
export type LeadMortgageInterestRateType = "fixed" | "variable";
export type LeadMortgageLenderType = "bank" | "insurance" | "pension_fund";
export type LeadMortgageStatus = "not_started" | "working_with_bank" | "working_with_independent";
export type LeadMortgageStep = "financials" | "result" | "situation" | "type" | "verification";
export type LeadOwnership = "co_ownership" | "corporate_ownership" | "sole_ownership";
export type LeadProfessionalSituation = "business_owner" | "employed" | "retired" | "self_employed" | "unemployed";
export type LeadPropertyUsage = "not_set" | "other" | "primary_residence" | "rental_property" | "secondary_residence";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type MortgageTrancheType = "fixed" | "variable";
export type PropertyCooling = "air_conditioning" | "ceiling_fans" | "none";
export type PropertyHeatingDistribution = "floor" | "forced_air" | "other" | "radiators" | "stove";
export type PropertyOrientation = "E" | "N" | "NE" | "NW" | "S" | "SE" | "SW" | "W";
export type PropertySearchNotification = "daily" | "never" | "weekly";
export type SubscriptionStatus = "active" | "canceled" | "incomplete" | "incomplete_expired" | "past_due" | "trialing" | "unpaid";
export type UserMaritalStatus = "cohabitation" | "divorced" | "married" | "separated" | "single" | "widowed";
export type UpsertLeadInput = {|
  clientMutationId?: ?string,
  lead?: ?LeadInput,
|};
export type LeadInput = {|
  accessComment?: ?string,
  accessStatus?: ?boolean,
  annualPremium?: ?number,
  appraisalInheritanceNextStep?: ?LeadAppraisalInheritanceNextStep,
  appraisalMarketTime?: ?string,
  appraisalMonthlyGrossRent?: ?number,
  appraisalNextStep?: ?string,
  appraisalOpenToOffer?: ?string,
  appraisalPerceivedValue?: ?number,
  appraisalPerception?: ?string,
  appraisalPropertyBalconySurface?: ?number,
  appraisalPropertyBuildingCondition?: ?number,
  appraisalPropertyBuiltSurface?: ?number,
  appraisalPropertyCommercialPotentialRentalIncome?: ?number,
  appraisalPropertyCommercialSurface?: ?number,
  appraisalPropertyCommercialYearlyRentalIncome?: ?number,
  appraisalPropertyConstructionYear?: ?number,
  appraisalPropertyCountry?: ?string,
  appraisalPropertyCountryCode?: ?string,
  appraisalPropertyCtn?: ?string,
  appraisalPropertyFloorOfFlat?: ?number,
  appraisalPropertyGardenSurface?: ?number,
  appraisalPropertyGoogleAddress?: ?GoogleAddressInput,
  appraisalPropertyGoogleAddressId?: ?string,
  appraisalPropertyHasElevator?: ?boolean,
  appraisalPropertyHasGarden?: ?boolean,
  appraisalPropertyHasStorageRoom?: ?boolean,
  appraisalPropertyHasSwimmingPool?: ?boolean,
  appraisalPropertyHasView?: ?boolean,
  appraisalPropertyIncomeIncludesParking?: ?boolean,
  appraisalPropertyIsCommercialFullyRented?: ?boolean,
  appraisalPropertyIsParkingFullyRented?: ?boolean,
  appraisalPropertyIsRenovated?: ?boolean,
  appraisalPropertyIsResidentialFullyRented?: ?boolean,
  appraisalPropertyKnowPlotNumber?: ?boolean,
  appraisalPropertyLandSurface?: ?number,
  appraisalPropertyLat?: ?number,
  appraisalPropertyLivingSurface?: ?number,
  appraisalPropertyLng?: ?number,
  appraisalPropertyLocality?: ?string,
  appraisalPropertyNumberOfBathrooms?: ?number,
  appraisalPropertyNumberOfBedrooms?: ?number,
  appraisalPropertyNumberOfCommercialUnits?: ?number,
  appraisalPropertyNumberOfFloors?: ?number,
  appraisalPropertyNumberOfIndoorParkings?: ?number,
  appraisalPropertyNumberOfOutdoorParkings?: ?number,
  appraisalPropertyNumberOfResidentialUnits?: ?number,
  appraisalPropertyNumberOfRooms?: ?number,
  appraisalPropertyOccupied?: ?string,
  appraisalPropertyParkingPotentialRentalIncome?: ?number,
  appraisalPropertyParkingYearlyRentalIncome?: ?number,
  appraisalPropertyPlotNumber?: ?string,
  appraisalPropertyPostcode?: ?string,
  appraisalPropertyPropertyTypeId?: ?string,
  appraisalPropertyRenovationYear?: ?number,
  appraisalPropertyResidentialPotentialRentalIncome?: ?number,
  appraisalPropertyResidentialSurface?: ?number,
  appraisalPropertyResidentialYearlyRentalIncome?: ?number,
  appraisalPropertyRoute?: ?string,
  appraisalPropertySide?: ?string,
  appraisalPropertyState?: ?string,
  appraisalPropertyStreetNumber?: ?string,
  appraisalPropertyUsage?: ?string,
  appraisalReason?: ?LeadAppraisalReason,
  appraisalSellingWith?: ?string,
  appraisalSeparationNextStep?: ?LeadAppraisalSeparationNextStep,
  appraisalStep?: ?LeadAppraisalStep,
  askingPrice?: ?number,
  assetsSecondPillar?: ?number,
  assetsThirdPillarAAnnualPremiumOrganization?: ?string,
  assetsThirdPillarAOrganisation?: ?string,
  brokerId?: ?string,
  buyHorizon?: ?LeadBuyHorizon,
  callTimes?: ?$ReadOnlyArray<?string>,
  cantonalPlanComment?: ?string,
  cantonalPlanStatus?: ?boolean,
  comment?: ?string,
  completed?: ?boolean,
  constructionPermitComment?: ?string,
  constructionPermitStatus?: ?boolean,
  contactId?: ?string,
  dateOfBirth?: ?string,
  deadline?: ?string,
  debt?: ?number,
  debts?: ?$ReadOnlyArray<?LeadDebtInput>,
  donation?: ?number,
  emailAddress?: ?string,
  financingSecured?: ?boolean,
  firstName?: ?string,
  gender?: ?LeadGender,
  groundComment?: ?string,
  groundStatus?: ?boolean,
  hasAcceptedTerms?: ?boolean,
  hasDependantChildren?: ?boolean,
  hasNoStreetNumber?: ?boolean,
  hasOtherCredits?: ?boolean,
  id?: ?string,
  inventoryProtectionComment?: ?string,
  inventoryProtectionStatus?: ?boolean,
  landingPageUrl?: ?string,
  lastBonus?: ?number,
  lastName?: ?string,
  localityPlanComment?: ?string,
  localityPlanStatus?: ?boolean,
  mandateProbability?: ?number,
  maritalStatus?: ?string,
  maxPropertyValue?: ?number,
  mortgageAutoMortgageType?: ?boolean,
  mortgageBuyingStatus?: ?LeadMortgageBuyingStatus,
  mortgageComments?: ?string,
  mortgageFinancialInstitution?: ?string,
  mortgageGoogleAddress?: ?GoogleAddressInput,
  mortgageGoogleAddressId?: ?string,
  mortgageHasMortgage?: ?boolean,
  mortgageInsuranceProviderId?: ?string,
  mortgageInsuranceRevenue?: ?number,
  mortgageInterestRate?: ?number,
  mortgageInterestRateType?: ?LeadMortgageInterestRateType,
  mortgageIsConstruction?: ?boolean,
  mortgageIsPropertyIdentified?: ?boolean,
  mortgageLenderId?: ?string,
  mortgageLenderName?: ?string,
  mortgageLenderType?: ?LeadMortgageLenderType,
  mortgageMortgageType?: ?string,
  mortgageNotaryId?: ?string,
  mortgageProductMortgage?: ?boolean,
  mortgageProductThirdPillar?: ?boolean,
  mortgagePropertyUsage?: ?string,
  mortgagePropertyValue?: ?number,
  mortgagePurchaseDate?: ?string,
  mortgagePurchasePrice?: ?number,
  mortgageRealEstateBrokerId?: ?string,
  mortgageReportType?: ?string,
  mortgageRevenue?: ?number,
  mortgageSell?: ?boolean,
  mortgageStartDate?: ?string,
  mortgageStatus?: ?LeadMortgageStatus,
  mortgageStep?: ?LeadMortgageStep,
  mortgageTerm?: ?string,
  nationalityId?: ?string,
  neighbourhoodPlanComment?: ?string,
  neighbourhoodPlanStatus?: ?boolean,
  neighbourhoodRelations?: ?string,
  noiseComment?: ?string,
  noiseStatus?: ?boolean,
  numberOfBuyers?: ?number,
  numberOfDependantChildren?: ?number,
  occupationId?: ?string,
  occupationText?: ?string,
  otherCredits?: ?number,
  ownership?: ?LeadOwnership,
  parentLeadId?: ?string,
  partnerAnnualPremium?: ?number,
  partnerDateOfBirth?: ?string,
  partnerDonation?: ?number,
  partnerFirstName?: ?string,
  partnerGender?: ?LeadGender,
  partnerHasOtherCredits?: ?boolean,
  partnerHasThirdPillar?: ?boolean,
  partnerLastBonus?: ?number,
  partnerLastName?: ?string,
  partnerMaritalStatus?: ?UserMaritalStatus,
  partnerNationalityId?: ?string,
  partnerOccupationText?: ?string,
  partnerOtherCredits?: ?number,
  partnerProfessionalSituation?: ?LeadProfessionalSituation,
  partnerResidenceCountryCode?: ?string,
  partnerResidenceStateId?: ?string,
  partnerSavings?: ?number,
  partnerSecondPillar?: ?number,
  partnerThirdPillar?: ?number,
  partnerThirdPillarAOrganisation?: ?string,
  partnerUseBankThirdPillar?: ?boolean,
  partnerUseInsuranceThirdPillar?: ?boolean,
  partnerWorkPermitId?: ?string,
  partnerYearlyRevenues?: ?number,
  partnerYearlyRevenuesAtRetirement?: ?number,
  perceivedValueAgent?: ?number,
  phoneNumber?: ?string,
  pollutionComment?: ?string,
  pollutionStatus?: ?boolean,
  potentialDevelopment?: ?boolean,
  predictedListingDate?: ?string,
  professionalSituation?: ?LeadProfessionalSituation,
  property?: ?PropertyInput,
  propertySearchNotification?: ?PropertySearchNotification,
  propertyUsage?: ?LeadPropertyUsage,
  receivedMortgageOffer?: ?boolean,
  referrer?: ?string,
  relationship?: ?LeadRelationship,
  residenceCountryCode?: ?string,
  residenceStateId?: ?string,
  saleComments?: ?string,
  saleHasFound?: ?boolean,
  saleHorizon?: ?LeadSaleHorizon,
  saleIsSeller?: ?boolean,
  salePrice?: ?number,
  saleStarted?: ?boolean,
  savings?: ?number,
  secondContactId?: ?string,
  secondPillar?: ?number,
  servitudesComment?: ?string,
  servitudesStatus?: ?boolean,
  source?: ?string,
  sourceId?: ?string,
  sourceOrganisationId?: ?string,
  stageId?: ?string,
  stepsTaken?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  thirdPillar?: ?number,
  treesComment?: ?string,
  treesStatus?: ?boolean,
  useBankThirdPillar?: ?boolean,
  useInsuranceThirdPillar?: ?boolean,
  useSecondPillar?: ?boolean,
  useThirdPillar?: ?boolean,
  userInfoIp?: ?string,
  userInfoUserAgent?: ?string,
  workPermitId?: ?string,
  yearlyRevenues?: ?number,
  yearlyRevenuesAtRetirement?: ?number,
|};
export type PropertyInput = {|
  balconySurface?: ?number,
  basementSurface?: ?number,
  borrowerLeadId?: ?string,
  buildingCondition?: ?number,
  buildingStandard?: ?number,
  buildingVolume?: ?number,
  buildingVolumeNorm?: ?string,
  builtSurface?: ?number,
  ceilingHeight?: ?number,
  charges?: ?number,
  commercialPotentialRentalIncome?: ?number,
  commercialSurface?: ?number,
  commercialYearlyRentalIncome?: ?number,
  constructionYear?: ?number,
  consumption?: ?number,
  cooling?: ?PropertyCooling,
  country?: ?string,
  countryCode?: ?string,
  createdBy?: ?string,
  ctn?: ?string,
  deletedByUser?: ?boolean,
  distanceToAirport?: ?number,
  distanceToCityCenter?: ?number,
  distanceToGroceryShop?: ?number,
  distanceToHighSchool?: ?number,
  distanceToHospital?: ?number,
  distanceToKindergarten?: ?number,
  distanceToMotorway?: ?number,
  distanceToPrimarySchool?: ?number,
  distanceToPublicTransport?: ?number,
  distanceToSportsCenter?: ?number,
  distanceToTrainStation?: ?number,
  emissions?: ?number,
  energyCertificateVersion?: ?string,
  energyConsumptionClass?: ?string,
  energyInspectionDate?: ?string,
  energyReferenceYear?: ?number,
  estimatedHigherEnergyCost?: ?number,
  estimatedLowerEnergyCost?: ?number,
  financingInstitutionId?: ?string,
  financingLeadId?: ?string,
  flatCondition?: ?number,
  floorOfFlat?: ?number,
  gardenSurface?: ?number,
  gasEmissionsClass?: ?string,
  googleAddressId?: ?string,
  grossFloorSurface?: ?number,
  hasAttic?: ?boolean,
  hasCellar?: ?boolean,
  hasElevator?: ?boolean,
  hasFireplace?: ?boolean,
  hasGym?: ?boolean,
  hasPhotovoltaicPanels?: ?boolean,
  hasSauna?: ?boolean,
  hasStorageRoom?: ?boolean,
  hasSwimmingPool?: ?boolean,
  hasView?: ?boolean,
  hasWellnessArea?: ?boolean,
  heating?: ?string,
  heatingDistribution?: ?PropertyHeatingDistribution,
  id?: ?string,
  incomeIncludesParking?: ?boolean,
  isChildFriendly?: ?boolean,
  isCommercialFullyRented?: ?boolean,
  isLuxury?: ?boolean,
  isMaisonette?: ?boolean,
  isParkingFullyRented?: ?boolean,
  isRental?: ?boolean,
  isResidentialFullyRented?: ?boolean,
  isSecondaryResidence?: ?boolean,
  isWheelchairAccessible?: ?boolean,
  landSurface?: ?number,
  lat?: ?number,
  livingSurface?: ?number,
  lng?: ?number,
  locality?: ?string,
  localityId?: ?string,
  macroLocation?: ?number,
  microLocation?: ?number,
  minergieCode?: ?number,
  monthlyGrossRent?: ?number,
  mortgage?: ?MortgageInput,
  numberOfBathrooms?: ?number,
  numberOfBedrooms?: ?number,
  numberOfCommercialUnits?: ?number,
  numberOfFlats?: ?number,
  numberOfFloors?: ?number,
  numberOfIndoorParkings?: ?number,
  numberOfLifts?: ?number,
  numberOfOutdoorParkings?: ?number,
  numberOfResidentialUnits?: ?number,
  numberOfRooms?: ?number,
  numberOfToilets?: ?number,
  orientation?: ?PropertyOrientation,
  ownersIds?: ?$ReadOnlyArray<?string>,
  parkingPotentialRentalIncome?: ?number,
  parkingYearlyRentalIncome?: ?number,
  perceivedValue?: ?number,
  plotHasConstructibleReserve?: ?boolean,
  plotIsConstructible?: ?boolean,
  plotNumber?: ?string,
  postcode?: ?string,
  propertyTypeId?: ?string,
  propertyTypeName?: ?string,
  relevantServitudes?: ?boolean,
  renovationFund?: ?number,
  renovationYear?: ?number,
  residentialPotentialRentalIncome?: ?number,
  residentialSurface?: ?number,
  residentialYearlyRentalIncome?: ?number,
  route?: ?string,
  shareOfOwnership?: ?number,
  side?: ?string,
  state?: ?string,
  streetNumber?: ?string,
  subLocality?: ?string,
  terraceSurface?: ?number,
  usableSurface?: ?number,
  weightedFloorSurface?: ?number,
|};
export type MortgageInput = {|
  amortisation?: ?number,
  annualRentalIncome?: ?number,
  bankerId?: ?string,
  borrowerPropertyLocation?: ?string,
  expiryDate?: ?string,
  id?: ?string,
  interestRate?: ?number,
  lender?: ?string,
  lenderId?: ?string,
  loanAmount?: ?number,
  mortgageBrokerId?: ?string,
  property?: ?PropertyInput,
  propertyId?: ?string,
  signedAt?: ?string,
  startDate?: ?string,
  tranches?: ?$ReadOnlyArray<?MortgageTrancheInput>,
  tranchesExist?: ?boolean,
  type?: ?MortgageTrancheType,
  value?: ?number,
|};
export type MortgageTrancheInput = {|
  amortisation?: ?number,
  expiryDate?: ?string,
  fixedFor?: ?number,
  interestRate?: ?number,
  loanAmount?: ?number,
  startDate?: ?string,
  type?: ?MortgageTrancheType,
|};
export type GoogleAddressInput = {|
  country?: ?string,
  countryCode?: ?string,
  ctn?: ?string,
  formattedAddress?: ?string,
  googlePlace?: ?GooglePlaceInput,
  googlePlaceId?: ?string,
  id?: ?string,
  lat?: ?number,
  lng?: ?number,
  locality?: ?string,
  postcode?: ?string,
  route?: ?string,
  state?: ?string,
  streetNumber?: ?string,
|};
export type GooglePlaceInput = {|
  address_components: $ReadOnlyArray<?GooglePlaceAddressComponents>,
  formatted_address?: ?string,
  geometry?: ?GooglePlaceGeometry,
  partial_match?: ?boolean,
  place_id?: ?string,
  plus_code?: ?PlusCode,
  types?: ?$ReadOnlyArray<?string>,
|};
export type GooglePlaceAddressComponents = {|
  long_name: string,
  short_name: string,
  types: $ReadOnlyArray<string>,
|};
export type GooglePlaceGeometry = {|
  bounds?: ?GooglePlaceGeometryGeometryBounds,
  location?: ?GooglePlaceGeometryLocation,
  location_type?: ?string,
  viewport?: ?GooglePlaceGeometryGeometryViewport,
|};
export type GooglePlaceGeometryGeometryBounds = {|
  east?: ?number,
  north?: ?number,
  northeast?: ?GooglePlaceGeometryBoundsNortheast,
  south?: ?number,
  southwest?: ?GooglePlaceGeometryBoundsSouthwest,
  west?: ?number,
|};
export type GooglePlaceGeometryBoundsNortheast = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryBoundsSouthwest = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryLocation = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryGeometryViewport = {|
  east?: ?number,
  north?: ?number,
  northeast?: ?GooglePlaceGeometryViewportNortheast,
  south?: ?number,
  southwest?: ?GooglePlaceGeometryViewportSouthwest,
  west?: ?number,
|};
export type GooglePlaceGeometryViewportNortheast = {|
  lat?: ?number,
  lng?: ?number,
|};
export type GooglePlaceGeometryViewportSouthwest = {|
  lat?: ?number,
  lng?: ?number,
|};
export type PlusCode = {|
  compound_code?: ?string,
  global_code?: ?string,
|};
export type LeadDebtInput = {|
  debt?: ?number,
  expiration?: ?string,
  id?: ?string,
  leadId?: ?string,
  rate?: ?number,
|};
export type leadInputUpsertLeadMutation$variables = {|
  input: UpsertLeadInput,
|};
export type leadInputUpsertLeadMutation$data = {|
  +upsertLead: ?{|
    +lead: ?{|
      +broker: ?{|
        +firstName: ?string,
        +id: string,
        +lastName: ?string,
        +organisation: ?{|
          +formattedAddress: ?string,
          +name: ?string,
        |},
        +primaryEmail: ?{|
          +email: string,
        |},
        +primaryImage: ?{|
          +url: string,
        |},
        +subscription: ?{|
          +status: SubscriptionStatus,
        |},
      |},
      +contact: ?{|
        +firstName: ?string,
        +id: string,
        +lastName: ?string,
        +organisation: ?{|
          +formattedAddress: ?string,
          +name: ?string,
        |},
        +primaryEmail: ?{|
          +email: string,
        |},
        +primaryImage: ?{|
          +url: string,
        |},
        +subscription: ?{|
          +status: SubscriptionStatus,
        |},
      |},
      +id: string,
      +property: ?{|
        +formattedAddress: ?string,
      |},
    |},
  |},
|};
export type leadInputUpsertLeadMutation = {|
  response: leadInputUpsertLeadMutation$data,
  variables: leadInputUpsertLeadMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v9/*: any*/)
    ],
    "storageKey": null
  }
],
v11 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Email",
    "kind": "LinkedField",
    "name": "primaryEmail",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Image",
    "kind": "LinkedField",
    "name": "primaryImage",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "organisation",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "subscription",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "leadInputUpsertLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertLeadPayload",
        "kind": "LinkedField",
        "name": "upsertLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "lead",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "broker",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "leadInputUpsertLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertLeadPayload",
        "kind": "LinkedField",
        "name": "upsertLead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Lead",
            "kind": "LinkedField",
            "name": "lead",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Property",
                "kind": "LinkedField",
                "name": "property",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "broker",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8598119af4dfb370b2bfd72afff5f527",
    "id": null,
    "metadata": {},
    "name": "leadInputUpsertLeadMutation",
    "operationKind": "mutation",
    "text": "mutation leadInputUpsertLeadMutation(\n  $input: UpsertLeadInput!\n) {\n  upsertLead(input: $input) {\n    lead {\n      id\n      property {\n        formattedAddress\n        id\n      }\n      contact {\n        id\n        firstName\n        lastName\n        primaryEmail {\n          email\n          id\n        }\n        primaryImage {\n          url\n          id\n        }\n        organisation {\n          name\n          formattedAddress\n          id\n        }\n        subscription {\n          status\n          id\n        }\n      }\n      broker {\n        id\n        firstName\n        lastName\n        primaryEmail {\n          email\n          id\n        }\n        primaryImage {\n          url\n          id\n        }\n        organisation {\n          name\n          formattedAddress\n          id\n        }\n        subscription {\n          status\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "149f48a96729116f64e4fdb3bbaf0e3d";

export default node;
