/**
 * @generated SignedSource<<73415b54ee3bc5dce835f70bebb9ac55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
import { FragmentRefs } from "relay-runtime";
export type leadQualificationCard_root$data = {
  readonly mandateProbabilityTypes: ReadonlyArray<{
    readonly label: string;
    readonly name: string;
  }>;
  readonly me: {
    readonly id: string;
    readonly modules: ReadonlyArray<string>;
  } | null;
  readonly pipelinesStages: ReadonlyArray<{
    readonly id: string;
    readonly label: string | null;
    readonly stages: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly name: string | null;
      readonly status: LeadStageStatus;
    }>;
  }>;
  readonly tagsTypes: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "leadQualificationCard_root";
};
export type leadQualificationCard_root$key = {
  readonly " $data"?: leadQualificationCard_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadQualificationCard_root">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadQualificationCard_root",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modules",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "mandateProbabilityTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "mandate_probability_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": "dictionaries(type:\"mandate_probability_types\")"
    },
    {
      "alias": "tagsTypes",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "tags_types"
        }
      ],
      "concreteType": "Dictionary",
      "kind": "LinkedField",
      "name": "dictionaries",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": "dictionaries(type:\"tags_types\")"
    },
    {
      "alias": "pipelinesStages",
      "args": [
        {
          "kind": "Literal",
          "name": "leadType",
          "value": "sales"
        }
      ],
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipelines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadStage",
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "pipelines(leadType:\"sales\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "3178089621556f9583a8d78e2d2fe8f6";

export default node;
