import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';

import { useCMAEditor } from './CMAReportEditorWorkflow';
import StepperListItem from './StepperListItem';

type CMAReportEditorStepperProps = {
  children: ReactNode;
  setIsStepComponentExpanded: (expanded: boolean) => void;
  isStepComponentExpanded: boolean;
};

const CMAReportEditorStepper = ({
  setIsStepComponentExpanded,
  isStepComponentExpanded,
  children,
}: CMAReportEditorStepperProps) => {
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('lg'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [isExpanded, setIsExpanded] = useState(!isTablet);
  const { t } = useLocale();

  const { steps, currentStepData } = useCMAEditor();

  const { mainSteps, footerSteps } = useMemo(() => {
    const mainSteps = steps.filter(step => step.position === 'main');
    const footerSteps = steps.filter(step => step.position === 'footer');
    return { mainSteps, footerSteps };
  }, [steps]);

  useEffect(() => {
    if (isTablet) {
      setIsExpanded(false);
    }
  }, [isTablet]);

  const handleExpandToggle = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  // If mobile, we don't display vertical stepper, just the step component
  if (isMobile) {
    return children;
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Stack
        sx={{
          width: isExpanded ? 280 : 73,
          height: '100%',
          minHeight: 0,
          flexShrink: 0,
          borderRight: '1px solid',
          borderColor: 'divider',
          transition: 'width 0.2s ease',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            pl: isExpanded ? 3.5 : 0,
            pr: isExpanded ? 1.25 : 0,
            borderBottom: '1px solid',
            borderColor: 'divider',
            height: 57,
          }}
        >
          {isExpanded && (
            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
              {t('Page')}
            </Typography>
          )}
          <IconButton
            onClick={handleExpandToggle}
            sx={{ ml: 'auto', mr: isExpanded ? 0 : 'auto' }}
          >
            {isExpanded ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>

        <Stack justifyContent="space-between" flexGrow={1}>
          <Box sx={{ overflow: 'auto', height: 0, flexGrow: 1 }}>
            {mainSteps.map((item, index) => (
              <StepperListItem
                key={item.pageId}
                item={item}
                index={index}
                isExpanded={isExpanded}
                isActive={currentStepData.pageId === item.pageId}
                isStepComponentExpanded={isStepComponentExpanded}
                setIsStepComponentExpanded={setIsStepComponentExpanded}
              />
            ))}
          </Box>
          <Box>
            {footerSteps.map((item, index) => (
              <StepperListItem
                key={index}
                item={item}
                index={mainSteps.length + index}
                isExpanded={isExpanded}
                isActive={currentStepData.pageId === item.pageId}
                isStepComponentExpanded={isStepComponentExpanded}
                setIsStepComponentExpanded={setIsStepComponentExpanded}
              />
            ))}
          </Box>
        </Stack>
      </Stack>

      {children}
    </Box>
  );
};

export default CMAReportEditorStepper;
