import { useCallback, useState } from 'react';

import { type ApolloError, useMutation } from '@apollo/client';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import Save from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { fromGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  Activity_Types_Enum_Enum,
  type AssignBrokerWithActivitiesMutation,
} from '../../__generated__/graphql';
import { MutationErrorSnackbar } from '../../components/MutationErrorModal';
import { UserSelect } from '../../components/user-select/UserSelect';

type AssignBrokerDialogProps = {
  leadsIds: string[];
  open: boolean;
  onCancel: () => void;
  isLegacy?: boolean;
};

const ASSIGN_BROKER_WITH_ACTIVITIES = gql(`
  mutation AssignBrokerWithActivities(
    $broker_id: uuid!
    $leads_ids: [uuid!]!
    $activities: [activities_insert_input!]!
  ) {
    update_leads(
      where: { id: { _in: $leads_ids } }
      _set: { broker_id: $broker_id, claimed_by: null }
    ) {
      affected_rows
      returning {
        id
        broker {
          id
          first_name
          last_name
          emails {
            email
          }
        }
      }
    }

    update_activities(
      where: {
        lead_id: { _in: $leads_ids }
        activity_type: { _eq: assignment }
        done: { _eq: false }
      }
      _set: { done: true, success: false, done_at: "now()" }
    ) {
      affected_rows
    }

    insert_activities(objects: $activities) {
      affected_rows
      returning {
        id
        lead_id
      }
    }
  }
`);

const DialogAssignBroker = ({
  leadsIds,
  open,
  onCancel,
  isLegacy,
}: AssignBrokerDialogProps) => {
  const { t } = useLocale();
  const [brokerId, setBrokerId] = useState<string | null>(null);
  const [error, setError] = useState<null | ApolloError>(null);

  const [assignBrokerWithActivities, { loading: assigning }] =
    useMutation<AssignBrokerWithActivitiesMutation>(
      ASSIGN_BROKER_WITH_ACTIVITIES,
      { onError: setError },
    );

  const handleConfirm = useCallback(async () => {
    if (!brokerId) {
      return;
    }

    try {
      const activities = leadsIds.map(leadId => ({
        lead_id: isLegacy ? fromGlobalId(leadId) : leadId,
        assigned_to: brokerId,
        activity_type: Activity_Types_Enum_Enum.Assignment,
        done: false,
        due_at: 'now()',
      }));

      const result = await assignBrokerWithActivities({
        variables: {
          broker_id: brokerId,
          leads_ids: isLegacy
            ? leadsIds.map(leadId => fromGlobalId(leadId))
            : leadsIds,
          activities,
        },
      });

      if ((result.data?.update_leads?.affected_rows ?? 0) > 0) {
        onCancel();
      }
    } catch (error) {
      console.error('Error assigning broker:', error);
    }
  }, [brokerId, leadsIds, assignBrokerWithActivities, isLegacy, onCancel]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onCancel}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 500,
            margin: '0 auto',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AssignmentIndOutlinedIcon />
          {t('Assign this lead')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText pb={1}>
            {t('Please select a broker to assign these leads to')}
          </DialogContentText>
          <UserSelect
            autoFocus
            onChange={setBrokerId}
            filters={{
              is_broker: { _eq: true },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 2, px: 3 }}>
          <Button onClick={onCancel}>{t('Cancel')}</Button>
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={brokerId == null}
            loadingPosition="start"
            startIcon={<Save />}
            loading={assigning}
            onClick={handleConfirm}
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <MutationErrorSnackbar error={error} onClose={() => setError(null)} />
    </>
  );
};

export default DialogAssignBroker;
