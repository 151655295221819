import * as React from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  MenuItem,
} from '@material-ui/core';
import { useConstant } from '@realadvisor/hooks';
import { endOfDay, formatDistanceStrict, isToday } from 'date-fns';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Box, Flex } from 'react-system';

import IndicatorLeadStage from '../../../apollo/components/IndicatorLeadStage';
import {
  type ColumnConfig,
  InfiniteTable,
} from '../../controls/infinite-table';
import { Menu } from '../../controls/popup';
import { Subscription } from '../../controls/subscription';
import { useLocale } from '../../hooks/locale';
import { useSet } from '../../hooks/set';
import { type Theme, useTheme } from '../../hooks/theme';
import { ArrowDropDown } from '../../icons/arrow-drop-down';
import { AssignmentInd } from '../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../icons/assignment-turned-in';
import { Brightness1 } from '../../icons/brightness-1';
import { MeetingRoom } from '../../icons/meeting-room';
import { NoMeetingRoom } from '../../icons/no-meeting-room';
import { NoteOutline } from '../../icons/note-outline';
import { Phone } from '../../icons/phone';
import { PhoneMissed } from '../../icons/phone-missed';
import { RoundWarning } from '../../icons/round-warning';
import { LeadCreateDrawer } from '../../shared/lead-create-drawer';
import { LeadDrawer } from '../../shared/lead-drawer';
import { LeadUpdatedTimeAgo } from '../../shared/lead-updated-time-ago';
import { LeadsActionsMenu } from '../../shared/leads-actions-menu';
import { TopbarTitle } from '../../shared/topbar';
import { UserInfo } from '../../shared/user-info';
import {
  getHorizonLabel,
  translateAppraisalReason,
  translateMortgageBuyingStatus,
  translateMortgageStatus,
  translateReceivedMortgageOffer,
} from '../../utils/lead-labels';
import { formatNumberByPrecision } from '../../utils/number-format';

import type {
  Leads_root$data,
  Leads_root$key,
} from './__generated__/Leads_root.graphql';
import type { LeadsAll_root$key } from './__generated__/LeadsAll_root.graphql';
import type { LeadsAllPaginationQuery } from './__generated__/LeadsAllPaginationQuery.graphql';
import type { LeadsAllQuery } from './__generated__/LeadsAllQuery.graphql';
import type { LeadsPaginationQuery } from './__generated__/LeadsPaginationQuery.graphql';
import type { LeadsPipelinesQuery } from './__generated__/LeadsPipelinesQuery.graphql';
import type { LeadsQuery } from './__generated__/LeadsQuery.graphql';
import { LeadsKanban } from './leads-kanban';
import { LeadsMap } from './leads-map';
import {
  LeadsFilters,
  type LeadsParams,
  paramsToFilters,
  useLeadsParams,
} from './LeadsFilters';

type LeadsNode = NonNullable<
  NonNullable<
    NonNullable<NonNullable<Leads_root$data['leads']>['edges']>[number]
  >['node']
>;

const ActivityCell = ({
  node,
  type,
}: {
  node: LeadsNode;
  type: 'next' | 'latest';
}) => {
  const { t, dateLocale } = useLocale();
  const { colors, text } = useTheme();
  let activity: LeadsNode['nextActivity'] | LeadsNode['latestActivity'] =
    node.nextActivity;
  if (type === 'latest') {
    activity = node.latestActivity;
  }
  if (activity == null || activity.__typename === '%other') {
    return (
      <Flex alignItems="center">
        <RoundWarning size={18} fill={colors.error} css={{ marginRight: 4 }} />
        {t('noActivity')}
      </Flex>
    );
  }
  let overdue = false;
  if (type === 'next' && activity.__typename !== 'ActivityNote') {
    overdue = activity.overdue ?? false;
  }

  let dueAt = null;

  if (activity.doneAt != null) {
    if (isToday(new Date(activity.doneAt))) {
      dueAt = t('Today');
    } else if (activity.doneAt != null) {
      dueAt = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.doneAt)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (
    activity.__typename !== 'ActivityNote' &&
    activity.startDate != null
  ) {
    if (isToday(new Date(activity.startDate))) {
      dueAt = t('Today');
    } else if (activity.startDate != null) {
      dueAt = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.startDate)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  } else if (activity.__typename !== 'ActivityNote' && activity.dueAt != null) {
    dueAt = (
      <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
        {formatDistanceStrict(new Date(activity.dueAt), Date.now(), {
          locale: dateLocale,
          addSuffix: true,
        })}
      </div>
    );
  } else if (activity.createdAt != null) {
    if (isToday(new Date(activity.createdAt))) {
      dueAt = t('Today');
    } else if (activity.createdAt != null) {
      dueAt = (
        <div css={{ color: overdue ? colors.errorText : colors.grey700 }}>
          {formatDistanceStrict(
            endOfDay(new Date(activity.createdAt)),
            Date.now(),
            {
              locale: dateLocale,
              addSuffix: true,
            },
          )}
        </div>
      );
    }
  }

  const overdueStyles = overdue ? { color: colors.errorText } : null;

  if (activity.__typename === 'ActivityTask') {
    const { subject } = activity;
    return (
      <Box css={overdueStyles} title={subject ?? ''}>
        <Flex alignItems="center">
          <AssignmentTurnedIn
            fill={overdue ? colors.error : colors.mediumText}
            size={18}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {subject == null || subject.trim() === '' ? t('task') : subject}
          </div>
        </Flex>
        {dueAt}
      </Box>
    );
  }

  if (activity.__typename === 'ActivityCall') {
    const { note, success, done } = activity;
    const Icon = success === false && done === true ? PhoneMissed : Phone;
    return (
      <div css={overdueStyles} title={note ?? ''}>
        <Flex alignItems="center">
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('call') : note}
          </div>
        </Flex>
        {dueAt}
      </div>
    );
  }

  if (activity.__typename === 'ActivityVisit') {
    const { note, success, done } = activity;
    const Icon =
      success === false && done === true ? NoMeetingRoom : MeetingRoom;
    return (
      <div css={overdueStyles}>
        <Flex alignItems="center" css={text.ellipsis}>
          <Icon
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('visit') : note}
          </div>
        </Flex>
        {dueAt}
      </div>
    );
  }

  if (activity.__typename === 'ActivityNote') {
    const { note } = activity;
    return (
      <div css={overdueStyles}>
        <Flex alignItems="center" css={text.ellipsis}>
          <NoteOutline
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          <div css={[text.truncate(1), text.ellipsis]}>
            {note == null || note.trim() === '' ? t('Note') : note}
          </div>
        </Flex>
        {dueAt}
      </div>
    );
  }

  if (activity.__typename === 'ActivityAssignment') {
    return (
      <div css={overdueStyles}>
        <Flex alignItems="center">
          <AssignmentInd
            size={18}
            fill={overdue ? colors.error : colors.mediumText}
            css={{ marginRight: 4 }}
          />
          {type === 'latest' ? t('leadClaimed') : t('claimLead')}
        </Flex>
        {dueAt}
      </div>
    );
  }

  return null;
};

export const getAppraisalPerception = (
  colors: Theme['colors'],
  appraisalPerception: null | string,
  appraisalPerceivedValue: null | number,
  max: null | number,
  min: null | number,
) => {
  if (
    appraisalPerception === 'just_right' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      max != null &&
      min <= appraisalPerceivedValue &&
      max >= appraisalPerceivedValue)
  ) {
    return '👍';
  } else if (
    appraisalPerception === 'too_low' ||
    (appraisalPerceivedValue != null &&
      max != null &&
      max < appraisalPerceivedValue)
  ) {
    return <RoundWarning fill={colors.error} />;
  } else if (
    appraisalPerception === 'too_high' ||
    (appraisalPerceivedValue != null &&
      min != null &&
      min > appraisalPerceivedValue)
  ) {
    return <Brightness1 fill={colors.success} />;
  } else {
    return '';
  }
};

const useColumns = ({ isAdmin }: { isAdmin: boolean }) => {
  const { t, locale } = useLocale();
  const { text, colors, textColor } = useTheme();

  const formatValues = (
    realadvisor: NonNullable<
      NonNullable<LeadsNode['property']>['latestAppraisal']
    >['realadvisor'],
  ) => {
    if (!realadvisor || realadvisor.min == null || realadvisor.max == null) {
      return '-';
    }

    let min = realadvisor.min / 1000;
    let max = realadvisor.max / 1000;
    let minUnit = 'k';
    let maxUnit = 'k';
    let minString = '';
    let maxString = '';

    if (min < 1000) {
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (max < 1000) {
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    }

    if (min >= 1000) {
      min = min / 1000;
      minString = min.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      minUnit = 'm';
    }

    if (max >= 1000) {
      max = max / 1000;
      maxString = max.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      maxUnit = 'm';
    }

    return `${minString}${minUnit} - ${maxString}${maxUnit}`;
  };

  const columns: ColumnConfig<LeadsNode>[] = [
    {
      label: t('createdAtOrRequalifiedAt'),
      width: 12,
      sortBy: 'createdOrRequalified',
      cellRenderer: ({ node }) => (
        <LeadUpdatedTimeAgo
          shortVersion={true}
          lead={node}
          showBothDate={isAdmin}
          suffixLabel={node.source.label}
        />
      ),
    },

    {
      label: t('contact'),
      width: 20,
      grow: 2,
      sortBy: 'contact_firstName|contact_lastName',
      cellRenderer: ({ node }) =>
        node.contact != null && (
          <UserInfo
            user={node.contact}
            referrer={node.contact.referrer}
            showSensitiveData={node.userCanViewLeadDetails}
          />
        ),
    },

    {
      label: t('address'),
      width: 18,
      grow: 2,
      cellRenderer: ({ node }) => {
        const googleAddress = node.property?.googleAddress;
        const state = node.property?.state ?? googleAddress?.state;
        const postcode = node.property?.postcode ?? googleAddress?.postcode;
        const locality = node.property?.locality ?? googleAddress?.locality;
        const route = node.property?.route ?? googleAddress?.route;
        const streetNumber =
          node.property?.streetNumber ?? googleAddress?.streetNumber;
        return (
          <div css={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>
            {node.userCanViewLeadDetails === true && (
              <div>{[route, ' ', streetNumber]}</div>
            )}
            <div css={[text.caption, textColor('mediumText')]}>
              {[postcode, ' ', locality, ' ', state]}
            </div>
          </div>
        );
      },
    },

    {
      width: 6,
      alignment: 'right',
      cellRenderer: ({ node }) => (
        <div css={{ fontSize: 20 }}>
          {getAppraisalPerception(
            colors,
            node.appraisalPerception,
            node.appraisalPerceivedValue,
            node.property?.latestAppraisal?.realadvisor?.max ?? null,
            node.property?.latestAppraisal?.realadvisor?.min ?? null,
          )}
        </div>
      ),
    },

    {
      label: t('value'),
      width: 20,
      sortBy: 'value',
      cellRenderer: ({ node }) => (
        <div>
          <div>
            {formatValues(node.property?.latestAppraisal?.realadvisor ?? null)}
          </div>
          <div css={[text.caption, textColor('mediumText')]}>
            {node.appraisalPerception != null
              ? node.appraisalPerception
              : formatNumberByPrecision(node.appraisalPerceivedValue, locale)}
          </div>
        </div>
      ),
    },

    {
      label: t('intention'),
      width: 20,
      cellRenderer: ({ node }) => {
        const horisonLabel = getHorizonLabel(t, {
          appraisalReason: node.appraisalReason,
          saleHorizon: node.saleHorizon,
          buyHorizon: node.buyHorizon,
        });
        const mortgageBuyingStatusLabel = translateMortgageBuyingStatus(
          t,
          node.mortgageBuyingStatus,
        );
        return (
          <div>
            <div>
              {node.appraisalReason != null
                ? translateAppraisalReason(t, node.appraisalReason)
                : '-'}
            </div>
            {horisonLabel != null && (
              <div css={[text.caption, textColor('mediumText')]}>
                {horisonLabel}
              </div>
            )}
            {mortgageBuyingStatusLabel != null && (
              <div css={[text.caption, textColor('mediumText')]}>
                {mortgageBuyingStatusLabel}
              </div>
            )}
          </div>
        );
      },
    },

    {
      label: t('propertyType'),
      width: 15,
      sortBy: 'property_propertyType_name',
      cellRenderer: ({ node }) => {
        const { mainType, label } = node.property?.propertyType ?? {};
        if (mainType == null || label == null) {
          return null;
        }
        const mainTypeLabel =
          {
            HOUSE: t('house'),
            APPT: t('apartment'),
            PROP: t('Land'),
            PARK: t('parking'),
            INDUS: t('commercial'),
            GASTRO: t('hotellerie'),
            AGRI: t('agriculturalProperty'),
            SECONDARY: t('secondary'),
            GARDEN: t('Garden'),
          }[mainType] ?? mainType;
        return (
          <div css={{ whiteSpace: 'nowrap' }}>
            <div>{mainTypeLabel}</div>
            <div css={[text.caption, textColor('mediumText')]}>{label}</div>
          </div>
        );
      },
    },

    {
      label: t('livingAndPlotSurface'),
      width: 14,
      sortBy: 'property.livingOrBuiltSurface',
      cellRenderer: ({ node }) => (
        <div css={{ whiteSpace: 'nowrap' }}>
          <div>
            {node.property?.livingSurface != null
              ? node.property.livingSurface.toLocaleString(locale) + ' m²'
              : node.property?.builtSurface != null
              ? node.property.builtSurface.toLocaleString(locale) + ' m²'
              : '-'}
          </div>
          <div css={[text.caption, textColor('mediumText')]}>
            {node.property?.landSurface != null
              ? node.property.landSurface.toLocaleString(locale) + ' m²'
              : '-'}
          </div>
        </div>
      ),
    },

    {
      label: t('Stage'),
      width: 25,
      grow: 1,
      sortBy: 'stage_orderNr|stage_pipeline_id',
      cellRenderer: ({ node }) => (
        <div>
          <IndicatorLeadStage status={node.stage?.status}>
            {node.stage?.label || '-'}
          </IndicatorLeadStage>
        </div>
      ),
    },

    {
      label: t('nextActivity'),
      width: 25,
      sortBy: 'nextActivity.dueAt',
      cellRenderer: ({ node }) =>
        node.nextActivity?.assignedTo?.id &&
        node.nextActivity?.createdBy?.id && (
          <ActivityCell node={node} type="next" />
        ),
    },

    {
      label: t('latestActivity'),
      width: 40,
      sortBy: 'latestActivity.doneAt',
      cellRenderer: ({ node }) =>
        node.latestActivity?.assignedTo?.id &&
        node.latestActivity?.createdBy?.id && (
          <ActivityCell node={node} type="latest" />
        ),
    },

    {
      label: t('broker'),
      width: 20,
      grow: 1,
      sortBy: 'broker_firstName|broker_lastName',
      cellRenderer: ({ node }) =>
        node.broker != null && <UserInfo user={node.broker} />,
    },
    {
      label: t('financingStatus'),
      width: 25,
      cellRenderer: ({ node }) => (
        <div>
          <div>{translateMortgageStatus(t, node.mortgageStatus ?? '-')}</div>
          <div css={[text.caption, textColor('mediumText')]}>
            {translateReceivedMortgageOffer(t, node.receivedMortgageOffer)}
          </div>
        </div>
      ),
    },
  ];

  return columns;
};

const getSorting = (params: LeadsParams) => {
  if (params.sortBy != null) {
    return { sortBy: params.sortBy, sortDirection: params.sortDirection };
  }
  return { sortBy: 'createdOrRequalified', sortDirection: 'desc' } as const;
};

const getQueryVariables = (params: LeadsParams) => {
  return {
    ...getSorting(params),
    count: 40,
    filters: {
      ...paramsToFilters(params),
    },
  };
};

const ListBase = ({
  pipelineId,
  leadId,
  setLeadId,
}: {
  pipelineId: null | string;
  leadId: null | string;
  setLeadId: (leadId: null | string) => void;
}) => {
  const { colors } = useTheme();
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const checkedIds = useSet<string>([]);
  const initialVariables = useConstant(() => {
    const pipelineId_eq = params.pipelineId_eq ?? pipelineId;
    return getQueryVariables({ ...params, pipelineId_eq });
  });
  const initialVariablesAllLeads = useConstant(() => {
    const pipelineId_eq = params.pipelineId_eq ?? pipelineId;
    return {
      ...getQueryVariables({ ...params, pipelineId_eq }),
      count: 500,
    };
  });

  const queryData = useLazyLoadQuery<LeadsQuery>(
    graphql`
      query LeadsQuery(
        $count: Int!
        $filters: LeadsFilters
        $sortBy: String
        $sortDirection: SortDirection
      ) {
        ...Leads_root
          @arguments(
            count: $count
            filters: $filters
            sortBy: $sortBy
            sortDirection: $sortDirection
          )
        me {
          isAdmin
        }
      }
    `,
    initialVariables,
    { fetchPolicy: 'store-and-network' },
  );

  const queryAllData = useLazyLoadQuery<LeadsAllQuery>(
    graphql`
      query LeadsAllQuery(
        $count: Int!
        $filters: LeadsFilters
        $sortBy: String
        $sortDirection: SortDirection
      ) {
        ...LeadsAll_root
          @arguments(
            count: $count
            filters: $filters
            sortBy: $sortBy
            sortDirection: $sortDirection
          )
      }
    `,
    initialVariablesAllLeads,
    { fetchPolicy: 'store-and-network' },
  );

  const { data: allLeads, refetch: refetchAllLeads } = usePaginationFragment<
    LeadsAllPaginationQuery,
    LeadsAll_root$key
  >(
    graphql`
      fragment LeadsAll_root on Query
      @refetchable(queryName: "LeadsAllPaginationQuery")
      @argumentDefinitions(
        count: { type: "Int!" }
        cursor: { type: "String", defaultValue: null }
        filters: { type: "LeadsFilters" }
        sortBy: { type: "String" }
        sortDirection: { type: "SortDirection" }
      ) {
        leadIds: leads(
          first: $count
          after: $cursor
          sortBy: $sortBy
          sortDirection: $sortDirection
          filters: $filters
        ) @connection(key: "Connection_leadIds", filters: []) {
          edges {
            node {
              id
            }
          }
          totalCount
        }
      }
    `,
    queryAllData,
  );

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment<LeadsPaginationQuery, Leads_root$key>(
      graphql`
        fragment Leads_root on Query
        @refetchable(queryName: "LeadsPaginationQuery")
        @argumentDefinitions(
          count: { type: "Int!" }
          cursor: { type: "String", defaultValue: null }
          filters: { type: "LeadsFilters" }
          sortBy: { type: "String" }
          sortDirection: { type: "SortDirection" }
        ) {
          leads(
            first: $count
            after: $cursor
            sortBy: $sortBy
            sortDirection: $sortDirection
            filters: $filters
          ) @connection(key: "Connection_leads", filters: []) {
            edges {
              node {
                ...leadUpdatedTimeAgo_lead
                id
                appraisalReason
                appraisalPerception
                appraisalPerceivedValue
                mortgageBuyingStatus
                mortgageStatus
                receivedMortgageOffer
                saleHorizon
                buyHorizon
                userCanViewLeadDetails
                contact {
                  id
                  ...userInfo_user
                  referrer
                }
                broker {
                  ...userInfo_user
                }
                stage {
                  status
                  label
                }
                property {
                  id
                  propertyType {
                    mainType
                    label
                  }
                  route
                  streetNumber
                  postcode
                  locality
                  state
                  googleAddress {
                    route
                    streetNumber
                    postcode
                    locality
                    state
                  }
                  livingSurface
                  builtSurface
                  landSurface
                  latestAppraisal {
                    realadvisor {
                      max
                      min
                    }
                  }
                }
                nextActivity {
                  __typename
                  createdBy {
                    id
                  }
                  assignedTo {
                    id
                  }
                  ... on ActivityAssignment {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    overdue
                  }
                  ... on ActivityCall {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    done
                    overdue
                    success
                    note
                  }
                  ... on ActivityVisit {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    done
                    overdue
                    success
                    note
                  }
                  ... on ActivityTask {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    overdue
                    subject
                  }
                }
                latestActivity {
                  __typename
                  createdBy {
                    id
                  }
                  assignedTo {
                    id
                  }
                  ... on ActivityAssignment {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    overdue
                  }
                  ... on ActivityCall {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    done
                    overdue
                    success
                    note
                  }
                  ... on ActivityVisit {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    done
                    overdue
                    success
                    note
                  }
                  ... on ActivityTask {
                    startDate
                    createdAt
                    dueAt
                    doneAt
                    overdue
                    subject
                  }
                  ... on ActivityNote {
                    createdAt
                    doneAt
                    note
                  }
                }
                source {
                  label
                }
              }
            }
            totalCount
          }
        }
      `,
      queryData,
    );

  const nodes = [];
  for (const edge of data.leads?.edges ?? []) {
    if (edge?.node != null) {
      nodes.push(edge.node);
    }
  }

  const loadUntil = (stopIndex: number) => {
    const count = nodes.length;
    const LOAD_ITEMS_MORE = 30;
    if (count <= stopIndex + 1 && hasNext === true && isLoadingNext === false) {
      loadNext(LOAD_ITEMS_MORE);
    }
  };

  const retry = () => {
    const count = Math.max(nodes.length, 20);
    const pipelineId_eq = params.pipelineId_eq ?? pipelineId;
    // pass count to preserve table scroll
    const variables = {
      ...getQueryVariables({ ...params, pipelineId_eq }),
      count,
    };
    refetch(variables, { fetchPolicy: 'store-and-network' });
    refetchAllLeads(
      { ...variables, count: 500 },
      { fetchPolicy: 'store-and-network' },
    );
  };

  const columns = useColumns({
    isAdmin: queryData.me?.isAdmin ?? false,
  });

  columns.unshift({
    label: (
      <>
        <Box>
          <Box
            css={{
              position: 'absolute',
              marginTop: -5,
              textAlign: 'center',
              width: 42,
            }}
          >
            {String(
              data.leads?.totalCount.toLocaleString(locale, {
                minimumFractionDigits: 0,
              }) ?? '0',
            )}
          </Box>
          <Checkbox
            checked={
              checkedIds.size !== 0 &&
              checkedIds.size === allLeads?.leadIds?.edges?.length
            }
            indeterminate={
              checkedIds.size !== 0 &&
              checkedIds.size !== allLeads?.leadIds?.edges?.length
            }
            onChange={event => {
              if (event.target.checked) {
                checkedIds.clear();
                allLeads?.leadIds?.edges?.forEach(elem => {
                  if (elem?.node?.id) {
                    checkedIds.add(elem.node.id);
                  }
                });
              } else {
                checkedIds.clear();
              }
            }}
          />
        </Box>
      </>
    ),
    alignment: 'center',
    width: 8,
    clickable: true,
    cellRenderer: ({ node }) =>
      node.id ? (
        <Checkbox
          checked={checkedIds.has(node.id)}
          onClick={e => {
            e.stopPropagation();
            if (checkedIds.has(node.id)) {
              checkedIds.delete(node.id);
            } else {
              checkedIds.add(node.id);
            }
          }}
        />
      ) : (
        <CircularProgress color="primary" size={25} disableShrink />
      ),
  });

  return (
    <>
      <LeadDrawer
        leadId={leadId}
        onClose={refetchLeads => {
          setLeadId(null);
          if (refetchLeads) {
            retry();
          }
        }}
        onDelete={() => retry()}
      />
      <Subscription
        value={params}
        onChange={() => {
          const pipelineId_eq = params.pipelineId_eq ?? pipelineId;
          refetch(getQueryVariables({ ...params, pipelineId_eq }), {
            fetchPolicy: 'store-and-network',
          });
          refetchAllLeads(
            { ...getQueryVariables({ ...params, pipelineId_eq }), count: 500 },
            { fetchPolicy: 'store-and-network' },
          );
        }}
      />

      {checkedIds.size > 0 && (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={3}
            height="48px"
            css={{
              background: colors.subMenu,
              borderBottom: `1px solid ${colors.borderMain}`,
            }}
          >
            <div>{t('Selected', { count: checkedIds.size })}</div>
            <Box px={1}>
              <LeadsActionsMenu
                pipeline={'sales'}
                pipelineId={pipelineId ?? ''}
                leadsIds={checkedIds.values()}
                onConfirm={checkedIds.clear}
                retry={retry}
              />
            </Box>
          </Flex>
        </>
      )}
      <InfiniteTable
        columns={columns}
        data={nodes}
        sort={params}
        onSort={setParams}
        rowSize={64}
        getRowLink={({ node }) => `/leads/${node.id}`}
        getRowStyle={({ node }) => {
          const activity = node.nextActivity;
          return (activity?.__typename === 'ActivityAssignment' ||
            activity?.__typename === 'ActivityCall' ||
            activity?.__typename === 'ActivityVisit' ||
            activity?.__typename === 'ActivityTask') &&
            activity.overdue === true &&
            activity.assignedTo?.id &&
            activity.createdBy?.id
            ? {
                background: colors.red50,
                ':hover': { background: colors.red100 },
              }
            : null;
        }}
        rowTarget="_blank"
        loadUntil={loadUntil}
        hasMore={() => hasNext}
        onRowClick={({ node }) => setLeadId(node.id)}
      />
    </>
  );
};

export const Leads = () => {
  const { text } = useTheme();

  const { search } = useLocation();
  const navigate = useNavigate();

  const [params] = useLeadsParams();

  const addToStageMatch = useMatch(`/leads/add/:stage`);
  const addMatch = useMatch(`/leads/add`);

  const [pipelineMenu, setPipelineMenu] = React.useState(false);
  const pipelineButtonRef = React.useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const leadId = searchParams.get('leadId');
  const setLeadId = (leadId: string | null) => {
    if (leadId != null) {
      searchParams.set('leadId', leadId);
    } else {
      searchParams.delete('leadId');
    }
    setSearchParams(searchParams);
  };
  const data = useLazyLoadQuery<LeadsPipelinesQuery>(
    graphql`
      query LeadsPipelinesQuery {
        tenantSettings {
          defaultPipeline {
            id
          }
        }
        pipelines(leadType: sales) {
          id
          label
        }
      }
    `,
    {},
  );

  const pipelineId =
    params.pipelineId_eq ?? data.tenantSettings?.defaultPipeline?.id ?? null;
  const selectedPipeline = data.pipelines.find(
    pipeline => pipeline.id === pipelineId,
  );

  return (
    <>
      <TopbarTitle titleOverride={selectedPipeline?.label}>
        <Button
          css={[
            text.h6,
            {
              letterSpacing: 'normal',
              textTransform: 'none',
              display: 'inline-flex',
              cursor: 'pointer',
              fontWeight: text.font.bold,
            },
          ]}
          ref={pipelineButtonRef}
          onClick={() => setPipelineMenu(!pipelineMenu)}
          endIcon={<ArrowDropDown />}
        >
          {selectedPipeline?.label ?? ''}
        </Button>
        <Menu
          referenceRef={pipelineButtonRef}
          open={pipelineMenu}
          onClose={() => setPipelineMenu(false)}
        >
          {data.pipelines.map(pipeline => (
            <MenuItem
              key={pipeline.id}
              onClick={() => {
                navigate({
                  search: `?${new URLSearchParams({
                    pipelineId_eq: pipeline.id,
                    completed_in: 'true',
                    status_in: 'active',
                  }).toString()}`,
                });
                setPipelineMenu(false);
              }}
            >
              {pipeline.label}
            </MenuItem>
          ))}
        </Menu>
      </TopbarTitle>
      <React.Suspense fallback={null}>
        <LeadsFilters hidePipelineFilter={params.tab === 'kanban'} />
      </React.Suspense>
      {params.tab === 'list' && (
        <ListBase
          pipelineId={pipelineId}
          leadId={leadId}
          setLeadId={setLeadId}
        />
      )}
      {pipelineId != null && params.tab === 'kanban' && (
        <React.Suspense fallback={<LinearProgress />}>
          <LeadsKanban
            pipelineId={pipelineId}
            onRowClick={setLeadId}
            leadId={leadId}
            onClose={() => setLeadId(null)}
          />
        </React.Suspense>
      )}
      {params.tab === 'map' && <LeadsMap pipelineId={pipelineId} />}

      <LeadCreateDrawer
        open={addMatch != null || addToStageMatch != null}
        onClose={() =>
          navigate(
            {
              pathname: `/leads`,
              search,
            },
            { replace: true },
          )
        }
        onCreate={leadId => navigate(`/leads/${leadId}`, { replace: true })}
        pipelineId={pipelineId}
        stageId={addToStageMatch?.params.stage}
      />
    </>
  );
};
