import {
  Fragment,
  type MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import { createDocumentUrl } from '../../../../src/utils/url';

import { useCMAEditor } from './CMAReportEditorWorkflow';

type BaseAction = {
  type: 'copyLink' | 'download' | 'print';
  icon: JSX.Element;
  tooltip: string;
  onClick: () => void;
};

type ActiveAction = BaseAction & {
  activeTooltip: string;
};

type Action = BaseAction | ActiveAction;

type ActionBoxProps = {
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
};

export const ACTION_BOX_HEIGHT = 50;

const ActionBox = ({ iFrameRef }: ActionBoxProps) => {
  const { documentId, templateId, steps, cmaLanguage } = useCMAEditor();
  const { breakpoints } = useTheme();
  const { t } = useLocale();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [tooltipTimer, setTooltipTimer] = useState<number>();

  const documentUrl = createDocumentUrl(templateId, documentId, cmaLanguage);

  const getIframeContent = useCallback(() => {
    const iframe = iFrameRef.current;

    if (!iframe?.contentWindow) {
      console.error('Iframe not accessible');
      return null;
    }

    return iframe.contentWindow;
  }, [iFrameRef]);

  const handleCopyLink = useCallback(() => {
    if (tooltipTimer) {
      window.clearTimeout(tooltipTimer);
    }
    setShowCopiedTooltip(true);

    const url = new URL(documentUrl.href);
    navigator.clipboard.writeText(url.href);

    setShowCopiedTooltip(true);

    const timer = window.setTimeout(() => setShowCopiedTooltip(false), 1500);
    setTooltipTimer(timer);
  }, [tooltipTimer, documentUrl.href]);

  const handleDownload = useCallback(() => {
    const contentWindow = getIframeContent();
    if (!contentWindow) {
      return;
    }

    const pdfUrl = new URL(documentUrl.href);
    const documentTitle = contentWindow.document.title;

    pdfUrl.pathname =
      pdfUrl.hostname === 'localhost'
        ? `/generate-pdf/${encodeURIComponent(documentTitle)}.pdf`
        : `/pdf/${encodeURIComponent(documentTitle)}.pdf`;

    window.open(pdfUrl.href);
  }, [getIframeContent, documentUrl.href]);

  const handlePrint = useCallback(() => {
    const contentWindow = getIframeContent();
    if (!contentWindow) {
      return;
    }

    const content = contentWindow.document.querySelector(
      '#content',
    ) as HTMLElement;
    if (!content) {
      return;
    }

    const originalTransform = content.style.transform;

    try {
      content.style.transform = 'scale(1)';
      contentWindow.print();
    } finally {
      content.style.transform = originalTransform;
    }
  }, [getIframeContent]);

  const atLeastOneStepIsActive = steps.some(
    step => 'active' in step && step.active,
  );

  const actions = useMemo<Action[]>(
    () => [
      {
        type: 'copyLink',
        tooltip: t('Copy link'),
        activeTooltip: t('Link copied'),
        icon: <LinkIcon sx={{ fontSize: 20 }} />,
        onClick: handleCopyLink,
      },
      {
        type: 'download',
        tooltip: t('Download'),
        icon: <DownloadIcon sx={{ fontSize: 20 }} />,
        onClick: handleDownload,
      },
      {
        type: 'print',
        tooltip: t('Print'),
        icon: <PrintIcon sx={{ fontSize: 20 }} />,
        onClick: handlePrint,
      },
    ],
    [handleCopyLink, handleDownload, handlePrint, t],
  );

  const renderActions = useCallback(
    () =>
      actions.map(({ icon, onClick, tooltip, type }, index) => (
        <Fragment key={`action-${index}`}>
          <Tooltip
            placement={isMobile ? 'bottom' : 'left'}
            title={
              type === 'copyLink' && showCopiedTooltip ? 'Link copied' : tooltip
            }
          >
            <IconButton onClick={onClick}>{icon}</IconButton>
          </Tooltip>
          {isMobile && index < actions.length - 1 && (
            <Divider orientation="vertical" flexItem />
          )}
        </Fragment>
      )),
    [actions, isMobile, showCopiedTooltip],
  );

  if (!atLeastOneStepIsActive) {
    return null;
  }

  if (isMobile) {
    return (
      <Box
        sx={{
          height: `${ACTION_BOX_HEIGHT}px`,
          width: '100%',
          position: 'relative',
          zIndex: 2,
          background: 'white',
          boxShadow: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {renderActions()}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        background: 'white',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
        borderRadius: 1,
        top: 30,
        right: 30,
      }}
    >
      {renderActions()}
    </Box>
  );
};

export default ActionBox;
