import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { graphql, useMutation } from 'react-relay';

import { PipelineDialog } from '../components/PipelineDialog';
import { Menu } from '../controls/popup';
import { useLocale } from '../hooks/locale';

import type { leadsActionsMenuAssignBrokerMutation } from './__generated__/leadsActionsMenuAssignBrokerMutation.graphql';
import type { leadsActionsMenuUpdateLeadsStageMutation } from './__generated__/leadsActionsMenuUpdateLeadsStageMutation.graphql';
import { type User, UserInput } from './user-input';

type Props = {
  leadsIds: string[];
  pipeline: 'buyer' | 'financing' | 'sales';
  pipelineId?: string;
  retry?: () => void;
  onConfirm: () => void;
};

const AssignBrokerDialog = ({
  leadsIds,
  open,
  onConfirm,
  onCancel,
}: {
  leadsIds: string[];
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const [assignBroker, assigning] =
    useMutation<leadsActionsMenuAssignBrokerMutation>(graphql`
      mutation leadsActionsMenuAssignBrokerMutation($input: AssignLeadsInput!) {
        assignLeads(input: $input) {
          leads {
            id
            broker {
              firstName
              lastName
              emails {
                email
              }
            }
          }
        }
      }
    `);
  const { t } = useLocale();
  const [user, setUser] = React.useState<User | null>(null);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('Assign this lead')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Please select a broker to assign these leads to')}
        </DialogContentText>
        <FormControl>
          <InputLabel>{t('broker')}</InputLabel>
          <UserInput
            value={user}
            filters={{ isBroker: true }}
            onChange={newUser => setUser(newUser)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={user == null || assigning}
          onClick={() => {
            assignBroker({
              variables: {
                input: {
                  id: leadsIds,
                  brokerId: user ? user.id : null,
                },
              },
              onCompleted: onConfirm,
            });
          }}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const LeadsActionsMenu = ({
  pipeline,
  pipelineId,
  leadsIds,
  onConfirm,
  retry,
}: Props) => {
  const { t } = useLocale();
  const [assignBrokerDialog, setAssignBrokerDialog] = React.useState(false);
  const [pipelineDialog, setPipelineDialog] = React.useState(false);
  const targetRef = React.useRef(null);
  const [menu, setMenu] = React.useState(false);
  const [updateMultipleLeadsStage, updatingMultipleLeadsStage] =
    useMutation<leadsActionsMenuUpdateLeadsStageMutation>(graphql`
      mutation leadsActionsMenuUpdateLeadsStageMutation(
        $input: UpdateMultipleLeadsStageInput!
      ) {
        updateMultipleLeadsStage(input: $input) {
          leads {
            id
            stage {
              label
            }
          }
        }
      }
    `);

  return (
    <>
      <AssignBrokerDialog
        leadsIds={leadsIds}
        open={assignBrokerDialog}
        onConfirm={() => {
          setAssignBrokerDialog(false);
          onConfirm();
        }}
        onCancel={() => setAssignBrokerDialog(false)}
      />
      <Button
        ref={targetRef}
        variant="contained"
        color="secondary"
        onClick={() => setMenu(true)}
      >
        {t('actions')}
      </Button>
      <Menu referenceRef={targetRef} open={menu} onClose={() => setMenu(false)}>
        <MenuItem onClick={() => setAssignBrokerDialog(true)}>
          {t('Assign to broker')}
        </MenuItem>
        <MenuItem onClick={() => setPipelineDialog(true)}>
          {t('Change stage')}
        </MenuItem>
      </Menu>
      <PipelineDialog
        pipelines={pipeline}
        pipelineId={pipelineId}
        open={pipelineDialog}
        onClose={() => setPipelineDialog(false)}
        loading={updatingMultipleLeadsStage}
        onChange={({ selectedStage, pipelineId: changedPipelineId }) => {
          updateMultipleLeadsStage({
            variables: {
              input: {
                ids: leadsIds,
                stageId: selectedStage.id,
              },
            },
            onCompleted: () => {
              setPipelineDialog(false);
              onConfirm();
              if (pipelineId !== changedPipelineId) {
                retry?.();
              }
            },
          });
        }}
      />
    </>
  );
};
