import * as React from 'react';
import { useRef, useState } from 'react';

import {
  Avatar,
  CircularProgress,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { Image } from '@realadvisor/image';
import { endOfDay, format, isToday, parseISO } from 'date-fns';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex } from 'react-system';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import IndicatorLeadStage, {
  type IndicatorStatus,
} from '../../../apollo/components/IndicatorLeadStage';
import { fromGlobalId } from '../../../shared/global-id';
import { PipelineDialog, type Stage } from '../../components/PipelineDialog';
import { InfiniteLoader } from '../../controls/load-more-indicator';
import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Add } from '../../icons/add';
import { AssignmentInd } from '../../icons/assignment-ind';
import { AssignmentTurnedIn } from '../../icons/assignment-turned-in';
import { Compass } from '../../icons/compass';
import { MeetingRoom } from '../../icons/meeting-room';
import { MoreVert } from '../../icons/more-vert';
import { Phone } from '../../icons/phone';
import { RoundWarning } from '../../icons/round-warning';
import { UserChat } from '../../icons/user-chat';
import {
  type Column as ColumnType,
  type PaginatedStateHook,
  useKanbanPaginatedState,
} from '../../shared/kanban/kanban-data';
import {
  KanbanDND,
  KanbanDNDColumn,
  type RenderItem,
} from '../../shared/kanban/kanban-dnd';
import {
  KanbanColumn,
  KanbanItem,
  KanbanLayout,
} from '../../shared/kanban/kanban-ui';
import { LeadDrawer } from '../../shared/lead-drawer';
import { LeadUpdatedTimeAgo } from '../../shared/lead-updated-time-ago';
import { abbrDateDifference } from '../../utils/abbrDateDifference';
import {
  getHorizonLabel,
  translateAppraisalReason,
} from '../../utils/lead-labels';

import type { leadsKanbanIdsQuery } from './__generated__/leadsKanbanIdsQuery.graphql';
import type { leadsKanbanItemsQuery } from './__generated__/leadsKanbanItemsQuery.graphql';
import type { leadsKanbanStagesQuery } from './__generated__/leadsKanbanStagesQuery.graphql';
import type { leadsKanbanUpdateStageMutation } from './__generated__/leadsKanbanUpdateStageMutation.graphql';
import { paramsToFilters, useLeadsParams } from './LeadsFilters';

graphql`
  fragment leadsKanbanItem on Lead {
    ...leadUpdatedTimeAgo_lead
    relationship
    appraisalReason
    appraisalPerceivedValue
    userCanViewLeadDetails
    saleHorizon
    buyHorizon
    mandateProbability
    predictedListingDate
    stage {
      id
      pipeline {
        id
      }
    }
    contact {
      firstName
      lastName
      primaryEmail {
        email
      }
    }
    broker {
      firstName
      lastName
      primaryImage {
        url
      }
    }
    property {
      formattedAddress
      latestAppraisal {
        realadvisor {
          max
          min
        }
      }
    }
    nextActivity {
      __typename
      ... on ActivityAssignment {
        dueAt
        startDate
        overdue
      }
      ... on ActivityCall {
        dueAt
        startDate
        overdue
      }
      ... on ActivityVisit {
        dueAt
        startDate
        overdue
      }
      ... on ActivityTask {
        dueAt
        startDate
        overdue
      }
    }
    lot {
      id
    }
  }
`;

const ItemMenu = ({
  lead,
  onUpdate,
  updateLeadStage,
}: {
  lead: LeadItem;
  onUpdate: (data: { newStageId: string; newPipelineId: string }) => void;
  updateLeadStage: (
    itemId: string,
    destColumnId: string,
    destIndex: number,
    destColumn?: ColumnType<LeadItem, { label: string; status: string }>,
  ) => Promise<void>;
}) => {
  const { t } = useLocale();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [menu, setMenu] = useState(false);
  const [pipelineDialog, setPipelineDialog] = useState(false);
  const [createListingModalState, setCreateListingModalState] = useState<{
    open: boolean;
    stage: Stage | null;
  }>({
    open: false,
    stage: null,
  });

  // We need this so that we would not render <Menu> and <PipelineDialog>
  // for every card unless it's needed
  const [hasBeenOpen, setHasBeenOpen] = useState(false);

  const handleStageChange = async ({
    selectedStage,
    pipelineId: changedPipelineId,
  }: {
    selectedStage: Stage;
    pipelineId: string;
  }) => {
    if (selectedStage?.status === 'won' && lead.lot == null) {
      setCreateListingModalState({
        open: true,
        stage: selectedStage,
      });
      setPipelineDialog(false);
      return;
    }

    await updateLeadStage(lead.id, selectedStage.id, 0, undefined);
    onUpdate({
      newPipelineId: changedPipelineId,
      newStageId: selectedStage.id,
    });
    setPipelineDialog(false);
  };

  return (
    <>
      <IconButton
        ref={menuRef}
        size="small"
        onClick={() => {
          setMenu(v => !v);
          setHasBeenOpen(true);
        }}
      >
        <MoreVert size={20} />
      </IconButton>

      {hasBeenOpen && (
        <Menu referenceRef={menuRef} open={menu} onClose={() => setMenu(false)}>
          <MenuItem onClick={() => setPipelineDialog(true)}>
            {t('Change stage')}
          </MenuItem>
        </Menu>
      )}

      <PipelineDialog
        pipelines="sales"
        pipelineId={lead.stage?.pipeline?.id}
        open={pipelineDialog}
        onClose={() => setPipelineDialog(false)}
        onChange={handleStageChange}
      />

      <CreateListingModal
        fromLeadId={fromGlobalId(lead.id) ?? ''}
        open={createListingModalState.open}
        onClose={() => setCreateListingModalState({ open: false, stage: null })}
        onListingCreated={async listingId => {
          const stage = createListingModalState.stage;
          if (stage) {
            await updateLeadStage(lead.id, stage.id, 0, undefined);
            onUpdate({
              newPipelineId: lead.stage?.pipeline?.id ?? '',
              newStageId: stage.id,
            });
            navigate(`/listings/${listingId}`);
          }
          setCreateListingModalState({ open: false, stage: null });
        }}
        onCancel={() =>
          setCreateListingModalState({ open: false, stage: null })
        }
      />
    </>
  );
};

const Chip = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color?: 'green' | 'orange';
}) => {
  const { colors } = useTheme();
  let chipColors = {};

  switch (color) {
    case 'green':
      chipColors = { color: colors.white, background: colors.green400 };
      break;
    case 'orange':
      chipColors = { color: colors.white, background: colors.orange600 };
      break;
    default:
      chipColors = { color: colors.grey600, background: colors.grey100 };
      break;
  }

  return children != null && children !== '' && children !== false ? (
    <div
      css={{
        ...chipColors,
        borderRadius: 100,
        marginRight: 6,
        marginBottom: 6,
        display: 'inline-block',
        padding: '2px 10px',
      }}
    >
      {children}
    </div>
  ) : null;
};

type BoundPaginationStateHook = PaginatedStateHook<LeadItem, { label: string }>;

// This component should be as lighweight as possible.
// We'll have a lot of them on a page.
const Item = React.memo(
  ({
    lead,
    removeItem,
    moveItem,
    pipelineId,
    onRowClick,
    isAdmin,
    updateLeadStage,
  }: {
    lead: LeadItem;
    root: leadsKanbanStagesQuery['response'];
    updateItem: BoundPaginationStateHook['updateItem'];
    removeItem: BoundPaginationStateHook['removeItem'];
    moveItem: BoundPaginationStateHook['moveItem'];
    pipelineId: string;
    onRowClick?: null | ((id: string) => void);
    isAdmin: boolean;
    updateLeadStage: (
      itemId: string,
      destColumnId: string,
      destIndex: number,
      destColumn?: ColumnType<LeadItem, { label: string; status: string }>,
    ) => Promise<void>;
  }) => {
    const { t, locale } = useLocale();
    const { colors, text } = useTheme();
    const leadLink = { pathname: `/leads/${lead.id}`, search: '' };

    const { contact, userCanViewLeadDetails, property } = lead;
    const contactName = [
      contact?.firstName,
      userCanViewLeadDetails === true
        ? contact?.lastName
        : (contact?.lastName ?? '').slice(0, 1) + '.',
    ]
      .filter(Boolean)
      .join(' ');

    const contactLabel =
      contact == null
        ? t('noContact')
        : contactName === ''
        ? contact.primaryEmail?.email
        : contactName;

    const address =
      userCanViewLeadDetails === true
        ? property?.formattedAddress
        : property?.formattedAddress?.split(',').slice(1).join(' ');

    const relationship = {
      owner: t('owner'),
      tenant: t('tenant'),
      buyer: t('buyer'),
      heir: t('heir'),
      agent: t('agent'),
      other: t('other'),
      not_set: null,
    }[lead.relationship];

    const formatMillions = (value: number) => {
      const formatted = (value / 1000000).toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return t('millionsOfShort', { value: formatted });
    };

    const appraisal = lead.property?.latestAppraisal?.realadvisor;
    const appraisalMinMax = [
      appraisal?.min == null ? [] : [formatMillions(appraisal.min)],
      appraisal?.max == null ? [] : [formatMillions(appraisal.max)],
    ].join(' – ');
    const appraisalPerceived =
      lead.appraisalPerceivedValue == null
        ? null
        : formatMillions(lead.appraisalPerceivedValue);

    // check for .dueAt != null is for Flow
    // this cannot happen, we select only activities where it's not null on API side
    const activity =
      lead.nextActivity == null ||
      lead.nextActivity.__typename === '%other' ||
      (lead.nextActivity.dueAt == null && lead.nextActivity.startDate == null)
        ? null
        : {
            type: lead.nextActivity.__typename,
            overdue: lead.nextActivity.overdue,
            date:
              lead.nextActivity?.startDate != null
                ? endOfDay(parseISO(lead.nextActivity.startDate))
                : parseISO(lead.nextActivity?.dueAt ?? ''),
          };

    const isTodayActivity =
      activity?.date != null ? isToday(activity.date) : false;
    const overdueBy =
      activity?.date != null
        ? abbrDateDifference(t, new Date(), activity.date, true).text
        : '';

    const ActivityIcon =
      activity == null
        ? RoundWarning
        : {
            ActivityAssignment: AssignmentInd,
            ActivityCall: Phone,
            ActivityTask: AssignmentTurnedIn,
            ActivityVisit: MeetingRoom,
          }[activity.type];

    const brokerImageUrl = lead.broker?.primaryImage?.url;
    const brokerName = [
      (lead.broker?.firstName ?? '').trim().slice(0, 1).toUpperCase(),
      lead.broker?.lastName,
    ]
      .filter(x => x !== '')
      .join('. ');

    const DATE_WIDTH = 25;
    const PADDING_X = 12;
    const PADDING_Y = 8;
    const MENU_WIDTH = 22;

    return (
      <div css={{ position: 'relative' }}>
        <Link
          to={leadLink}
          onClick={
            onRowClick != null
              ? e => {
                  if (!e.ctrlKey && !e.metaKey) {
                    e.preventDefault();
                    onRowClick(lead.id);
                  }
                }
              : undefined
          }
          css={[
            text.caption,
            {
              cursor: 'pointer',
              paddingTop: PADDING_Y,
              paddingBottom: PADDING_Y,
              display: 'block',
              ':hover': { textDecoration: 'none' },
            },
          ]}
        >
          <div
            css={{
              paddingLeft: PADDING_X,
              marginBottom: 8,
            }}
          >
            <Flex alignItems="center">
              <div
                css={{
                  color: colors.grey900,
                  fontWeight: text.font.medium,
                  fontSize: text.size(16),
                }}
              >
                {contactLabel}
              </div>

              <div
                css={{
                  color: colors.grey600,
                  marginLeft: 'auto',
                  paddingRight: PADDING_X + MENU_WIDTH,
                }}
              >
                <LeadUpdatedTimeAgo
                  shortVersion={true}
                  lead={lead}
                  showBothDate={isAdmin}
                />
              </div>
            </Flex>
            <div
              css={{ marginRight: DATE_WIDTH + MENU_WIDTH, marginBottom: 6 }}
            >
              {address != null && (
                <div
                  css={[text.truncate(1), { color: colors.grey800 }]}
                  title={address}
                >
                  {address}
                </div>
              )}
            </div>
            <Chip>{relationship}</Chip>
            <Chip>
              {lead.appraisalReason !== 'not_set' &&
                translateAppraisalReason(t, lead.appraisalReason)}
            </Chip>
            <Chip>
              {getHorizonLabel(t, {
                appraisalReason: lead.appraisalReason,
                saleHorizon: lead.saleHorizon,
                buyHorizon: lead.buyHorizon,
              })}
            </Chip>
            <Flex flexDirection="row-reverse">
              {lead.mandateProbability && (
                <Chip
                  color={lead.mandateProbability >= 50 ? 'green' : 'orange'}
                >
                  {lead.mandateProbability + '%'}
                </Chip>
              )}
              {lead.predictedListingDate && (
                <Chip color="green">
                  {format(
                    new Date(lead.predictedListingDate ?? ''),
                    'MMM yyyy',
                  )}
                </Chip>
              )}
            </Flex>
          </div>
          {(appraisalMinMax !== '' || appraisalPerceived != null) && (
            <div
              css={{
                background: colors.grey100,
                paddingBottom: 6,
                paddingTop: 6,
                paddingRight: PADDING_X,
                paddingLeft: PADDING_X,
                color: colors.grey900,
                fontWeight: text.font.medium,
                fontSize: text.size(16),
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {appraisalMinMax !== '' && (
                  <>
                    <Compass size={18} />
                    <span
                      css={{
                        marginLeft: '0.3em',
                      }}
                    >
                      {appraisalMinMax}
                    </span>
                  </>
                )}
              </span>
              {appraisalPerceived != null && (
                <span
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '0.5em',
                  }}
                >
                  <UserChat size={18} />
                  <span
                    css={{
                      marginLeft: '0.3em',
                    }}
                  >
                    {appraisalPerceived}
                  </span>
                </span>
              )}
            </div>
          )}
          <div
            css={{
              display: 'flex',
              paddingRight: PADDING_X,
              paddingLeft: PADDING_X,
              paddingTop: 10,
              paddingBottom: 4,
              justifyContent: 'space-between',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: text.font.medium,
                color:
                  activity == null || activity.overdue
                    ? colors.errorText
                    : isTodayActivity
                    ? colors.warningText
                    : colors.successText,
              }}
            >
              <ActivityIcon size={18} css={{ marginRight: '0.3em' }} />
              {activity == null
                ? t('noActivity')
                : activity.overdue
                ? t('overdueBy', {
                    time: overdueBy,
                  })
                : t('dueIn', {
                    time: overdueBy,
                  })}
            </div>
            <div
              css={{
                color: colors.grey900,
                fontWeight: text.font.medium,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {brokerImageUrl != null && (
                <Avatar css={{ width: 22, height: 22, marginRight: '0.3em' }}>
                  {/* Using 40x40 so we reuse the file generated for UserCard */}
                  <Image src={brokerImageUrl} srcSize={[[40, 40]]} />
                </Avatar>
              )}
              {brokerName}
            </div>
          </div>
        </Link>
        <div css={{ position: 'absolute', right: 4, top: PADDING_Y }}>
          <ItemMenu
            lead={lead}
            onUpdate={action => {
              if (action.newPipelineId !== pipelineId) {
                removeItem(lead.id);
              } else {
                moveItem(lead.id, action.newStageId);
              }
            }}
            updateLeadStage={updateLeadStage}
          />
        </div>
      </div>
    );
  },
);

const getItemId = (lead: LeadItem) => lead.id;
const updateItemAfterMove = (lead: LeadItem, columnId: string) =>
  lead.stage?.id === columnId ? lead : { ...lead, stageId: columnId };

const Column = ({
  index,
  column,
  paginatedState,
  pipelineId,
  onRowClick,
  isAdmin,
  root,
  updateLeadStage,
}: {
  index: number;
  column: ColumnType<LeadItem, { label: string; status: string }>;
  paginatedState: BoundPaginationStateHook;
  pipelineId: string;
  onRowClick?: (id: string) => void;
  isAdmin: boolean;
  root: leadsKanbanStagesQuery['response'];
  updateLeadStage: (
    itemId: string,
    destColumnId: string,
    destIndex: number,
    destColumn?: ColumnType<LeadItem, { label: string; status: string }>,
  ) => Promise<void>;
}) => {
  const { colors } = useTheme();
  const { search } = useLocation();
  const newLink = {
    pathname: `/leads/new`,
    search: `${search}&stage_id=${column.columnId}`,
  };

  const totalCount =
    column.readyItems.length +
    [column.loadingPage, ...column.idlePages]
      .map(page => page?.itemIds.length ?? 0)
      .reduce((a, b) => a + b, 0);

  const { updateItem, removeItem, moveItem } = paginatedState;

  const renderItem: RenderItem<LeadItem> = React.useCallback(
    ({ item, isDragging, props, ref }) => {
      return (
        <KanbanItem {...props} isDragging={isDragging} ref={ref}>
          <Item
            lead={item}
            root={root}
            updateItem={updateItem}
            removeItem={removeItem}
            moveItem={moveItem}
            pipelineId={pipelineId}
            onRowClick={!isDragging ? onRowClick : null}
            isAdmin={isAdmin}
            updateLeadStage={updateLeadStage}
          />
        </KanbanItem>
      );
    },
    [
      root,
      updateItem,
      removeItem,
      moveItem,
      pipelineId,
      onRowClick,
      isAdmin,
      updateLeadStage,
    ],
  );

  return (
    <KanbanDNDColumn
      key={column.columnId}
      id={column.columnId}
      items={column.readyItems}
      getItemId={getItemId}
      renderItem={renderItem}
      renderContainer={({ children, ref, isDraggingOver }) => (
        <KanbanColumn
          ref={ref}
          index={index}
          label={
            <>
              <Box flexGrow={1}>
                <IndicatorLeadStage
                  status={column.data.status as IndicatorStatus}
                >
                  {column.data.label} ({totalCount})
                </IndicatorLeadStage>
              </Box>
              <Box>
                <IconButton component={Link} to={newLink} size="small">
                  <Add size={20} fill={colors.primaryMain} />
                </IconButton>
              </Box>
            </>
          }
        >
          {children}

          {column.loadingPage && (
            <Flex
              justifyContent="center"
              mt={column.readyItems.length === 0 ? 4 : 0}
              mb={2}
            >
              <CircularProgress size={24} disableShrink />
            </Flex>
          )}

          {column.loadingPage == null &&
            column.idlePages.length > 0 &&
            !isDraggingOver && (
              <InfiniteLoader
                offset={-500}
                onLoadMore={() => paginatedState.loadPage(column.idlePages[0])}
              />
            )}
        </KanbanColumn>
      )}
    />
  );
};

const PAGE_SIZE = 8;

type LeadItem = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<leadsKanbanItemsQuery['response']['leads']>['edges']
    >[number]
  >['node']
>;

const LeadsKanbanBase = ({
  stages,
  leadIds,
  pipelineId,
  onRowClick,
  leadId,
  onClose,
  isAdmin,
  root,
}: {
  stages: NonNullable<
    leadsKanbanStagesQuery['response']['pipelineById']
  >['stages'];
  leadIds: NonNullable<leadsKanbanIdsQuery['response']['leads']>;
  pipelineId: string;
  statusFilter: string[] | null;
  onRowClick?: (id: string) => void;
  leadId: null | string;
  onClose: () => void;
  isAdmin: boolean;
  root: leadsKanbanStagesQuery['response'];
}) => {
  const relayEnv = useRelayEnvironment();
  const navigate = useNavigate();

  const [updateStage] = useMutation<leadsKanbanUpdateStageMutation>(graphql`
    mutation leadsKanbanUpdateStageMutation($input: UpdateLeadStageInput!) {
      updateLeadStage(input: $input) {
        clientMutationId
      }
    }
  `);

  const loadItems = async (itemsId: string[]) => {
    // Please talk to @istarkov, @TrySound, or @rpominov before using fetchQuery() somewhere else
    // We should avoid it if possible because it's a low level API that may work unexpectedly
    const resp = await fetchQuery<leadsKanbanItemsQuery>(
      relayEnv,

      // NOTE:
      //  Do not use fragments w/o (mask: false) in this query or its subfragments.
      //  If we do that, Relay will put data into it's store,
      //  and then it will either delete it at a random time, or hold indefinitely: both bad.
      graphql`
        query leadsKanbanItemsQuery($first: Int!, $filters: LeadsFilters!) {
          leads(first: $first, filters: $filters) {
            edges {
              node {
                id
                ...leadsKanbanItem @relay(mask: false)
                lot {
                  id
                }
              }
            }
          }
        }
      `,
      { first: itemsId.length, filters: { id_in: itemsId } },
    ).toPromise();

    return (resp?.leads?.edges ?? []).flatMap(edge =>
      edge?.node == null ? [] : [edge.node],
    );
  };

  const getInitialState = () => {
    const leads = (leadIds.edges ?? []).flatMap(edge =>
      edge?.node ? [edge.node] : [],
    );
    return stages.map(stage => {
      const stageLeads = leads.filter(lead => lead.stageId === stage.id);
      const pagesCount = Math.ceil(stageLeads.length / PAGE_SIZE);
      return {
        columnId: stage.id,
        data: { label: stage.label, status: stage.status },
        readyItems: [],
        loadingPage: null,
        idlePages: Array.from(Array(pagesCount), (_, i) => ({
          pageId: [stage.id, i].join('/'),
          itemIds: stageLeads
            .slice(i * PAGE_SIZE, i * PAGE_SIZE + PAGE_SIZE)
            .map(x => x.id),
        })),
      };
    });
  };

  const [createListingModalState, setCreateListingModalState] = useState<{
    open: boolean;
    leadId: string | null;
    columnId: string | null;
    index: number | null;
    sourceColumnId: string | null;
    sourceIndex: number | null;
  }>({
    open: false,
    leadId: null,
    columnId: null,
    index: null,
    sourceColumnId: null,
    sourceIndex: null,
  });

  const updateLeadStage = async (
    itemId: string,
    destColumnId: string,
    destIndex: number,
    destColumn:
      | ColumnType<LeadItem, { label: string; status: string }>
      | undefined,
  ) => {
    updateStage({
      variables: {
        input: {
          stageId: destColumnId,
          id: itemId,
          putAfter:
            destIndex === 0 ? null : destColumn?.readyItems[destIndex - 1].id,
        },
      },
    });
  };

  const paginatedState = useKanbanPaginatedState<
    LeadItem,
    { label: string; status: string }
  >({
    getInitialState,
    getItemId,
    loadItems,
    afterDragEnd: async (args, updatedState) => {
      const { itemId, destColumnId, destIndex, srcColumnId, srcIndex } = args;
      const destColumn = updatedState.find(
        column => column.columnId === destColumnId,
      );
      const lead = paginatedState.state
        .flatMap(column => column.readyItems)
        .find(item => item.id === itemId);

      if (destColumn?.data.status === 'won' && lead?.lot == null) {
        setCreateListingModalState({
          open: true,
          leadId: itemId,
          columnId: destColumnId,
          index: destIndex,
          sourceColumnId: srcColumnId,
          sourceIndex: srcIndex,
        });
      } else {
        await updateLeadStage(itemId, destColumnId, destIndex, destColumn);
      }
    },
    updateItemAfterMove,
  });

  const revertPosition = () => {
    const { leadId, sourceColumnId, sourceIndex } = createListingModalState;
    if (leadId && sourceColumnId && sourceIndex != null) {
      paginatedState.moveItem(leadId, sourceColumnId, sourceIndex);
    }
  };

  const closeCreateListingModal = () => {
    setCreateListingModalState({
      open: false,
      leadId: null,
      columnId: null,
      index: null,
      sourceColumnId: null,
      sourceIndex: null,
    });
  };

  const handleListingCreation = async (listingId: string) => {
    const { leadId, columnId, index } = createListingModalState;
    if (columnId && index != null && leadId) {
      await updateLeadStage(
        leadId,
        columnId,
        index,
        paginatedState.state.find(column => column.columnId === columnId),
      );
      navigate(`/listings/${listingId}`);
    }
  };

  return (
    <>
      <KanbanDND onDragEnd={paginatedState.onDragEnd}>
        <KanbanLayout>
          {paginatedState.state.map((column, index) => (
            <Column
              key={column.columnId}
              index={index}
              column={column}
              paginatedState={paginatedState}
              pipelineId={pipelineId}
              onRowClick={onRowClick}
              isAdmin={isAdmin}
              root={root}
              updateLeadStage={updateLeadStage}
            />
          ))}
        </KanbanLayout>
        {leadId != null && (
          <LeadDrawer
            leadId={leadId}
            onClose={onClose}
            onDelete={() => paginatedState.removeItem(leadId)}
          />
        )}
      </KanbanDND>

      {createListingModalState.open && (
        <CreateListingModal
          fromLeadId={fromGlobalId(createListingModalState.leadId) ?? ''}
          open={createListingModalState.open}
          onClose={closeCreateListingModal}
          onCancel={revertPosition}
          onListingCreated={handleListingCreation}
        />
      )}
    </>
  );
};

export const LeadsKanban = (props: {
  pipelineId: string;
  onRowClick?: (id: string) => void;
  leadId: string | null;
  onClose: () => void;
}) => {
  const [params] = useLeadsParams();
  const filters = paramsToFilters(params);

  const statusFilterAsString = JSON.stringify(
    filters.status_in?.filter(Boolean) ?? null,
  );
  // Makes it a stable array
  const statusFilter: null | string[] = React.useMemo(
    () => JSON.parse(statusFilterAsString),
    [statusFilterAsString],
  );

  const data = useLazyLoadQuery<leadsKanbanStagesQuery>(
    graphql`
      query leadsKanbanStagesQuery($id: ID!) {
        pipelineById(id: $id) {
          stages {
            id
            label
            status
          }
        }
        me {
          isAdmin
        }
      }
    `,
    { id: props.pipelineId },
  );

  const pipeline = data.pipelineById;

  // We load IDs of all itmes at the beginning.
  // This number is somewhat arbitrary limit,
  // we may extend it in the future if need arise and performance allow.
  const MAX_SUPPORTED_ITEMS = 200000;

  const { leads } = useLazyLoadQuery<leadsKanbanIdsQuery>(
    graphql`
      query leadsKanbanIdsQuery($first: Int!, $filters: LeadsFilters!) {
        leads(first: $first, sortBy: "posWithinStage", filters: $filters) {
          edges {
            node {
              id
              stageId
            }
          }
        }
      }
    `,
    {
      first: MAX_SUPPORTED_ITEMS,
      filters: {
        ...filters,
        stageName_in: null,
        stageId_in: pipeline?.stages.map(stage => stage.id),
      },
    },
    { fetchPolicy: 'store-and-network' },
  );

  // should never happen unless a bug
  if (pipeline == null) {
    return <>Error: pipeline not found</>;
  }

  // for flow
  if (leads == null) {
    return null;
  }

  // temporary solution
  // after discussion with the business, a new kanban refactoring task will be created
  // // should not happen soon hopefully
  // if (leads.totalCount > MAX_SUPPORTED_ITEMS) {
  //   return <>Error: to many lead</>;
  // }

  // We rely on an assumption that `stages` and `leadIds`
  // never change during a lifetime of a LeadsKanbanBase instance.
  // (if they do change, useKanbanPaginatedState will still hold the old state)
  //
  // This is currently true because <*Renderer>s render `null` during loading,
  // so on a reload we unmount <LeadsKanbanBase> and then mount again.

  // key props force useKanbanPaginatedState and whole component to re-render thus updating data if leads count changes
  return (
    <LeadsKanbanBase
      key={leads.edges?.length}
      stages={pipeline.stages}
      leadIds={leads}
      pipelineId={props.pipelineId}
      statusFilter={statusFilter}
      onRowClick={props.onRowClick}
      leadId={props.leadId}
      onClose={props.onClose}
      isAdmin={data.me?.isAdmin ?? false}
      root={data}
    />
  );
};
