import * as React from 'react';

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import {
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import {
  type FormikErrors,
  type FormikHook,
  useFormik,
} from '@realadvisor/form';
import { addHours, format, parseISO, roundToNearestMinutes } from 'date-fns';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
// $FlowFixMe[untyped-import]
import { useNavigate } from 'react-router-dom';
import { Box, Flex, useResponsive } from 'react-system';

import { CreateListingModal } from '../../../apollo/components/create-listing/CreateListingModal';
import { MarkdownComposer } from '../../../shared/composer';
import { FinancingLeadLostDialog } from '../../../shared/financing-lead-lost-dialog';
import { fromGlobalId } from '../../../shared/global-id';
import { formatDate, parseDate } from '../../controls/date-input';
import { ProgressButton } from '../../controls/progress-button';
import { Radio } from '../../controls/radio';
import { parseTime } from '../../controls/time-input';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { Cancel } from '../../icons/cancel';
import { CheckBox } from '../../icons/check-box';
import { ChevronLeft } from '../../icons/chevron-left';
import { Close } from '../../icons/close';
import { Create } from '../../icons/create';
import { Done } from '../../icons/done';
import { MoreHoriz } from '../../icons/more-horiz';
import { Phone } from '../../icons/phone';
import { Today } from '../../icons/today';
import { FinancingWonDialog } from '../../shared/financing-won-dialog';
import { number_of_string, string_of_number } from '../../utils/number-format';
import { PipelineDialog } from '../PipelineDialog';

import type {
  activityWorkflowDialog_activity$data,
  activityWorkflowDialog_activity$key,
} from './__generated__/activityWorkflowDialog_activity.graphql';
import type {
  activityWorkflowDialog_root$data,
  activityWorkflowDialog_root$key,
} from './__generated__/activityWorkflowDialog_root.graphql';
import type { activityWorkflowDialogLeadBuyerLeadUpdateMutation } from './__generated__/activityWorkflowDialogLeadBuyerLeadUpdateMutation.graphql';
import type { activityWorkflowDialogLeadMutation } from './__generated__/activityWorkflowDialogLeadMutation.graphql';
import type {
  ActivityTypeEnum,
  EmailVisibilityTypeEnum,
  activityWorkflowDialogMutation,
} from './__generated__/activityWorkflowDialogMutation.graphql';
import type {
  activityWorkflowDialogQuery,
  activityWorkflowDialogQuery$data,
} from './__generated__/activityWorkflowDialogQuery.graphql';
import type { activityWorkflowDialogUpdateLeadMutation } from './__generated__/activityWorkflowDialogUpdateLeadMutation.graphql';
import { ActivityEventDialog } from './activity-event-dialog';
import { getParticipantData } from './feed-form-utils';
import {
  type TimeRange,
  activityOfTimeRange,
  timeRangeOfActivity,
} from './time-range-picker';
import { getDefaultSubject } from './utils';

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();
  return (
    <Box pb={2} css={[text.subtitle2]}>
      {children}
    </Box>
  );
};

const useNow = (): number => {
  const nowRef = React.useRef<number | null>(null);
  if (nowRef.current == null) {
    const now = Date.now();
    nowRef.current = now;
    return now;
  }
  return nowRef.current;
};

const getActivityTypeFromStep = (step: Step) => {
  let activityType: ActivityTypeEnum = 'note';

  if (step === 'schedule_visit') {
    activityType = 'visit';
  } else if (step === 'schedule_call') {
    activityType = 'call';
  } else if (step === 'schedule_task') {
    activityType = 'task';
  }

  return activityType;
};

const NotePreset = ({
  form,
  preset,
  leadType,
}: {
  form: FormikHook<State>;
  preset: string[];
  leadType?: PipelineLeadType;
}) => {
  const [selected, setSelected] = React.useState<string | null>(null);
  return (
    <>
      {preset.map(item => (
        <Radio
          key={item}
          checked={selected === item}
          onChange={() => {
            setSelected(item);
            form.setValues({ note: item });
          }}
        >
          {item}
        </Radio>
      ))}
      {leadType === 'financing' && (
        <MarkdownComposer
          initialValue={form.values.note}
          onChange={note => form.setValues({ note })}
        />
      )}
      {form.errors.note != null && (
        <FormHelperText error={true}>{form.errors.note}</FormHelperText>
      )}
    </>
  );
};

const NextStepDialogContent = ({
  parentType,
  leadType,
  type,
  activityType,
  onNext,
}: {
  parentType: null | 'Enquiry' | 'Lot' | 'Lead' | 'User' | 'BuyerLead';
  leadType?: PipelineLeadType;
  type: 'completed' | 'canceled';
  activityType: 'visit' | 'call' | 'task';
  onNext: (step: Step) => void;
}) => {
  const { t } = useLocale();
  const { colors } = useTheme();

  return (
    <DialogContent>
      <Subtitle>{t('What would you like to do next?')}</Subtitle>
      <List>
        {!(activityType === 'call' && type === 'canceled') && (
          <ListItem button={true} onClick={() => onNext('schedule_visit')}>
            <ListItemAvatar>
              <Avatar css={{ backgroundColor: colors.blue500 }}>
                <Today />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                (parentType === 'Lead' && leadType === 'sales') ||
                parentType === 'BuyerLead'
                  ? t('Organise a visit')
                  : t('Organise a meeting')
              }
            />
          </ListItem>
        )}
        <ListItem button={true} onClick={() => onNext('schedule_call')}>
          <ListItemAvatar>
            <Avatar css={{ backgroundColor: colors.orange500 }}>
              <Phone />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('Schedule a call')} />
        </ListItem>
        <ListItem button={true} onClick={() => onNext('schedule_task')}>
          <ListItemAvatar>
            <Avatar css={{ backgroundColor: colors.purple500 }}>
              <CheckBox />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('Schedule a task')} />
        </ListItem>
        {parentType === 'Lead' ? (
          <ListItem button={true} onClick={() => onNext('lead_lost')}>
            <ListItemAvatar>
              <Avatar css={{ backgroundColor: colors.error }}>
                <Close />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('Lead lost')} />
          </ListItem>
        ) : (
          <ListItem button={true} onClick={() => onNext('note')}>
            <ListItemAvatar>
              <Avatar css={{ backgroundColor: colors.success }}>
                <Create />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('Write a note')} />
          </ListItem>
        )}
        {parentType === 'Lead' &&
          type !== 'canceled' &&
          leadType === 'sales' && (
            <ListItem button={true} onClick={() => onNext('lead_won')}>
              <ListItemAvatar>
                <Avatar css={{ backgroundColor: colors.success }}>
                  <Done />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t('Mandate won')} />
            </ListItem>
          )}
        {((parentType === 'Lead' &&
          type !== 'canceled' &&
          leadType === 'financing') ||
          parentType === 'BuyerLead') && (
          <ListItem button={true} onClick={() => onNext('financing_lead_won')}>
            <ListItemAvatar>
              <Avatar css={{ backgroundColor: colors.success }}>
                <Done />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('Lead won')} />
          </ListItem>
        )}
        {parentType === 'BuyerLead' && (
          <ListItem button={true} onClick={() => onNext('lead_lost')}>
            <ListItemAvatar>
              <Avatar css={{ backgroundColor: colors.error }}>
                <Close />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t('Lead lost')} />
          </ListItem>
        )}
        <ListItem button={true} onClick={() => onNext('do_nothing')}>
          <ListItemAvatar>
            <Avatar css={{ backgroundColor: colors.grey500 }}>
              <MoreHoriz />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('Do nothing')} />
        </ListItem>
      </List>
    </DialogContent>
  );
};

type PipelineLeadType = 'buyer' | 'financing' | 'sales';

type Props = {
  root: activityWorkflowDialog_root$key;
  allowNotifyParticipants?: boolean;
  activity: activityWorkflowDialog_activity$key;
  initialStep:
    | 'completed_visit'
    | 'completed_call'
    | 'completed_task'
    | 'cancelled_visit'
    | 'cancelled_call'
    | 'cancelled_task';
  parentType: null | 'Enquiry' | 'Lot' | 'Lead' | 'User' | 'BuyerLead';
  nylasEmail?: null | string;
  leadType?: PipelineLeadType;
  parentId: null | string;
  onClose: () => void;
  onNewActivity: () => void;
  open: boolean;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  showUnfinishedWarning: boolean;
  setShowUnfinishedWarning: (value: boolean) => void;
};

type Step =
  | 'none'
  | 'completed_visit_note'
  | 'completed_visit_list'
  | 'completed_call_note'
  | 'completed_call_list'
  | 'completed_task_list'
  | 'cancelled_visit_note'
  | 'cancelled_visit_list'
  | 'cancelled_call_list'
  | 'cancelled_task_list'
  | 'schedule_visit'
  | 'schedule_call'
  | 'schedule_task'
  | 'lead_lost'
  | 'buyer_lead_lost'
  | 'note'
  | 'lead_won'
  | 'financing_lead_won'
  | 'pipeline_change'
  | 'do_nothing';

type State = {
  visibility: EmailVisibilityTypeEnum;
  createdBy: activityWorkflowDialog_root$data['me'];
  assignedTo:
    | activityWorkflowDialog_activity$data['assignedTo']
    | activityWorkflowDialog_root$data['me'];
  notifyParticipants: boolean;
  targetUsers: ReturnType<typeof getParticipantData>[];
  step: Step;
  finishedNote: string;
  timeRange: TimeRange;
  startDate: string;
  dueAt: string;
  subject: string;
  note: string;
  location: string;
  mandateProbability: string;
  predictedListingDate: string | null;
  showCreateListingModal: boolean;
  selectedStageId: null;
};

export type ActivityWorkflowParticipantData = NonNullable<
  activityWorkflowDialog_activity$data['displayParticipants']
>[number];

export const ActivityWorkflowDialog = (props: Props) => {
  const { parentType, leadType, parentId } = props;
  const { t } = useLocale();
  const responsive = useResponsive();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const getPipelineType = (): PipelineLeadType => {
    if (parentType === 'Lead') {
      return leadType === 'financing' ? 'financing' : 'sales';
    }

    // parentType === 'BuyerLead'
    return 'buyer';
  };

  const pipelineType = getPipelineType();

  const root = useFragment(
    graphql`
      fragment activityWorkflowDialog_root on Query {
        me {
          id
          ...userInput_user @relay(mask: false)
          isAdmin
          firstName
          lastName
          modules
          organisation {
            name
          }
        }
        mandateProbabilityTypes: dictionaries(
          type: "mandate_probability_types"
        ) {
          name
          label
        }
        tenantSettings {
          countryCode
        }
      }
    `,
    props.root,
  );

  const data = useLazyLoadQuery<activityWorkflowDialogQuery>(
    graphql`
      query activityWorkflowDialogQuery($pipelineName: String!) {
        lostStages: stages(pipelineName: $pipelineName, status: "lost") {
          id
          label
          labelEN
        }
        wonStages: stages(pipelineName: $pipelineName, status: "won") {
          id
          label
        }
      }
    `,
    {
      pipelineName: leadType === 'financing' ? 'financing' : 'brokerage',
    },
  );

  const activity = useFragment(
    graphql`
      fragment activityWorkflowDialog_activity on Activity {
        id
        visibility
        ... on ActivityVisit {
          location
          startDate
          endDate
          note
        }
        ... on ActivityCall {
          note
        }
        ... on ActivityTask {
          note
        }
        ... on ActivityNote {
          note
        }
        assignedTo {
          ...userInput_user @relay(mask: false)
          organisation {
            name
          }
        }
        createdBy {
          ...userInput_user @relay(mask: false)
        }
        displayParticipants {
          name
          email
          user {
            ...userInput_user @relay(mask: false)
          }
        }
        participants {
          name
          email
          user {
            id
          }
        }
        enquiry {
          id
        }
        lot {
          id
          property {
            formattedAddress
          }
        }
        lead {
          ...financingWonDialog_lead
          id
          property {
            formattedAddress
          }
          mandateProbability
          predictedListingDate
          stage {
            id
          }
          lot {
            id
          }
        }
        user {
          id
        }
        buyerLead {
          id
        }
      }
    `,
    props.activity,
  );
  const [upsertActivity, upsertActivityLoading] =
    useMutation<activityWorkflowDialogMutation>(graphql`
      mutation activityWorkflowDialogMutation($input: UpsertActivityInput!) {
        upsertActivity(input: $input) {
          activity {
            ... on ActivityVisit {
              success
              ...FeedVisit_activity
            }
            ...FeedCall_activity
            ...FeedEmail_activity
            ... on ActivityTask {
              success
              ...FeedTask_activity
            }
            ...FeedNote_activity
          }
        }
      }
    `);
  const [upsertLead, upsertLeadLoading] =
    useMutation<activityWorkflowDialogLeadMutation>(graphql`
      mutation activityWorkflowDialogLeadMutation($input: UpsertLeadInput!) {
        upsertLead(input: $input) {
          lead {
            id
            stage {
              id
              status
              name
            }
            mandateProbability
            predictedListingDate
          }
        }
      }
    `);

  const [updateBuyerLead] =
    useMutation<activityWorkflowDialogLeadBuyerLeadUpdateMutation>(graphql`
      mutation activityWorkflowDialogLeadBuyerLeadUpdateMutation(
        $id: ID!
        $status: BuyerLeadStatus
      ) {
        updateBuyerLead(input: { id: $id, status: $status }) {
          buyerLead {
            id
            status
          }
        }
      }
    `);

  const [updateLead, updateLeadLoading] =
    useMutation<activityWorkflowDialogUpdateLeadMutation>(
      graphql`
        mutation activityWorkflowDialogUpdateLeadMutation(
          $id: ID!
          $stageId: ID!
        ) {
          upsertLead(input: { lead: { id: $id, stageId: $stageId } }) {
            lead {
              id
              stage {
                id
                status
              }
            }
          }
        }
      `,
    );

  const now = useNow();
  const inTwoHours = roundToNearestMinutes(addHours(now, 2), { nearestTo: 15 });
  let success: boolean | null = null;

  // Make start and end times two hours from now with a one hour default duration
  const dueAt = roundToNearestMinutes(addHours(now, 2), { nearestTo: 15 });
  const endAt = roundToNearestMinutes(addHours(now, 3), { nearestTo: 15 });

  const timeRange = timeRangeOfActivity({
    dueAt,
    startDate: activity.startDate,
    endDate: activity.endDate,
    endAt,
  });

  const assignedToUser = activity ? activity.assignedTo : root.me;

  const lostStages = (data.lostStages ?? []).filter(Boolean) as NonNullable<
    NonNullable<activityWorkflowDialogQuery$data['lostStages']>[number]
  >[];

  const wonStageId = data.wonStages?.[0]?.id;

  const initialValues = {
    visibility: activity.visibility ?? 'shared',
    createdBy: root.me,
    assignedTo: assignedToUser ?? null,
    notifyParticipants: true,
    targetUsers: (activity.displayParticipants ?? []).map(getParticipantData),
    step: 'none' as Step,
    finishedNote: '',
    timeRange,
    startDate: formatDate(inTwoHours),
    dueAt: '',
    subject: '',
    note: '',
    location: '',
    mandateProbability: string_of_number(activity.lead?.mandateProbability),
    predictedListingDate: activity.lead?.predictedListingDate ?? null,
    showCreateListingModal: false,
    selectedStageId: null,
  };

  switch (props.initialStep) {
    case 'completed_visit':
      success = true;
      initialValues.step = 'completed_visit_note';
      break;
    case 'completed_call':
      success = true;
      initialValues.step = 'completed_call_note';
      break;
    case 'completed_task':
      success = true;
      initialValues.step = 'completed_task_list';
      break;
    case 'cancelled_visit':
      success = false;
      initialValues.step = 'cancelled_visit_note';
      break;
    case 'cancelled_call':
      success = false;
      initialValues.step = 'cancelled_call_list';
      break;
    case 'cancelled_task':
      success = false;
      initialValues.step = 'cancelled_task_list';
      break;
  }

  const finishChain = () => {
    form.resetForm();
    if (
      pipelineType &&
      !(
        form.values.step === 'lead_won' ||
        form.values.step === 'financing_lead_won' ||
        form.values.step === 'lead_lost' ||
        form.values.step === 'buyer_lead_lost'
      )
    ) {
      form.setValues({ step: 'pipeline_change' });
    } else {
      props.onNewActivity();
    }
    setIsLoading(false);
  };

  const handleActivityUpdate = (note: string, onCompleted?: () => void) => {
    const input = {
      id: activity.id,
      success,
      done: true,
      doneAt: new Date().toISOString(),
      note: note.trim().length === 0 ? activity.note : note.trim(),
    };

    upsertActivity({
      variables: {
        input: {
          activity: {
            ...input,
          },
          notifyParticipants: form.values.notifyParticipants,
        },
      },
      onCompleted,
    });
  };

  const handleLeadStageUpdate = (
    stageId: string | null,
    onCompleted?: () => void,
  ) => {
    if (!activity.lead || !stageId) {
      return;
    }

    upsertLead({
      variables: {
        input: {
          lead: {
            id: activity.lead.id,
            stageId,
          },
        },
      },
      onCompleted,
    });
  };

  const submitForm = (values: State, doNothing: boolean) => {
    const { notifyParticipants } = values;
    setIsLoading(true);
    const finishActivity = () => {
      const note = values.finishedNote.trim();
      const input = {
        id: activity.id,
        createdBy: activity.createdBy?.id,
        success,
        done: true,
        doneAt: new Date().toISOString(),
        note: note.length === 0 ? activity.note : note,
      };
      upsertActivity({
        variables: {
          input: {
            activity: {
              ...input,
            },
            notifyParticipants,
          },
        },
        onCompleted: () => {
          if (doNothing) {
            props.onNewActivity();
            setIsLoading(false);
          } else {
            createActivity();
          }
        },
      });
      if (
        root.tenantSettings?.countryCode === 'CH' &&
        parentType === 'Lead' &&
        leadType === 'sales' &&
        props.initialStep === 'completed_visit'
      ) {
        upsertLead({
          variables: {
            input: {
              lead: {
                id: parentId,
                mandateProbability: number_of_string(values.mandateProbability),
                predictedListingDate: values.predictedListingDate,
              },
            },
          },
        });
      }
    };

    const createActivity = () => {
      if (doNothing) {
        finishChain();
        return;
      }

      const activityType = getActivityTypeFromStep(values.step);
      let dueAt = null;
      let endAt = null;
      let startDate = null;
      let endDate = null;

      if (activityType === 'visit') {
        const dates = activityOfTimeRange(values.timeRange);
        dueAt = dates.dueAt ? dates.dueAt.toISOString() : null;
        endAt = dates.endAt ? dates.endAt.toISOString() : null;
        startDate = dates.startDate;
        endDate = dates.endDate;
      } else if (activityType === 'call' || activityType === 'task') {
        const parsedDate = parseDate(values.startDate);
        let parsedTime = null;
        if (parsedDate) {
          parsedTime = parseTime(parsedDate, values.dueAt);
          if (parsedTime == null) {
            // set whole day
            startDate = format(parsedDate, 'yyyy-MM-dd');
            // set start of day
            parsedTime = parseTime(parsedDate, '00:00');
          }
        }
        dueAt = parsedTime?.toISOString();
      }

      const subject = values.subject.trim();
      const note = values.note.trim();
      const parent =
        activity.enquiry ??
        activity.lot ??
        activity.lead ??
        activity.user ??
        activity.buyerLead;
      const input = {
        parentId: parent?.id,
        createdBy: values.createdBy?.id,
        visibility: values.visibility,
        assignedTo: values.assignedTo?.id ?? null,
        participants: (activity.participants ?? []).map(p => {
          return {
            name: p.name,
            email: p.email,
            id: p.user?.id,
          };
        }),
        dueAt,
        endAt,
        startDate,
        endDate,
        done: false,
        subject: subject.length === 0 ? null : subject,
        location: values.location,
        note: note.length === 0 ? null : note,
        activityType,
      };
      upsertActivity({
        variables: {
          input: {
            activity: {
              ...input,
            },
            notifyParticipants,
          },
        },
        onCompleted: () => {
          if (parentType === 'Lead' && values.step === 'lead_lost') {
            const selectedStage = lostStages.find(
              stage => stage.label === note || stage.labelEN === 'Other',
            );

            upsertLead({
              variables: {
                input: {
                  lead: {
                    id: parentId,
                    stageId: selectedStage?.id,
                  },
                },
              },
              onCompleted: () => finishChain(),
            });
          } else {
            finishChain();
          }
        },
      });
    };
    // run chain
    finishActivity();
  };

  const form = useFormik({
    initialValues,

    validate: values => {
      const errors: FormikErrors<State> = {};
      if (
        values.step === 'completed_visit_note' ||
        values.step === 'completed_call_note' ||
        values.step === 'cancelled_visit_note'
      ) {
        const finishedNote = values.finishedNote;
        if (finishedNote.trim().length === 0) {
          errors.finishedNote = t('Please leave a comment');
        }
      }
      if (
        values.step === 'completed_visit_note' &&
        root.tenantSettings?.countryCode === 'CH' &&
        parentType === 'Lead' &&
        leadType === 'sales'
      ) {
        if (values.mandateProbability === '') {
          errors.mandateProbability = t('This field is required');
        }
        if (
          values.mandateProbability !== '' &&
          values.mandateProbability !== '0' &&
          values.predictedListingDate == null
        ) {
          errors.predictedListingDate = t('This field is required');
        }
      }
      if (values.step === 'schedule_call' || values.step === 'schedule_task') {
        const parsedDate = parseDate(values.startDate);
        if (parsedDate == null) {
          errors.startDate = t('Invalid date');
        }
      }
      if (values.step === 'schedule_visit' && values.timeRange != null) {
        const dates = activityOfTimeRange(values.timeRange);

        if (dates.startDate == null && dates.dueAt == null) {
          errors.startDate = t('Invalid date');
        }
      }
      if (
        values.step === 'schedule_visit' ||
        values.step === 'schedule_call' ||
        values.step === 'schedule_task'
      ) {
        if (values.subject.trim().length === 0) {
          errors.subject = t('Please leave a subject');
        }
      }
      if (values.step === 'lead_lost' && leadType !== 'financing') {
        const note = values.note;
        if (note.trim().length === 0) {
          errors.note = t('Select answer or leave comment');
        }
      }
      return errors;
    },

    onSubmit: values => {
      if (values.step === 'completed_visit_note') {
        form.setValues({ step: 'completed_visit_list' });
      }
      if (values.step === 'completed_call_note') {
        form.setValues({ step: 'completed_call_list' });
      }
      if (values.step === 'cancelled_visit_note') {
        form.setValues({ step: 'cancelled_visit_list' });
      }
      if (
        values.step === 'schedule_visit' ||
        values.step === 'schedule_call' ||
        values.step === 'schedule_task' ||
        values.step === 'lead_lost' ||
        values.step === 'buyer_lead_lost' ||
        values.step === 'note' ||
        values.step === 'lead_won' ||
        values.step === 'financing_lead_won'
      ) {
        submitForm(values, false);
      }
    },
  });

  const setNextStep = (step: Step) => {
    form.setValues({ step });

    const buyerLeadId = activity.buyerLead?.id;

    if (
      (step === 'financing_lead_won' || step === 'lead_won') &&
      buyerLeadId != null
    ) {
      updateBuyerLead({
        variables: {
          id: buyerLeadId,
          status: 'won',
        },
        onCompleted: () => {
          submitForm(form.values, true);
        },
      });
    }

    if (
      (step === 'lead_lost' || step === 'buyer_lead_lost') &&
      buyerLeadId != null
    ) {
      updateBuyerLead({
        variables: {
          id: buyerLeadId,
          status: 'dead',
        },
      });
    }

    if (step === 'do_nothing') {
      submitForm(form.values, true);
    }

    const activityType = getActivityTypeFromStep(step);

    if (activityType === 'call' || activityType === 'visit') {
      const prefilledSubject = getDefaultSubject({
        t,
        participants: (activity.displayParticipants ?? []).map(p => {
          return {
            name: p.name,
          };
        }),
        assignedTo: {
          firstName: assignedToUser?.firstName ?? null,
          lastName: assignedToUser?.lastName ?? null,
          organisationName: assignedToUser?.organisation?.name ?? null,
        },
        activityType,
      });

      form.setValues({ subject: prefilledSubject });
    }

    if (activityType === 'visit') {
      const location =
        activity.location != null
          ? activity.location
          : parentType === 'Lead'
          ? activity.lead?.property?.formattedAddress
          : parentType === 'Lot'
          ? activity.lot?.property.formattedAddress
          : null;
      if (location != null) {
        form.setValues({ location });
      }
    }
  };
  const closeAllOffClick = () => {
    const finishedNote = form.values.finishedNote;

    // Don't prompt warning for these as they are handled in activity-event-dialog.js
    const activitySteps = ['schedule_visit', 'schedule_call', 'schedule_task'];

    if (
      finishedNote.trim().length > 0 &&
      !activitySteps.includes(form.values.step)
    ) {
      if (confirm(t('Activity outcome not saved'))) {
        form.resetForm();
        props.onClose();
      }
    } else {
      form.resetForm();
      props.onClose();
    }
  };

  const closeAll = () => {
    form.resetForm();
    props.onClose();
  };

  const onGoBack = () => {
    steps.pop();
    form.setValues({ step: steps[steps.length - 1] });
    // Clear any setShowUnfinishedWarning warnings
    props.setShowUnfinishedWarning(false);
    // Below is needed in case active dialog has been collapsed on previous step
    props.setOpenDialog(true);
  };

  const currentStep = props.open ? form.values.step : ('none' as Step);
  const [steps, setSteps] = React.useState<Step[]>([]);

  React.useEffect(() => {
    setSteps(prev => [...prev, form.values.step]);
  }, [form.values.step]);

  const notificationCheckbox = props.allowNotifyParticipants === true && (
    <FormControlLabel
      label={t('Notify participants')}
      control={
        <Switch
          checked={form.values.notifyParticipants}
          onChange={e => {
            form.setValues({
              notifyParticipants: e.target.checked,
            });
          }}
        />
      }
    />
  );

  const isBrokerageModuleIncluded = root.me?.modules.includes('brokerage');

  const scheduleActions = {
    schedule_visit: 'visit',
    schedule_call: 'call',
    schedule_task: 'task',
  } as const;

  return (
    <>
      {/* visit dialogs */}
      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'completed_visit_note'}
        onClose={closeAllOffClick}
        // Creating TrapFocus error with date picker
        disableEnforceFocus={true}
      >
        <DialogTitle>{t('Visit done')}</DialogTitle>
        <DialogContent>
          <Subtitle>{t('What happened during the visit?')}</Subtitle>
          <MarkdownComposer
            initialValue={form.values.finishedNote}
            onChange={finishedNote => form.setValues({ finishedNote })}
          />
          {form.errors.finishedNote != null && (
            <FormHelperText error={true}>
              {form.errors.finishedNote}
            </FormHelperText>
          )}
          {root.tenantSettings?.countryCode === 'CH' &&
            parentType === 'Lead' &&
            leadType === 'sales' && (
              <>
                <Box py={2}>
                  <Subtitle>{t('Can we sign the mandate? When?')}</Subtitle>
                </Box>
                <Flex>
                  <Box width={[1, 1 / 2]} pr={3}>
                    <TextField
                      variant="filled"
                      select={true}
                      value={form.values.mandateProbability}
                      label={t('Mandate probability')}
                      onChange={event => {
                        form.setValues(prev => ({
                          mandateProbability: event.target.value,
                          predictedListingDate:
                            event.target.value === '0'
                              ? null
                              : prev.predictedListingDate,
                        }));
                      }}
                      helperText={form.errors.mandateProbability}
                      error={form.errors.mandateProbability != null}
                    >
                      {root.mandateProbabilityTypes.map(option => (
                        <MenuItem value={option.name} key={option.name}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  {form.values.mandateProbability !== '' &&
                    form.values.mandateProbability !== '0' && (
                      <Box width={[1, 1 / 2]}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <FormControl variant="filled">
                            <MobileDatePicker
                              views={['year', 'month']}
                              openTo="year"
                              label={t('Predicted listing date')}
                              value={
                                form.values.predictedListingDate != null
                                  ? parseISO(form.values.predictedListingDate)
                                  : null
                              }
                              onMonthChange={date => {
                                form.setValues({
                                  predictedListingDate: date.toISOString(),
                                });
                              }}
                              onChange={() => {}}
                              closeOnSelect={true}
                              slotProps={{
                                textField: {
                                  InputProps: {
                                    error:
                                      form.errors.predictedListingDate != null,
                                    endAdornment:
                                      form.values.predictedListingDate !=
                                      null ? (
                                        <InputAdornment position="end">
                                          <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={event => {
                                              event.stopPropagation();
                                              form.setValues({
                                                predictedListingDate: null,
                                              });
                                            }}
                                          >
                                            <Cancel />
                                          </IconButton>
                                        </InputAdornment>
                                      ) : null,
                                  },
                                },
                              }}
                            />
                            {form.errors.predictedListingDate != null && (
                              <FormHelperText error={true}>
                                {form.errors.predictedListingDate}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </LocalizationProvider>
                      </Box>
                    )}
                </Flex>
              </>
            )}
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button onClick={closeAll}>{t('Cancel')}</Button>
          <Button onClick={form.submitForm}>{t('Next')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'completed_visit_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Visit done')}</DialogTitle>
        <NextStepDialogContent
          activityType="visit"
          type="completed"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button
            onClick={() => form.setValues({ step: 'completed_visit_note' })}
          >
            {t('Back')}
          </Button>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'cancelled_visit_note'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Visit cancelled')}</DialogTitle>
        <DialogContent>
          <Subtitle>{t('Why was the visit cancelled?')}</Subtitle>
          <MarkdownComposer
            initialValue={form.values.finishedNote}
            onChange={finishedNote => form.setValues({ finishedNote })}
          />
          {form.errors.finishedNote != null && (
            <FormHelperText error={true}>
              {form.errors.finishedNote}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button onClick={closeAll}>{t('Cancel')}</Button>
          <Button onClick={form.submitForm}>{t('Next')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'cancelled_visit_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Visit cancelled')}</DialogTitle>
        <NextStepDialogContent
          activityType="visit"
          type="canceled"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button
            onClick={() => form.setValues({ step: 'cancelled_visit_note' })}
          >
            {t('Back')}
          </Button>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      {/* call dialogs */}

      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'completed_call_note'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Successful call')}</DialogTitle>
        <DialogContent>
          <Subtitle>{t('What happened during the call?')}</Subtitle>
          <MarkdownComposer
            initialValue={form.values.finishedNote}
            onChange={finishedNote => form.setValues({ finishedNote })}
          />
          {form.errors.finishedNote != null && (
            <FormHelperText error={true}>
              {form.errors.finishedNote}
            </FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button onClick={closeAll}>{t('Cancel')}</Button>
          <Button onClick={form.submitForm}>{t('Next')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'completed_call_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Successful call')}</DialogTitle>
        <NextStepDialogContent
          activityType="call"
          type="completed"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button
            onClick={() => form.setValues({ step: 'completed_call_note' })}
          >
            {t('Back')}
          </Button>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'cancelled_call_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Call attempt unsuccessful')}</DialogTitle>
        <NextStepDialogContent
          activityType="call"
          type="canceled"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      {/* task dialogs */}

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'completed_task_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Task completed')}</DialogTitle>
        <NextStepDialogContent
          activityType="task"
          type="completed"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        maxWidth="xs"
        open={currentStep === 'cancelled_task_list'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Task cancelled')}</DialogTitle>
        <NextStepDialogContent
          activityType="task"
          type="canceled"
          parentType={parentType}
          leadType={leadType}
          onNext={setNextStep}
        />
        <DialogActions>
          <Button onClick={closeAll}>{t('Cancel')}</Button>
        </DialogActions>
      </Dialog>

      {/* new activity form dialogs */}
      {currentStep !== 'none' && currentStep in scheduleActions && (
        <ActivityEventDialog
          openDialog={props.openDialog}
          setOpenDialog={props.setOpenDialog}
          showUnfinishedWarning={props.showUnfinishedWarning}
          form={form}
          nylasEmail={props.nylasEmail ?? null}
          isWorkflow={true}
          isCreating={true}
          activityType={
            scheduleActions[currentStep as keyof typeof scheduleActions]
          }
          allowNotifyParticipants={props.allowNotifyParticipants === true}
          isUpserting={isLoading}
          onSubmit={form.submitForm}
          onCancel={closeAll}
          onGoBack={onGoBack}
          isAdmin={root.me?.isAdmin ?? false}
          canEditVisibility={root.me?.isAdmin ?? false}
        />
      )}

      <FinancingLeadLostDialog
        open={currentStep === 'lead_lost' && leadType === 'financing'}
        onChange={note => {
          form.setValues({
            note,
          });
        }}
        onConfirm={form.submitForm}
        onClose={closeAllOffClick}
      />
      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'lead_lost' && leadType !== 'financing'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Lead lost')}</DialogTitle>
        <DialogContent>
          <Subtitle>{t('Why is this lead dead?')}</Subtitle>
          <NotePreset
            form={form}
            leadType={leadType}
            preset={lostStages.map(stage => stage.label)}
          />
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button color="inherit" onClick={closeAll}>
            {t('Cancel')}
          </Button>
          <ProgressButton loading={isLoading} onClick={form.submitForm}>
            {t('Confirm')}
          </ProgressButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'buyer_lead_lost'}
        onClose={closeAllOffClick}
      >
        <DialogTitle>{t('Not interested')}</DialogTitle>
        <DialogContent>
          <MarkdownComposer
            initialValue={form.values.note}
            onChange={note => form.setValues({ note })}
          />
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button color="inherit" onClick={closeAll}>
            {t('Cancel')}
          </Button>
          <ProgressButton loading={isLoading} onClick={form.submitForm}>
            {t('Confirm')}
          </ProgressButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={responsive([true, false])}
        open={currentStep === 'note'}
        onClose={closeAllOffClick}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <DialogTitle css={{ marginRight: 16 }}>
            {t('Write a note')}
          </DialogTitle>
          <Button
            css={{ marginRight: 8 }}
            onClick={onGoBack}
            startIcon={<ChevronLeft />}
          >
            {t('Back')}
          </Button>
        </Flex>
        <DialogContent>
          <MarkdownComposer
            initialValue={form.values.note}
            onChange={note => form.setValues({ note })}
          />
        </DialogContent>
        <DialogActions>
          {notificationCheckbox}
          <Button color="inherit" onClick={closeAll}>
            {t('Cancel')}
          </Button>
          <ProgressButton loading={isLoading} onClick={form.submitForm}>
            {t('Confirm')}
          </ProgressButton>
        </DialogActions>
      </Dialog>
      {activity.lead && activity.lead.lot == null && (
        <CreateListingModal
          fromLeadId={fromGlobalId(activity.lead.id)}
          open={
            currentStep === 'lead_won' && form.values.showCreateListingModal
          }
          onClose={closeAll}
          onListingCreated={lotId => {
            handleActivityUpdate(form.values.finishedNote, () => {
              handleLeadStageUpdate(form.values.selectedStageId, () => {
                closeAll();
                if (isBrokerageModuleIncluded === true) {
                  navigate(`/listings/${lotId}`);
                }
              });
            });
          }}
        />
      )}
      {activity.lead && (
        <FinancingWonDialog
          lead={activity.lead}
          open={currentStep === 'financing_lead_won'}
          onCreate={() => {
            handleActivityUpdate(form.values.finishedNote, closeAll);
          }}
          onClose={closeAll}
        />
      )}

      <PipelineDialog
        open={
          currentStep === 'pipeline_change' ||
          (currentStep === 'lead_won' && !form.values.showCreateListingModal)
        }
        onClose={() => {
          form.setValues({ step: 'none' });
          props.onNewActivity();
        }}
        initialStageId={
          currentStep === 'lead_won' ? wonStageId : activity.lead?.stage?.id
        }
        pipelines={pipelineType}
        loading={
          upsertActivityLoading || upsertLeadLoading || updateLeadLoading
        }
        onChange={({ selectedStage }) => {
          if (!activity.lead) {
            form.setValues({ step: 'none' });
            props.onNewActivity();
            closeAll();
            return;
          }

          if (currentStep === 'lead_won') {
            if (activity.lead.lot == null) {
              form.setValues({
                step: 'lead_won',
                showCreateListingModal: true,
                selectedStageId: selectedStage.id,
              });
              return;
            }

            handleActivityUpdate(form.values.finishedNote, () => {
              handleLeadStageUpdate(selectedStage.id, closeAll);
            });
          } else {
            updateLead({
              variables: {
                id: activity.lead.id,
                stageId: selectedStage.id,
              },
              onCompleted: () => {
                form.setValues({ step: 'none' });
                props.onNewActivity();
                closeAll();
              },
            });
          }
        }}
      />
    </>
  );
};
