import * as React from 'react';

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Button } from '@mui/material';
import {
  type Locale as DateFnsLocale,
  endOfDay,
  endOfMonth,
  format,
  isSameMonth,
  isSameYear,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, useResponsive } from 'react-system';

import {
  OrganisationFilter,
  type OrganisationParams,
  organisationParams,
} from '../../../shared/organisation-filter';
import {
  BrokerFilter,
  type BrokerParams,
  brokerParams,
} from '../../components/BrokerFilter';
import {
  ContactFilter,
  type ContactParams,
  contactParams,
} from '../../components/ContactFilter';
import {
  DateRangeFilter,
  type DateRangeParams,
  dateRangeParams,
} from '../../components/DateRangeFilter';
import {
  PropertyTypeFilter,
  type PropertyTypeParams,
  propertyTypeParams,
} from '../../components/PropertyTypeFilter';
import { Checkbox as MyCheckbox } from '../../controls/checkbox';
import { Filter, FiltersMenu } from '../../controls/Filters';
import { SquareInput } from '../../controls/meter-input';
import { Menu } from '../../controls/popup';
import { PriceInput } from '../../controls/price-input';
import { Radio } from '../../controls/radio';
import { RangeInput } from '../../controls/range-input';
import { useDebouncedHandler } from '../../hooks/debounce';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import {
  type ExtractFieldMap,
  type Field,
  makeField,
  makeUrlSearchParamsHook,
} from '../../hooks/url-search-params';
import { Brightness1 } from '../../icons/brightness-1';
import { KanbanOutline } from '../../icons/kanban-outline';
import { ListOutline } from '../../icons/list-outline';
import { LocationMapOutline } from '../../icons/location-map-outline';
import { MoreVert } from '../../icons/more-vert';
import { RoundWarning } from '../../icons/round-warning';
import { CompletedFilter } from '../../shared/CompletedFilter';
import {
  makeNullableListField,
  makeNullableNumberField,
  toggleFilterItem,
} from '../../shared/filter-helpers';
import {
  LeadSourceFilter,
  type LeadSourceParams,
  leadSourceParams,
} from '../../shared/lead-source-filter';
import { NextActivityOverdueFilter } from '../../shared/NextActivityOverdueFilter';
import {
  PlaceFilter,
  type PlaceParams,
  placeParams,
} from '../../shared/place-filter';
import {
  RelationshipFilter,
  type RelationshipParams,
  relationshipParams,
} from '../../shared/relationship-filter';
import {
  translateAppraisalPerception,
  translateAppraisalReason,
  translateBuyHorizon,
  translateSaleHorizon,
} from '../../utils/lead-labels';
import { number_of_string } from '../../utils/number-format';
import { type SortingParams, sortingParams } from '../../utils/sorting';

import type { LeadsFiltersQuery } from './__generated__/LeadsFiltersQuery.graphql';
import type { LeadsFiltersStagesQuery } from './__generated__/LeadsFiltersStagesQuery.graphql';
import type {
  LeadAppraisalReason,
  LeadBuyHorizon,
  LeadSaleHorizon,
  LeadsFilters as LeadsFiltersType,
} from './__generated__/LeadsQuery.graphql';

type Tab = 'list' | 'map' | 'kanban';

const tab_of_string = (string: null | string): Tab => {
  switch (string) {
    case 'list':
    case 'map':
    case 'kanban':
      return string;
    default:
      return 'list';
  }
};

type NextActivityType =
  | 'call'
  | 'visit'
  | 'task'
  | 'assignment'
  | 'no_activity';

const next_activity_of_string = (string: string): NextActivityType => {
  switch (string) {
    case 'call':
    case 'visit':
    case 'task':
    case 'assignment':
    case 'no_activity':
      return string;
    default:
      throw new Error(`unsupported next activity: ${string}`);
  }
};

const sale_horizon_of_string = (string: string): LeadSaleHorizon => {
  switch (string) {
    case 'under_6_months':
    case 'from_6_to_12_months':
    case 'from_1_to_2_years':
    case 'from_2_years':
    case 'already_for_sale':
    case 'not_selling':
      return string;
    case 'not_set':
    default:
      return 'not_set';
  }
};

const buy_horizon_of_string = (string: string): LeadBuyHorizon => {
  switch (string) {
    case 'under_3_months':
    case 'under_12_months':
    case 'over_12_months':
      return string;
    case 'not_set':
    default:
      return 'not_set';
  }
};

const mortgage_buying_status_of_string = (string: string): string => {
  switch (string) {
    case 'searching':
    case 'visited':
    case 'offer':
      return string;
    default:
      throw new Error(`unsupported next activity: ${string}`);
  }
};

const mortgage_status_of_string = (string: string): string => {
  switch (string) {
    case 'not_started':
    case 'working_with_bank':
    case 'working_with_independent':
      return string;
    default:
      throw new Error(`unsupported next activity: ${string}`);
  }
};

const appraisal_reason_of_string = (string: string): LeadAppraisalReason => {
  switch (string) {
    case 'buy_soon':
    case 'inheritance':
    case 'not_selling':
    case 'buy_this':
    case 'other_not_owner':
    case 'other_owner':
    case 'already_for_sale':
    case 'separation':
    case 'move_rental':
    case 'sell':
    case 'refinance':
    case 'other_renting':
    case 'other':
      return string;
    case 'not_set':
    default:
      return 'not_set';
  }
};

export type RequalificationParams = {
  useRequalifiedIfAvailable: Field<boolean | null>;
  mostRecent: Field<boolean | null>;
};

export const requalificationParams: RequalificationParams = {
  useRequalifiedIfAvailable: makeField({
    get: params => {
      return params.getBoolean('useRequalifiedIfAvailable');
    },
    set: (params, value) =>
      params.setBoolean('useRequalifiedIfAvailable', value),
  }),

  mostRecent: makeField({
    get: params => {
      return params.getBoolean('mostRecent');
    },
    set: (params, value) => params.setBoolean('mostRecent', value),
  }),
};

export type LeadsParamsFields = SortingParams &
  DateRangeParams &
  BrokerParams &
  ContactParams &
  OrganisationParams &
  PlaceParams &
  PropertyTypeParams &
  RelationshipParams &
  LeadSourceParams &
  RequalificationParams & {
    tab: Field<Tab>;
    leadLostId_in: Field<string[] | null>;
    pipelineId_eq: Field<string | null>;
    completed_in: Field<boolean[] | null>;
    potentialDevelopment_eq: Field<boolean | null>;
    status_in: Field<string[] | null>;
    stageId_in: Field<string[] | null>;
    appraisalReason_in: Field<LeadAppraisalReason[] | null>;
    appraisalPerception_in: Field<string[] | null>;
    buyHorizon_in: Field<LeadBuyHorizon[] | null>;
    saleHorizon_in: Field<LeadSaleHorizon[] | null>;
    mandateProbability_in: Field<number[] | null>;
    predictedListingStartDate: Field<Date | null>;
    predictedListingEndDate: Field<Date | null>;
    tags_in: Field<string[] | null>;
    appraisalNextStep_in: Field<string[] | null>;
    appraisalPropertyUsage_in: Field<string[] | null>;
    appraisalPropertyOccupied_in: Field<string[] | null>;
    appraisalOpenToOffer_in: Field<string[] | null>;
    livingSurface_gte: Field<number | null>;
    livingSurface_lte: Field<number | null>;
    landSurface_gte: Field<number | null>;
    landSurface_lte: Field<number | null>;
    residentialSurface_gte: Field<number | null>;
    residentialSurface_lte: Field<number | null>;
    commercialSurface_gte: Field<number | null>;
    commercialSurface_lte: Field<number | null>;
    appraisalValue_gte: Field<number | null>;
    appraisalValue_lte: Field<number | null>;
    nextActivity: Field<NextActivityType[] | null>;
    nextActivity_overdue: Field<boolean | null>;
    mortgageBuyingStatus_in: Field<string[] | null>;
    mortgageStatus_in: Field<string[] | null>;
    receivedMortgageOffer_in: Field<boolean[] | null>;
  };

export type LeadsParams = ExtractFieldMap<LeadsParamsFields>;

export const useLeadsParams = makeUrlSearchParamsHook<LeadsParamsFields>({
  ...requalificationParams,
  ...sortingParams,
  ...dateRangeParams,
  ...brokerParams,
  ...contactParams,
  ...organisationParams,
  ...placeParams,
  ...propertyTypeParams,
  ...relationshipParams,
  ...leadSourceParams,
  tab: makeField({
    get: params => tab_of_string(params.getString('tab')),
    set: (params, value) => params.setString('tab', value),
  }),
  leadLostId_in: makeField({
    get: params => {
      const list = params.getAllStrings('leadLostId_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('leadLostId_in', value || []),
  }),
  pipelineId_eq: makeField({
    get: params => params.getString('pipelineId_eq'),
    set: (params, value) => params.setString('pipelineId_eq', value),
  }),
  completed_in: makeField({
    get: params => {
      const list = params.getAllBooleans('completed_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllBooleans('completed_in', value ?? []),
  }),
  potentialDevelopment_eq: makeField({
    get: params => params.getBoolean('potentialDevelopment_eq'),
    set: (params, value) => params.setBoolean('potentialDevelopment_eq', value),
  }),
  status_in: makeNullableListField('status_in'),
  stageId_in: makeNullableListField('stageId_in'),
  appraisalReason_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('appraisalReason_in')
        .map(appraisal_reason_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('appraisalReason_in', value ?? []),
  }),
  appraisalPerception_in: makeNullableListField('appraisalPerception_in'),
  buyHorizon_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('buyHorizon_in')
        .map(buy_horizon_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('buyHorizon_in', value ?? []),
  }),
  saleHorizon_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('saleHorizon_in')
        .map(sale_horizon_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('saleHorizon_in', value ?? []),
  }),
  appraisalNextStep_in: makeNullableListField('appraisalNextStep_in'),
  appraisalPropertyUsage_in: makeNullableListField('appraisalPropertyUsage_in'),
  appraisalPropertyOccupied_in: makeNullableListField(
    'appraisalPropertyOccupied_in',
  ),
  appraisalOpenToOffer_in: makeNullableListField('appraisalOpenToOffer_in'),
  livingSurface_gte: makeNullableNumberField('livingSurface_gte'),
  livingSurface_lte: makeNullableNumberField('livingSurface_lte'),
  residentialSurface_gte: makeNullableNumberField('residentialSurface_gte'),
  residentialSurface_lte: makeNullableNumberField('residentialSurface_lte'),
  commercialSurface_gte: makeNullableNumberField('commercialSurface_gte'),
  commercialSurface_lte: makeNullableNumberField('commercialSurface_lte'),
  landSurface_gte: makeNullableNumberField('landSurface_gte'),
  landSurface_lte: makeNullableNumberField('landSurface_lte'),
  appraisalValue_gte: makeNullableNumberField('appraisalValue_gte'),
  appraisalValue_lte: makeNullableNumberField('appraisalValue_lte'),
  nextActivity: makeField({
    get: params => {
      const list = params.getAllStrings('nextActivity');

      // if "No activity" selected ignore other values.
      // can happen with manual URL editing.
      if (list.includes('no_activity')) {
        return ['no_activity'];
      }

      return list.length === 0 ? null : list.map(next_activity_of_string);
    },
    set: (params, value) => params.setAllStrings('nextActivity', value ?? []),
  }),
  nextActivity_overdue: makeField({
    get: params => params.getBoolean('nextActivity_overdue'),
    set: (params, value) => params.setBoolean('nextActivity_overdue', value),
  }),
  mortgageBuyingStatus_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('mortgageBuyingStatus_in')
        .map(mortgage_buying_status_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('mortgageBuyingStatus_in', value ?? []),
  }),
  mortgageStatus_in: makeField({
    get: params => {
      const list = params
        .getAllStrings('mortgageStatus_in')
        .map(mortgage_status_of_string);
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllStrings('mortgageStatus_in', value ?? []),
  }),
  receivedMortgageOffer_in: makeField({
    get: params => {
      const list = params.getAllBooleans('receivedMortgageOffer_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllBooleans('receivedMortgageOffer_in', value ?? []),
  }),
  mandateProbability_in: makeField({
    get: params => {
      const list = params.getAllNumbers('mandateProbability_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) =>
      params.setAllNumbers('mandateProbability_in', value ?? []),
  }),
  predictedListingStartDate: makeField({
    get: params => {
      const timestamp = params.getNumber('predictedListingStartDate');
      return timestamp == null ? null : startOfDay(timestamp);
    },
    set: (params, value) =>
      params.setNumber('predictedListingStartDate', value?.getTime() ?? null),
  }),
  predictedListingEndDate: makeField({
    get: params => {
      const timestamp = params.getNumber('predictedListingEndDate');
      return timestamp == null ? null : endOfDay(timestamp);
    },
    set: (params, value) =>
      params.setNumber('predictedListingEndDate', value?.getTime() ?? null),
  }),
  tags_in: makeField({
    get: params => {
      const list = params.getAllStrings('tags_in');
      return list.length === 0 ? null : list;
    },
    set: (params, value) => params.setAllStrings('tags_in', value ?? []),
  }),
});

export const paramsToFilters = (params: LeadsParams): LeadsFiltersType => {
  return {
    sourceId_in: params.sourceId_in,
    // If stageId_in is not empty then do not filter via pipelineId
    pipelineId_eq:
      params.stageId_in != null && params.stageId_in.length > 0
        ? null
        : params.pipelineId_eq,
    completed_in: params.completed_in,
    leadLostId_in: params.leadLostId_in,
    brokerId_in: params.brokerId_in,
    organisationId_in: params.organisationId_in,
    contactId_in: params.contactId_in,
    status_in: params.status_in,
    appraisalPerception_in: params.appraisalPerception_in,
    buyHorizon_in: params.buyHorizon_in,
    saleHorizon_in: params.saleHorizon_in,
    stageId_in: params.stageId_in,
    appraisalReason_in: params.appraisalReason_in,
    appraisalNextStep_in: params.appraisalNextStep_in,
    appraisalPropertyUsage_in: params.appraisalPropertyUsage_in,
    appraisalPropertyOccupied_in: params.appraisalPropertyOccupied_in,
    appraisalOpenToOffer_in: params.appraisalOpenToOffer_in,
    potentialDevelopment_eq: params.potentialDevelopment_eq,
    createdAt_inRange: {
      startDate: params.startDate ? params.startDate.toISOString() : null,
      endDate: params.endDate ? params.endDate.toISOString() : null,
      useRequalifiedIfAvailable: params.useRequalifiedIfAvailable,
      mostRecent: params.mostRecent,
    },
    property_placeId_in: params.placeId_in,
    property_landSurface_gte: params.landSurface_gte,
    property_landSurface_lte: params.landSurface_lte,
    appraisalValue_gte: params.appraisalValue_gte,
    appraisalValue_lte: params.appraisalValue_lte,
    property_livingSurface_gte: params.livingSurface_gte,
    property_livingSurface_lte: params.livingSurface_lte,
    property_residentialSurface_gte: params.residentialSurface_gte,
    property_residentialSurface_lte: params.residentialSurface_lte,
    property_commercialSurface_gte: params.commercialSurface_gte,
    property_commercialSurface_lte: params.commercialSurface_lte,
    relationship_in: params.relationship_in,
    property_propertyType_name_in: params.propertyType_name_in,
    nextActivity_exists: params.nextActivity
      ? !params.nextActivity.includes('no_activity')
      : null,
    nextActivity_type_in: params.nextActivity?.includes('no_activity')
      ? null
      : params.nextActivity,
    nextActivity_overdue: params.nextActivity_overdue,
    mortgageBuyingStatus_in: params.mortgageBuyingStatus_in,
    mortgageStatus_in: params.mortgageStatus_in,
    receivedMortgageOffer_in: params.receivedMortgageOffer_in,
    predictedListingDate_inRange: {
      startDate: params.predictedListingStartDate?.toISOString() ?? null,
      endDate: params.predictedListingEndDate?.toISOString() ?? null,
    },
    mandateProbability_in: params.mandateProbability_in,
    tags_in: params.tags_in,
  };
};

const NormalFilterContent = <T extends string>({
  nodes,
  selectedIds,
  onClick,
}: {
  nodes: ReadonlyArray<{ id: T; label: null | string }>;
  selectedIds: Set<T>;
  onClick: (node: T) => void;
}) => (
  <Box
    width="300px"
    py={2}
    css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
  >
    {nodes.map(item => (
      <Box key={item.id} px={3}>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              color="primary"
              checked={selectedIds.has(item.id)}
              onChange={() => onClick(item.id)}
            />
          }
        />
      </Box>
    ))}
  </Box>
);

const getLabel = (
  nodes: ReadonlyArray<Readonly<{ id: string; label: null | string }>>,
  ids: Set<string>,
  idleLabel: string,
) => {
  if (ids.size === 0) {
    return idleLabel;
  }
  const firstId = Array.from(ids)[0];
  const first = nodes.find(d => d.id === firstId);
  if (first && first.label != null) {
    return ids.size > 1 ? `${first.label} +${ids.size - 1}` : first.label;
  }
  return '';
};

const DevelopmentFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  let label = t('development');
  if (params.potentialDevelopment_eq === true) {
    label = t('potentialDevelopment');
  }
  return (
    <Filter
      dialogTitle={t('development')}
      label={label}
      empty={params.potentialDevelopment_eq !== true}
      onReset={() => setParams({ potentialDevelopment_eq: null })}
    >
      <Box px={3} py={2}>
        <MyCheckbox
          checked={params.potentialDevelopment_eq === true}
          onChange={value => setParams({ potentialDevelopment_eq: value })}
        >
          {t('potentialDevelopment')}
        </MyCheckbox>
      </Box>
    </Filter>
  );
};

const AppraisalReasonFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const appraisalReason_in = params.appraisalReason_in ?? [];

  const labels = appraisalReason_in.map(reason => {
    return translateAppraisalReason(t, reason);
  });

  const toggle = (value: LeadAppraisalReason, checked: boolean) => {
    const newSet = new Set(appraisalReason_in);
    if (checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    setParams({ appraisalReason_in: Array.from(newSet) });
  };

  return (
    <Filter
      label={
        appraisalReason_in.length === 0
          ? t('appraisalReasons')
          : labels.join(', ')
      }
      dialogTitle={t('appraisalReasons')}
      empty={appraisalReason_in.length === 0}
      onReset={() => setParams({ appraisalReason_in: null })}
    >
      <Box
        width="300px"
        py={2}
        px={3}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        {(
          [
            'buy_this',
            'buy_soon',
            'inheritance',
            'refinance',
            'sell',
            'already_for_sale',
            'not_selling',
            'other_not_owner',
            'other_owner',
            'separation',
            'move_rental',
            'other_renting',
            'other',
            'not_set',
          ] as const
        ).map(id => (
          <MyCheckbox
            key={id}
            color="primary"
            checked={appraisalReason_in.includes(id)}
            onChange={checked => toggle(id, checked)}
          >
            {translateAppraisalReason(t, id)}
          </MyCheckbox>
        ))}
      </Box>
    </Filter>
  );
};

const AppraisalPropertyUsageFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.appraisalPropertyUsage_in);
  const toggle = (id: string) =>
    setParams({ appraisalPropertyUsage_in: toggleFilterItem(selectedIds, id) });
  const nodes = [
    { id: 'primary_residence', label: t('primaryResidence') },
    { id: 'secondary_home', label: t('secondaryHome') },
  ];

  return (
    <Filter
      label={getLabel(nodes, selectedIds, t('appraisalPropertyUsage'))}
      empty={selectedIds.size === 0}
      dialogTitle={t('appraisalPropertyUsage')}
      onReset={() => setParams({ appraisalPropertyUsage_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const AppraisalPropertyOccupiedFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.appraisalPropertyOccupied_in);
  const nodes = [
    { id: 'owner_occupied', label: t('ownerOccupied') },
    { id: 'tenant_occupied', label: t('tenantOccupied') },
    { id: 'vacant', label: t('vacant') },
    { id: 'i_dont_know', label: t('unknown') },
  ];
  const toggle = (id: string) =>
    setParams({
      appraisalPropertyOccupied_in: toggleFilterItem(selectedIds, id),
    });

  return (
    <Filter
      label={getLabel(nodes, selectedIds, t('appraisalPropertyOccupied'))}
      empty={selectedIds.size === 0}
      dialogTitle={t('appraisalPropertyOccupied')}
      onReset={() => setParams({ appraisalPropertyOccupied_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const AppraisalOpenToOfferFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.appraisalOpenToOffer_in);
  const toggle = (id: string) =>
    setParams({ appraisalOpenToOffer_in: toggleFilterItem(selectedIds, id) });

  const nodes = [
    { id: 'depends_on_price', label: t('maybeOpenToOffer') },
    { id: 'no', label: t('notOpenToOffers') },
  ];

  return (
    <Filter
      label={getLabel(nodes, selectedIds, t('appraisalOpenToOffer'))}
      empty={selectedIds.size === 0}
      dialogTitle={t('appraisalOpenToOffer')}
      onReset={() => setParams({ appraisalOpenToOffer_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const BuyHorizonFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const buyHorizon_in = params.buyHorizon_in ?? [];
  const labels = buyHorizon_in.map(value => translateBuyHorizon(t, value));
  const toggle = (value: LeadBuyHorizon, checked: boolean) => {
    const newSet = new Set(buyHorizon_in);
    if (checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    setParams({ buyHorizon_in: Array.from(newSet) });
  };
  return (
    <Filter
      label={buyHorizon_in.length === 0 ? t('buyHorizons') : labels.join(', ')}
      dialogTitle={t('buyHorizons')}
      empty={buyHorizon_in.length === 0}
      onReset={() => setParams({ buyHorizon_in: null })}
    >
      <Box
        width="300px"
        py={2}
        px={3}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        {(
          [
            'under_3_months',
            'under_12_months',
            'over_12_months',
            'not_set',
          ] as const
        ).map(id => (
          <MyCheckbox
            key={id}
            color="primary"
            checked={buyHorizon_in.includes(id)}
            onChange={checked => toggle(id, checked)}
          >
            {translateBuyHorizon(t, id)}
          </MyCheckbox>
        ))}
      </Box>
    </Filter>
  );
};

const SaleHorizonFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const saleHorizon_in = params.saleHorizon_in ?? [];
  const labels = saleHorizon_in.map(value => translateSaleHorizon(t, value));
  const toggle = (value: LeadSaleHorizon, checked: boolean) => {
    const newSet = new Set(saleHorizon_in);
    if (checked) {
      newSet.add(value);
    } else {
      newSet.delete(value);
    }
    setParams({ saleHorizon_in: Array.from(newSet) });
  };
  return (
    <Filter
      label={
        saleHorizon_in.length === 0 ? t('saleHorizons') : labels.join(', ')
      }
      dialogTitle={t('saleHorizons')}
      empty={saleHorizon_in.length === 0}
      onReset={() => setParams({ saleHorizon_in: null })}
    >
      <Box
        width="300px"
        py={2}
        px={3}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        {(
          [
            'under_6_months',
            'from_6_to_12_months',
            'from_1_to_2_years',
            'from_2_years',
            'already_for_sale',
            'not_selling',
          ] as const
        ).map(value => (
          <MyCheckbox
            key={value}
            color="primary"
            checked={saleHorizon_in.includes(value)}
            onChange={checked => toggle(value, checked)}
          >
            {translateSaleHorizon(t, value)}
          </MyCheckbox>
        ))}
      </Box>
    </Filter>
  );
};

const StatusFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.status_in);
  const nodes = [
    { id: 'active', label: t('Active') },
    { id: 'won', label: t('won') },
    { id: 'lost', label: t('lost') },
  ];
  const toggle = (id: string) =>
    setParams({ status_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      label={getLabel(nodes, selectedIds, t('statuses'))}
      dialogTitle={t('statuses')}
      empty={selectedIds.size === 0}
      onReset={() => setParams({ status_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const StageFilter = ({
  root,
}: {
  root: LeadsFiltersStagesQuery['response'];
}) => {
  const nodes = root.stages ?? [];
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const selectedIds = new Set(params.stageId_in);
  const toggle = (id: string) =>
    setParams({ stageId_in: toggleFilterItem(selectedIds, id) });

  return (
    <Filter
      dialogTitle={t('pipelineStep')}
      label={getLabel(nodes, selectedIds, t('pipelineStep'))}
      empty={selectedIds.size === 0}
      onReset={() => setParams({ stageId_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selectedIds}
        onClick={toggle}
      />
    </Filter>
  );
};

const AppraisalPerceptionFilter = () => {
  const { t } = useLocale();
  const { colors } = useTheme();
  const [params, setParams] = useLeadsParams();
  const appraisalPerceptions = new Set(params.appraisalPerception_in);

  const label =
    appraisalPerceptions.size === 0
      ? t('appraisalPerceptions')
      : Array.from(appraisalPerceptions)
          .map(value => translateAppraisalPerception(t, value))
          .join(', ');

  const toggle = (id: string) =>
    setParams({
      appraisalPerception_in: toggleFilterItem(appraisalPerceptions, id),
    });

  return (
    <Filter
      label={label}
      dialogTitle={t('appraisalPerceptions')}
      empty={appraisalPerceptions.size === 0}
      onReset={() => setParams({ appraisalPerception_in: [] })}
    >
      <Box
        width="300px"
        py={2}
        css={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
      >
        <Box px={3}>
          <FormControlLabel
            label={
              <Flex alignItems="center">
                <Brightness1
                  size={16}
                  fill={colors.success}
                  css={{ marginRight: 8 }}
                />
                {t('tooHigh')}
              </Flex>
            }
            control={
              <Checkbox
                color="primary"
                checked={appraisalPerceptions.has('too_high')}
                onChange={() => toggle('too_high')}
              />
            }
          />
        </Box>
        <Box px={3}>
          <FormControlLabel
            label={'👍 ' + t('justRight')}
            control={
              <Checkbox
                color="primary"
                checked={appraisalPerceptions.has('just_right')}
                onChange={() => toggle('just_right')}
              />
            }
          />
        </Box>
        <Box px={3}>
          <FormControlLabel
            label={
              <Flex alignItems="center">
                <RoundWarning
                  size={16}
                  fill={colors.error}
                  css={{ marginRight: 8 }}
                />
                {t('tooLow')}
              </Flex>
            }
            control={
              <Checkbox
                color="primary"
                checked={appraisalPerceptions.has('too_low')}
                onChange={() => toggle('too_low')}
              />
            }
          />
        </Box>
        <Box px={3}>
          <FormControlLabel
            label={'❓ ' + t('undefined')}
            control={
              <Checkbox
                color="primary"
                checked={appraisalPerceptions.has('undefined')}
                onChange={() => toggle('undefined')}
              />
            }
          />
        </Box>
      </Box>
    </Filter>
  );
};

const useRequalificationParams = makeUrlSearchParamsHook(requalificationParams);

const RequalificationFilters = ({ isAdmin }: { isAdmin: boolean }) => {
  const [params, setParams] = useRequalificationParams();
  const { t } = useLocale();
  const { useRequalifiedIfAvailable, mostRecent } = params;
  const createdAt = useRequalifiedIfAvailable == null && mostRecent == null;
  const requalifiedAt = mostRecent == null && useRequalifiedIfAvailable != null;
  const { text } = useTheme();

  React.useEffect(() => {
    if (!isAdmin) {
      // if not admin force date filter to mostRecent
      setParams({ mostRecent: true, useRequalifiedIfAvailable: null });
    }
  }, [isAdmin, setParams]);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Radio
        color="primary"
        checked={useRequalifiedIfAvailable == null && mostRecent != null}
        onChange={() => {
          setParams({
            mostRecent: true,
            useRequalifiedIfAvailable: null,
          });
        }}
      >
        <div css={text.body2}>{t('mostRecentDate')}</div>
      </Radio>
      <Radio
        color="primary"
        checked={createdAt}
        onChange={() => {
          setParams({
            mostRecent: null,
            useRequalifiedIfAvailable: null,
          });
        }}
      >
        <div css={text.body2}>{t('createdDate')}</div>
      </Radio>
      <Radio
        color="primary"
        checked={requalifiedAt}
        onChange={() => {
          setParams({
            useRequalifiedIfAvailable: true,
            mostRecent: null,
          });
        }}
      >
        <div css={text.body2}>{t('requalificationDate')}</div>
      </Radio>
    </>
  );
};

const LivingSurfaceContent = ({
  value,
  onChange,
}: {
  value: LeadsParams;
  onChange: (params: Partial<LeadsParams>) => void;
}) => {
  const min = 1;
  const max = 10_000;
  const [range, setRange] = React.useState({
    from: (value.livingSurface_gte ?? min).toString(),
    to: (value.livingSurface_lte ?? max).toString(),
  });

  return (
    <Box px={2} pb={3}>
      <RangeInput
        min={min}
        max={max}
        step={1}
        valueGte={range.from?.toString() ?? ''}
        valueLte={range.to?.toString() ?? ''}
        onChange={({ valueGte: from, valueLte: to }) => {
          setRange({ from, to });
          onChange({
            livingSurface_gte:
              number_of_string(from) === min ? null : number_of_string(from),
            livingSurface_lte:
              number_of_string(to) === max ? null : number_of_string(to),
          });
        }}
        renderInput={props => (
          <SquareInput
            decimalScale={0}
            onChange={props.onChange}
            value={props.value}
            onBlur={props.onBlur}
          />
        )}
      />
    </Box>
  );
};

const ResidentialSurfaceContent = ({
  value,
  onChange,
}: {
  value: LeadsParams;
  onChange: (params: Partial<LeadsParams>) => void;
}) => {
  const min = 1;
  const max = 50_000;
  const [range, setRange] = React.useState({
    from: (value.residentialSurface_gte ?? min).toString(),
    to: (value.residentialSurface_lte ?? max).toString(),
  });

  return (
    <Box px={2} pb={3}>
      <RangeInput
        min={min}
        max={max}
        step={1}
        valueGte={range.from.toString() ?? ''}
        valueLte={range.to.toString() ?? ''}
        onChange={({ valueGte: from, valueLte: to }) => {
          setRange({ from, to });
          onChange({
            residentialSurface_gte:
              number_of_string(from) === min ? null : number_of_string(from),
            residentialSurface_lte:
              number_of_string(to) === max ? null : number_of_string(to),
          });
        }}
        renderInput={props => (
          <SquareInput
            decimalScale={0}
            onChange={props.onChange}
            value={props.value}
            onBlur={props.onBlur}
          />
        )}
      />
    </Box>
  );
};

const CommercialSurfaceContent = ({
  value,
  onChange,
}: {
  value: LeadsParams;
  onChange: (params: Partial<LeadsParams>) => void;
}) => {
  const min = 1;
  const max = 50_000;
  const [range, setRange] = React.useState({
    from: (value.commercialSurface_gte ?? min).toString(),
    to: (value.commercialSurface_lte ?? max).toString(),
  });

  return (
    <Box px={2} pb={3}>
      <RangeInput
        min={min}
        max={max}
        step={1}
        valueGte={range.from.toString() ?? ''}
        valueLte={range.to.toString() ?? ''}
        onChange={({ valueGte: from, valueLte: to }) => {
          setRange({ from, to });
          onChange({
            commercialSurface_gte:
              number_of_string(from) === min ? null : number_of_string(from),
            commercialSurface_lte:
              number_of_string(to) === max ? null : number_of_string(to),
          });
        }}
        renderInput={props => (
          <SquareInput
            decimalScale={0}
            onChange={props.onChange}
            value={props.value}
            onBlur={props.onBlur}
          />
        )}
      />
    </Box>
  );
};

const LivingSurfaceFilter = () => {
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const { livingSurface_gte, livingSurface_lte } = params;

  const min = 1;
  const max = 10000;

  const getLotsCountLabel = () => {
    if (
      livingSurface_gte != null &&
      livingSurface_lte != null &&
      livingSurface_gte === livingSurface_lte
    ) {
      return `${t('livingSurface')} ${livingSurface_gte.toLocaleString(
        locale,
      )} m²`;
    }
    if (livingSurface_gte != null && livingSurface_lte != null) {
      return t('livingSurfaceBetween', {
        over: livingSurface_gte.toLocaleString(locale),
        under: livingSurface_lte.toLocaleString(locale),
      });
    }
    if (livingSurface_lte != null) {
      if (livingSurface_lte === min) {
        return `${t('livingSurface')} ${livingSurface_lte.toLocaleString(
          locale,
        )} m²`;
      }
      return t('livingSurfaceUnder', {
        under: livingSurface_lte.toLocaleString(locale),
      });
    }
    if (livingSurface_gte != null) {
      if (livingSurface_gte === max) {
        return `${t('livingSurface')} ${livingSurface_gte.toLocaleString(
          locale,
        )} m²`;
      }
      return t('livingSurfaceOver', {
        over: livingSurface_gte.toLocaleString(locale),
      });
    }
    return t('livingSurface');
  };

  const onChangeDebounced = useDebouncedHandler(500, setParams);

  return (
    <Filter
      empty={livingSurface_lte == null && livingSurface_gte == null}
      onReset={() =>
        setParams({
          livingSurface_gte: null,
          livingSurface_lte: null,
        })
      }
      label={getLotsCountLabel()}
      dialogTitle={t('livingSurface')}
    >
      <Box css={{ maxWidth: 280 }}>
        <LivingSurfaceContent value={params} onChange={onChangeDebounced} />
      </Box>
    </Filter>
  );
};

const PredictedListingDateFilter = () => {
  const { t, dateLocale } = useLocale();
  const { text } = useTheme();
  const [params, setParams] = useLeadsParams();
  const { predictedListingStartDate, predictedListingEndDate } = params;

  const dateRange = {
    start: predictedListingStartDate,
    end: predictedListingEndDate,
  };
  const setDateRange = ({
    start,
    end,
  }: {
    start: null | Date;
    end: null | Date;
  }) => {
    const startDate = start != null ? startOfMonth(start) : null;
    const endDate = end != null ? endOfMonth(end) : null;
    setParams({
      predictedListingStartDate: startDate,
      predictedListingEndDate: endDate,
    });
  };

  const formatRange = (
    {
      start,
      end,
    }: {
      start: null | Date;
      end: null | Date;
    },
    { locale }: { locale: DateFnsLocale },
  ) => {
    if (start != null && end == null) {
      const startMonth = format(start, 'LLLL', { locale });
      const startYear = format(start, 'yyyy', { locale });
      return [t('From'), ' ', startMonth, ' ', startYear].join('');
    }

    if (start == null && end != null) {
      const endDate = format(end, `LLLL yyyy`, { locale });
      return [t('to'), ' ', endDate].join('');
    }

    if (start && end) {
      const startMonth = format(start, 'LLLL', { locale });
      const startYear = format(start, 'yyyy', { locale });
      const endDate = format(end, `LLLL yyyy`, { locale });
      if (isSameMonth(start, end)) {
        return [endDate].join('');
      }
      if (isSameYear(start, end)) {
        return [startMonth, ' - ', endDate].join('');
      }
      return [startMonth, ' ', startYear, ' - ', endDate].join('');
    }

    return t('Predicted listing date');
  };

  return (
    <Filter
      label={formatRange(dateRange, { locale: dateLocale })}
      dialogTitle={t('Predicted listing date')}
      empty={dateRange.start == null && dateRange.end == null}
      onReset={() => {
        setDateRange({
          start: null,
          end: null,
        });
      }}
    >
      <Flex
        css={{
          '.MuiPickersMonthSelection-container': {
            width: '200px',
          },
          '.MuiPickersYearSelection-container': {
            width: '200px',
          },
          '.MuiPickersStaticWrapper-staticWrapperRoot': {
            width: '200px',
            minWidth: 'auto',
          },
          '.MuiPickersToolbarText-toolbarTxt': {
            fontSize: '1.5rem',
          },
        }}
      >
        <Box p={3}>
          <Box pb={1} css={text.body1}>
            {t('startDate')}
          </Box>
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            variant="static"
            onChange={date => {
              setDateRange({
                start: date,
                end: predictedListingEndDate,
              });
            }}
            value={predictedListingStartDate}
            maxDate={predictedListingEndDate ?? new Date('2100-01-01')}
          />
        </Box>
        <Box p={3}>
          <Box pb={1} css={text.body1}>
            {t('endDate')}
          </Box>
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            variant="static"
            onChange={date =>
              setDateRange({
                start: predictedListingStartDate,
                end: date,
              })
            }
            value={predictedListingEndDate}
            minDate={predictedListingStartDate ?? new Date('1900-01-01')}
          />
        </Box>
      </Flex>
    </Filter>
  );
};

const MandateProbabilityFilter = ({
  root,
}: {
  root: LeadsFiltersQuery['response'];
}) => {
  const types = root.mandateProbabilityTypes ?? [];
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const mandateProbability_in = params.mandateProbability_in ?? [];
  const makeToggle = (name: number | null) => (value: boolean) => {
    setParams({
      mandateProbability_in:
        value && name != null
          ? [...mandateProbability_in, name]
          : mandateProbability_in.filter(d => d !== name),
    });
  };

  return (
    <Filter
      dialogTitle={t('Mandate probability')}
      label={
        mandateProbability_in.length === 0
          ? t('Mandate probability')
          : types
              .flatMap(s =>
                s != null && mandateProbability_in.includes(Number(s.name))
                  ? [s.label]
                  : [],
              )
              .join(', ')
      }
      empty={mandateProbability_in.length === 0}
      onReset={() => setParams({ mandateProbability_in: null })}
    >
      {types.map(
        mandateProbability =>
          mandateProbability && (
            <Box px={3} key={mandateProbability.name}>
              <MyCheckbox
                color="primary"
                checked={mandateProbability_in.includes(
                  Number(mandateProbability.name),
                )}
                onChange={makeToggle(number_of_string(mandateProbability.name))}
              >
                {mandateProbability.label}
              </MyCheckbox>
            </Box>
          ),
      )}
    </Filter>
  );
};

const TagsFilter = ({ root }: { root: LeadsFiltersQuery['response'] }) => {
  const types = root.tagsTypes ?? [];
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const tags_in = params.tags_in ?? [];
  const makeToggle = (name: string | null) => (value: boolean) => {
    setParams({
      tags_in:
        value && name != null
          ? [...tags_in, name]
          : tags_in.filter(d => d !== name),
    });
  };

  return (
    <Filter
      dialogTitle={t('Tags')}
      label={
        tags_in.length === 0
          ? t('Tags')
          : types
              .flatMap(s =>
                s != null && tags_in.includes(s.name) ? [s.label] : [],
              )
              .join(', ')
      }
      empty={tags_in.length === 0}
      onReset={() => setParams({ tags_in: null })}
    >
      {types.map(
        tags =>
          tags && (
            <Box px={3} key={tags.name}>
              <MyCheckbox
                color="primary"
                checked={tags_in.includes(tags.name)}
                onChange={makeToggle(tags.name)}
              >
                {tags.label}
              </MyCheckbox>
            </Box>
          ),
      )}
    </Filter>
  );
};

const ResidentialSurfaceFilter = () => {
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const { residentialSurface_gte, residentialSurface_lte } = params;

  const min = 1;
  const max = 50_000;

  const getLotsCountLabel = () => {
    if (
      residentialSurface_gte != null &&
      residentialSurface_lte != null &&
      residentialSurface_gte === residentialSurface_lte
    ) {
      return `${t(
        'residentialSurface',
      )} ${residentialSurface_gte.toLocaleString(locale)} m²`;
    }
    if (residentialSurface_gte != null && residentialSurface_lte != null) {
      return t('residentialSurfaceBetween', {
        over: residentialSurface_gte.toLocaleString(locale),
        under: residentialSurface_lte.toLocaleString(locale),
      });
    }
    if (residentialSurface_lte != null) {
      if (residentialSurface_lte === min) {
        return `${t(
          'residentialSurface',
        )} ${residentialSurface_lte.toLocaleString(locale)} m²`;
      }
      return t('residentialSurfaceUnder', {
        under: residentialSurface_lte.toLocaleString(locale),
      });
    }
    if (residentialSurface_gte != null) {
      if (residentialSurface_gte === max) {
        return `${t(
          'residentialSurface',
        )} ${residentialSurface_gte.toLocaleString(locale)} m²`;
      }
      return t('residentialSurfaceOver', {
        over: residentialSurface_gte.toLocaleString(locale),
      });
    }
    return t('residentialSurface');
  };

  const onChangeDebounced = useDebouncedHandler(500, setParams);

  return (
    <Filter
      empty={residentialSurface_lte == null && residentialSurface_gte == null}
      onReset={() =>
        setParams({
          residentialSurface_gte: null,
          residentialSurface_lte: null,
        })
      }
      label={getLotsCountLabel()}
      dialogTitle={t('residentialSurface')}
    >
      <Box css={{ maxWidth: 280 }}>
        <ResidentialSurfaceContent
          value={params}
          onChange={onChangeDebounced}
        />
      </Box>
    </Filter>
  );
};

const CommercialSurfaceFilter = () => {
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const { commercialSurface_gte, commercialSurface_lte } = params;

  const min = 1;
  const max = 50_000;

  const getLotsCountLabel = () => {
    if (
      commercialSurface_gte != null &&
      commercialSurface_lte != null &&
      commercialSurface_gte === commercialSurface_lte
    ) {
      return `${t('commercialSurface')} ${commercialSurface_gte.toLocaleString(
        locale,
      )} m²`;
    }
    if (commercialSurface_gte != null && commercialSurface_lte != null) {
      return t('commercialSurfaceBetween', {
        over: commercialSurface_gte.toLocaleString(locale),
        under: commercialSurface_lte.toLocaleString(locale),
      });
    }
    if (commercialSurface_lte != null) {
      if (commercialSurface_lte === min) {
        return `${t(
          'commercialSurface',
        )} ${commercialSurface_lte.toLocaleString(locale)} m²`;
      }
      return t('commercialSurfaceUnder', {
        under: commercialSurface_lte.toLocaleString(locale),
      });
    }
    if (commercialSurface_gte != null) {
      if (commercialSurface_gte === max) {
        return `${t(
          'commercialSurface',
        )} ${commercialSurface_gte.toLocaleString(locale)} m²`;
      }
      return t('commercialSurfaceOver', {
        over: commercialSurface_gte.toLocaleString(locale),
      });
    }
    return t('commercialSurface');
  };

  const onChangeDebounced = useDebouncedHandler(500, setParams);

  return (
    <Filter
      empty={commercialSurface_lte == null && commercialSurface_gte == null}
      onReset={() =>
        setParams({
          commercialSurface_gte: null,
          commercialSurface_lte: null,
        })
      }
      label={getLotsCountLabel()}
      dialogTitle={t('commercialSurface')}
    >
      <Box css={{ maxWidth: 280 }}>
        <CommercialSurfaceContent value={params} onChange={onChangeDebounced} />
      </Box>
    </Filter>
  );
};

const AppraisalValueContent = ({
  value,
  onChange,
}: {
  value: LeadsParams;
  onChange: (params: Partial<LeadsParams>) => void;
}) => {
  const min = 0;
  const max = 500_000_000;
  const [range, setRange] = React.useState({
    from: (value.appraisalValue_gte ?? min).toString(),
    to: (value.appraisalValue_lte ?? max).toString(),
  });

  return (
    <Box px={2} pb={3} css={{ maxWidth: 400 }}>
      <RangeInput
        min={min}
        max={max}
        step={10_000}
        valueGte={range.from?.toString() ?? ''}
        valueLte={range.to?.toString() ?? ''}
        onChange={({ valueGte: from, valueLte: to }) => {
          setRange({ from, to });
          onChange({
            appraisalValue_gte:
              number_of_string(from) === min ? null : number_of_string(from),
            appraisalValue_lte:
              number_of_string(to) === max ? null : number_of_string(to),
          });
        }}
        renderInput={props => (
          <PriceInput
            onChange={props.onChange}
            value={props.value}
            onBlur={props.onBlur}
            decimalScale={0}
          />
        )}
      />
    </Box>
  );
};

const AppraisalValueFilter = () => {
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const { appraisalValue_gte, appraisalValue_lte } = params;

  const min = 0;
  const max = 500_000_000;

  const getLabel = () => {
    if (
      appraisalValue_gte != null &&
      appraisalValue_lte != null &&
      appraisalValue_gte === appraisalValue_lte
    ) {
      return `${t('purchaseValue')} ${appraisalValue_gte.toLocaleString(
        locale,
      )}`;
    }
    if (appraisalValue_gte != null && appraisalValue_lte != null) {
      return t('purchaseValueBetween', {
        over: appraisalValue_gte.toLocaleString(locale),
        under: appraisalValue_lte.toLocaleString(locale),
      });
    }
    if (appraisalValue_lte != null) {
      if (appraisalValue_lte === min) {
        return `${t('purchaseValue')} ${appraisalValue_lte.toLocaleString(
          locale,
        )}`;
      }
      return t('purchaseValueUnder', {
        under: appraisalValue_lte.toLocaleString(locale),
      });
    }
    if (appraisalValue_gte != null) {
      if (appraisalValue_gte === max) {
        return `${t('purchaseValue')} ${appraisalValue_gte.toLocaleString(
          locale,
        )}`;
      }
      return t('purchaseValueOver', {
        over: appraisalValue_gte.toLocaleString(locale),
      });
    }
    return t('purchaseValue');
  };

  const onChangeDebounced = useDebouncedHandler(500, setParams);

  return (
    <Filter
      empty={appraisalValue_lte == null && appraisalValue_gte == null}
      onReset={() =>
        setParams({
          appraisalValue_gte: null,
          appraisalValue_lte: null,
        })
      }
      label={getLabel()}
      dialogTitle={t('purchaseValue')}
    >
      <Box css={{ maxWidth: 400 }}>
        <AppraisalValueContent value={params} onChange={onChangeDebounced} />
      </Box>
    </Filter>
  );
};

const LandSurfaceContent = ({
  value,
  onChange,
}: {
  value: LeadsParams;
  onChange: (params: Partial<LeadsParams>) => void;
}) => {
  const min = 1;
  const max = 500_000;
  const [range, setRange] = React.useState({
    from: (value.landSurface_gte ?? min).toString(),
    to: (value.landSurface_lte ?? max).toString(),
  });

  return (
    <Box px={2} pb={3}>
      <RangeInput
        min={min}
        max={max}
        step={1}
        valueGte={range.from?.toString() ?? ''}
        valueLte={range.to?.toString() ?? ''}
        onChange={({ valueGte: from, valueLte: to }) => {
          setRange({ from, to });
          onChange({
            landSurface_gte:
              number_of_string(from) === min ? null : number_of_string(from),
            landSurface_lte:
              number_of_string(to) === max ? null : number_of_string(to),
          });
        }}
        renderInput={props => (
          <SquareInput
            decimalScale={0}
            onChange={props.onChange}
            value={props.value}
            onBlur={props.onBlur}
          />
        )}
      />
    </Box>
  );
};

const LandSurfaceFilter = () => {
  const { t, locale } = useLocale();
  const [params, setParams] = useLeadsParams();
  const { landSurface_gte, landSurface_lte } = params;

  const min = 1;
  const max = 500_000;

  const getLabel = () => {
    if (
      landSurface_gte != null &&
      landSurface_lte != null &&
      landSurface_gte === landSurface_lte
    ) {
      return `${t('landSurface')} ${landSurface_gte.toLocaleString(locale)} m²`;
    }
    if (landSurface_gte != null && landSurface_lte != null) {
      return t('landSurfaceBetween', {
        over: landSurface_gte.toLocaleString(locale),
        under: landSurface_lte.toLocaleString(locale),
      });
    }
    if (landSurface_lte != null) {
      if (landSurface_lte === min) {
        return `${t('landSurface')} ${landSurface_lte.toLocaleString(
          locale,
        )} m²`;
      }
      return t('landSurfaceUnder', {
        under: landSurface_lte.toLocaleString(locale),
      });
    }
    if (landSurface_gte != null) {
      if (landSurface_gte === max) {
        return `${t('landSurface')} ${landSurface_gte.toLocaleString(
          locale,
        )} m²`;
      }
      return t('landSurfaceOver', {
        over: landSurface_gte.toLocaleString(locale),
      });
    }
    return t('landSurface');
  };

  const onChangeDebounced = useDebouncedHandler(500, setParams);

  return (
    <Filter
      empty={landSurface_lte == null && landSurface_gte == null}
      onReset={() =>
        setParams({
          landSurface_gte: null,
          landSurface_lte: null,
        })
      }
      label={getLabel()}
      dialogTitle={t('landSurface')}
    >
      <Box css={{ maxWidth: 300 }}>
        <LandSurfaceContent value={params} onChange={onChangeDebounced} />
      </Box>
    </Filter>
  );
};

const NextActivityFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();

  const selected = new Set(params.nextActivity?.map(next_activity_of_string));

  const nodes = [
    { id: 'no_activity', label: t('noActivity') },
    { id: 'assignment', label: t('assignment') },
    { id: 'call', label: t('call') },
    { id: 'visit', label: t('visit') },
    { id: 'task', label: t('task') },
  ] as const;

  return (
    <Filter
      label={getLabel(nodes, selected, t('nextActivity'))}
      dialogTitle={t('nextActivity')}
      empty={selected.size === 0}
      onReset={() => setParams({ nextActivity: null })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={selected}
        onClick={value => {
          const arr = Array.from(selected);
          setParams({
            nextActivity:
              value === 'no_activity'
                ? arr.includes(value)
                  ? []
                  : ['no_activity']
                : arr.includes(value)
                ? arr.filter(x => x !== value)
                : [value, ...arr.filter(x => x !== 'no_activity')],
          });
        }}
      />
    </Filter>
  );
};

const BuyingStageFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const mortgageBuyingStatus_in = new Set(params.mortgageBuyingStatus_in ?? []);
  const nodes = [
    { id: 'searching', label: t('searching') },
    { id: 'visited', label: t('visited') },
    { id: 'offer', label: t('madeOffer') },
  ];
  const toggle = (id: string) =>
    setParams({
      mortgageBuyingStatus_in: toggleFilterItem(mortgageBuyingStatus_in, id),
    });

  return (
    <Filter
      label={getLabel(nodes, mortgageBuyingStatus_in, t('buyingStage'))}
      dialogTitle={t('buyingStage')}
      empty={mortgageBuyingStatus_in.size === 0}
      onReset={() => setParams({ mortgageBuyingStatus_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={mortgageBuyingStatus_in}
        onClick={toggle}
      />
    </Filter>
  );
};

const MortgageApplicationFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const mortgageStatus_in = new Set(params.mortgageStatus_in ?? []);
  const nodes = [
    { id: 'not_started', label: t('noApplication') },
    { id: 'working_with_bank', label: t('workingWithBank') },
    { id: 'working_with_independent', label: t('workingWithBroker') },
  ];

  const toggle = (id: string) =>
    setParams({ mortgageStatus_in: toggleFilterItem(mortgageStatus_in, id) });

  return (
    <Filter
      label={getLabel(nodes, mortgageStatus_in, t('mortgageApplication'))}
      dialogTitle={t('mortgageApplication')}
      empty={mortgageStatus_in.size === 0}
      onReset={() => setParams({ mortgageStatus_in: [] })}
    >
      <NormalFilterContent
        nodes={nodes}
        selectedIds={mortgageStatus_in}
        onClick={toggle}
      />
    </Filter>
  );
};

export const MortgageOfferFilter = () => {
  const { t } = useLocale();
  const [params, setParams] = useLeadsParams();
  const receivedMortgageOffer_in = new Set(
    params.receivedMortgageOffer_in ?? [],
  );

  const labelMapper = {
    true: t('hasFinancingOffer'),
    false: t('noFinancingOffer'),
  } as const;

  const label =
    receivedMortgageOffer_in.size === 0
      ? t('mortgageOffer')
      : Array.from(receivedMortgageOffer_in)
          .map(received => labelMapper[received ? 'true' : 'false'])
          .join(', ');

  const toggle = (item: boolean) => {
    const newSet = new Set(receivedMortgageOffer_in);
    if (newSet.has(item)) {
      newSet.delete(item);
    } else {
      newSet.add(item);
    }

    setParams({
      receivedMortgageOffer_in: Array.from(newSet),
    });
  };

  return (
    <Filter
      label={label}
      dialogTitle={t('mortgageOffer')}
      empty={receivedMortgageOffer_in.size === 0}
      onReset={() => setParams({ receivedMortgageOffer_in: [] })}
    >
      <Box px={3} py={2}>
        <MyCheckbox
          color="primary"
          checked={receivedMortgageOffer_in.has(true)}
          onChange={() => toggle(true)}
        >
          {t('hasFinancingOffer')}
        </MyCheckbox>
        <MyCheckbox
          color="primary"
          checked={receivedMortgageOffer_in.has(false)}
          onChange={() => toggle(false)}
        >
          {t('noFinancingOffer')}
        </MyCheckbox>
      </Box>
    </Filter>
  );
};

const StageQuery = ({ pipelineId }: { pipelineId: string }) => {
  const data = useLazyLoadQuery<LeadsFiltersStagesQuery>(
    graphql`
      query LeadsFiltersStagesQuery($pipelineId: ID!) {
        stages(pipelineId: $pipelineId) {
          id
          label
        }
      }
    `,
    { pipelineId },
  );

  return (
    <Box px={1}>
      <StageFilter root={data} />
    </Box>
  );
};

type LeadsFiltersProps = {
  hidePipelineFilter: boolean;
};

export const LeadsFilters = (props: LeadsFiltersProps) => {
  const { t } = useLocale();
  const [menu, setMenu] = React.useState(false);
  const menuRef = React.useRef(null);
  const { colors } = useTheme();
  const responsive = useResponsive();
  const [params, setParams] = useLeadsParams();
  const { search } = useLocation();

  const data = useLazyLoadQuery<LeadsFiltersQuery>(
    graphql`
      query LeadsFiltersQuery {
        tenantSettings {
          defaultPipeline {
            id
          }
        }
        me {
          isAdmin
        }
        leadLostTypes: dictionaries(type: "lead_lost_types") {
          id
          label
        }
        mandateProbabilityTypes: dictionaries(
          type: "mandate_probability_types"
        ) {
          id
          label
          name
        }
        tagsTypes: dictionaries(type: "tags_types") {
          id
          label
          name
        }
      }
    `,
    {},
  );
  const pipelineId =
    params.pipelineId_eq ?? data.tenantSettings?.defaultPipeline?.id;
  const isAdmin = data.me?.isAdmin ?? false;

  return (
    <FiltersMenu
      actions={
        <>
          {responsive([
            <>
              <IconButton
                ref={menuRef}
                size="small"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setMenu(v => !v);
                }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                referenceRef={menuRef}
                open={menu}
                onClose={() => setMenu(false)}
              >
                <MenuItem
                  disabled={params.tab === 'list'}
                  onClick={() => setParams({ tab: 'list' })}
                >
                  {t('listView')}
                </MenuItem>
                <MenuItem
                  disabled={params.tab === 'kanban'}
                  onClick={() => setParams({ tab: 'kanban' })}
                >
                  {t('kanbanView')}
                </MenuItem>
                <MenuItem
                  disabled={params.tab === 'map'}
                  onClick={() => setParams({ tab: 'map' })}
                >
                  {t('mapView')}
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={{
                    pathname: `/leads/new`,
                    search,
                  }}
                >
                  {t('New')}
                </MenuItem>
              </Menu>
            </>,
            null,
          ])}
          {responsive([
            null,
            <>
              <IconButton
                color="inherit"
                size="small"
                title={t('listView')}
                onClick={() => setParams({ tab: 'list' })}
              >
                <ListOutline
                  fill={
                    params.tab === 'list' ? colors.primaryMain : colors.black
                  }
                />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                title={t('kanbanView')}
                onClick={() => setParams({ tab: 'kanban' })}
              >
                <KanbanOutline
                  fill={
                    params.tab === 'kanban' ? colors.primaryMain : colors.black
                  }
                />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                title={t('mapView')}
                onClick={() => setParams({ tab: 'map' })}
              >
                <LocationMapOutline
                  fill={
                    params.tab === 'map' ? colors.primaryMain : colors.black
                  }
                />
              </IconButton>
              <Button
                component={Link}
                to={{
                  pathname: `/leads/new`,
                  search,
                }}
                variant="contained"
              >
                {t('New')}
              </Button>
            </>,
          ])}
        </>
      }
    >
      <Box px={1}>
        <DateRangeFilter top={<RequalificationFilters isAdmin={isAdmin} />} />
      </Box>
      <Box px={1}>
        <LeadSourceFilter />
      </Box>
      <Box px={1}>
        <StatusFilter />
      </Box>
      <Box px={1}>
        <CompletedFilter params={params} setParams={setParams} />
      </Box>
      <Box px={1}>
        <ContactFilter />
      </Box>
      <Box px={1}>
        <RelationshipFilter />
      </Box>
      <Box px={1}>
        <BrokerFilter showNoAgentFilter={true} onlyInCurrentTeams={!isAdmin} />
      </Box>
      <Box px={1}>
        <OrganisationFilter />
      </Box>
      <Box px={1}>
        <PlaceFilter />
      </Box>
      <Box px={1}>
        <AppraisalValueFilter />
      </Box>
      <AppraisalPerceptionFilter />
      <Box px={1}>
        <AppraisalReasonFilter />
      </Box>
      <Box px={1}>
        <SaleHorizonFilter />
      </Box>
      <Box px={1}>
        <AppraisalOpenToOfferFilter />
      </Box>
      <Box px={1}>
        <PropertyTypeFilter />
      </Box>
      <Box px={1}>
        <LivingSurfaceFilter />
      </Box>
      <Box px={1}>
        <LandSurfaceFilter />
      </Box>
      <Box px={1}>
        <AppraisalPropertyOccupiedFilter />
      </Box>
      <Box px={1}>
        <AppraisalPropertyUsageFilter />
      </Box>
      <Box px={1}>
        <NextActivityFilter />
      </Box>
      <Box px={1}>
        <NextActivityOverdueFilter params={params} setParams={setParams} />
      </Box>
      <Box px={1}>
        <BuyingStageFilter />
      </Box>
      {!props.hidePipelineFilter && pipelineId != null && (
        <React.Suspense fallback={null}>
          <StageQuery pipelineId={pipelineId} />
        </React.Suspense>
      )}
      <Box px={1}>
        <MortgageApplicationFilter />
      </Box>
      <Box px={1}>
        <MortgageOfferFilter />
      </Box>
      <Box px={1}>
        <DevelopmentFilter />
      </Box>
      <Box px={1}>
        <BuyHorizonFilter />
      </Box>
      <Box px={1}>
        <PredictedListingDateFilter />
      </Box>
      <Box px={1}>
        <MandateProbabilityFilter root={data} />
      </Box>
      <Box px={1}>
        <TagsFilter root={data} />
      </Box>
      <Box px={1}>
        <ResidentialSurfaceFilter />
      </Box>
      <Box px={1}>
        <CommercialSurfaceFilter />
      </Box>
    </FiltersMenu>
  );
};
