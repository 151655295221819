import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';

type ConfirmationRefreshDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationRefreshDialog = ({
  open,
  onClose,
  onConfirm,
}: ConfirmationRefreshDialogProps) => {
  const { t } = useLocale();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', color: 'warning.main' }}
      >
        <WarningIcon sx={{ mr: 1 }} />
        {t('Refresh Comparable Listings')}
      </DialogTitle>
      <DialogContent>
        <Typography>{t('Refresh Comparable Listings Confirmation')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button color="warning" onClick={onConfirm} variant="contained">
          {t('refresh')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationRefreshDialog;
