import type * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';

import { ProgressButton } from './progress-button';

const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  const { colors } = useTheme();
  return (
    <Box
      p={3}
      css={{
        color: colors.errorText,
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        hyphens: 'auto',
      }}
    >
      {children}
    </Box>
  );
};

type Props = {
  title: React.ReactNode;
  open: boolean;
  okDisabled?: boolean;
  className?: string;
  error?: null | string;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: JSX.Element;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
};

export const ConfirmDialog = ({
  className,
  title,
  children,
  open,
  error,
  isSubmitting = false,
  onClose,
  onSubmit,
  okDisabled = false,
  okText,
  cancelText,
}: Props) => {
  const { t } = useLocale();
  return (
    <Dialog className={className} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      {error != null && (
        <DialogContent>
          <ErrorMessage>{error}</ErrorMessage>{' '}
        </DialogContent>
      )}

      {children}
      <DialogActions>
        <Button disabled={isSubmitting} onClick={onClose}>
          {cancelText ?? t('Cancel')}
        </Button>
        <ProgressButton
          loading={isSubmitting}
          disabled={okDisabled}
          color="primary"
          onClick={onSubmit}
          variant="text"
        >
          {okText ?? t('ok')}
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};
