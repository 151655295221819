/**
 * @generated SignedSource<<14aff3b301809c1bf4e0bd70ff372d1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
import { FragmentRefs } from "relay-runtime";
export type leadQualificationCard_lead$data = {
  readonly id: string;
  readonly lot: {
    readonly id: string;
  } | null;
  readonly mandateProbability: number | null;
  readonly predictedListingDate: string | null;
  readonly stage: {
    readonly id: string;
    readonly label: string;
    readonly pipeline: {
      readonly id: string;
      readonly label: string | null;
    } | null;
    readonly status: LeadStageStatus;
  } | null;
  readonly tags: ReadonlyArray<string> | null;
  readonly " $fragmentType": "leadQualificationCard_lead";
};
export type leadQualificationCard_lead$key = {
  readonly " $data"?: leadQualificationCard_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadQualificationCard_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadQualificationCard_lead",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mandateProbability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "predictedListingDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Lot",
      "kind": "LinkedField",
      "name": "lot",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pipeline",
          "kind": "LinkedField",
          "name": "pipeline",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "d6dd015ef4d187d426a54f46c44b3b09";

export default node;
