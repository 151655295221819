/**
 * @generated SignedSource<<15c2bbf0b459241b9cd23a4c96b3b020>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeadAppraisalReason = "already_for_sale" | "buy_soon" | "buy_this" | "inheritance" | "move_rental" | "not_selling" | "not_set" | "other" | "other_not_owner" | "other_owner" | "other_renting" | "refinance" | "sell" | "separation";
export type LeadBuyHorizon = "not_set" | "over_12_months" | "under_12_months" | "under_3_months";
export type LeadRelationship = "agent" | "buyer" | "heir" | "not_set" | "other" | "owner" | "tenant";
export type LeadSaleHorizon = "already_for_sale" | "from_1_to_2_years" | "from_2_years" | "from_6_to_12_months" | "not_selling" | "not_set" | "under_6_months";
export type LeadsFilters = {
  appraisalNextStep_in?: ReadonlyArray<string | null> | null;
  appraisalOpenToOffer_in?: ReadonlyArray<string | null> | null;
  appraisalPerception_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyOccupied_in?: ReadonlyArray<string | null> | null;
  appraisalPropertyUsage_in?: ReadonlyArray<string | null> | null;
  appraisalReason_in?: ReadonlyArray<LeadAppraisalReason> | null;
  appraisalValue_gte?: number | null;
  appraisalValue_lte?: number | null;
  brokerId_in?: ReadonlyArray<string | null> | null;
  buyHorizon_in?: ReadonlyArray<LeadBuyHorizon> | null;
  claimedBy_eq?: string | null;
  completed_in?: ReadonlyArray<boolean> | null;
  contactId_in?: ReadonlyArray<string | null> | null;
  contacts_assets_gte?: number | null;
  contacts_assets_lte?: number | null;
  contacts_income_gte?: number | null;
  contacts_income_lte?: number | null;
  createdAt_inRange?: DateRange | null;
  createdBy_in?: ReadonlyArray<string | null> | null;
  id_in?: ReadonlyArray<string | null> | null;
  leadLostId_in?: ReadonlyArray<string | null> | null;
  mandateProbability_in?: ReadonlyArray<number | null> | null;
  mortgageBuyingStatus_in?: ReadonlyArray<string | null> | null;
  mortgagePropertyValue_gte?: number | null;
  mortgagePropertyValue_lte?: number | null;
  mortgageStatus_in?: ReadonlyArray<string | null> | null;
  mortgage_type_in?: ReadonlyArray<string | null> | null;
  nextActivity_exists?: boolean | null;
  nextActivity_overdue?: boolean | null;
  nextActivity_type_in?: ReadonlyArray<string> | null;
  organisationId_in?: ReadonlyArray<string | null> | null;
  pipelineId_eq?: string | null;
  potentialDevelopment_eq?: boolean | null;
  predictedListingDate_inRange?: DateRange | null;
  property_commercialSurface_gte?: number | null;
  property_commercialSurface_lte?: number | null;
  property_googleAddress_lat_gte?: number | null;
  property_googleAddress_lat_lte?: number | null;
  property_googleAddress_lng_gte?: number | null;
  property_googleAddress_lng_lte?: number | null;
  property_landSurface_gte?: number | null;
  property_landSurface_lte?: number | null;
  property_livingSurface_gte?: number | null;
  property_livingSurface_lte?: number | null;
  property_placeId_in?: ReadonlyArray<string> | null;
  property_propertyType_name_in?: ReadonlyArray<string | null> | null;
  property_residentialSurface_gte?: number | null;
  property_residentialSurface_lte?: number | null;
  receivedMortgageOffer_in?: ReadonlyArray<boolean | null> | null;
  relationship_in?: ReadonlyArray<LeadRelationship> | null;
  saleHorizon_in?: ReadonlyArray<LeadSaleHorizon> | null;
  sourceId_in?: ReadonlyArray<string | null> | null;
  stageId_in?: ReadonlyArray<string | null> | null;
  stageName_in?: ReadonlyArray<string | null> | null;
  status_in?: ReadonlyArray<string | null> | null;
  tags_in?: ReadonlyArray<string | null> | null;
};
export type DateRange = {
  endDate?: string | null;
  mostRecent?: boolean | null;
  startDate?: string | null;
  useRequalifiedIfAvailable?: boolean | null;
};
export type leadsKanbanItemsQuery$variables = {
  filters: LeadsFilters;
  first: number;
};
export type leadsKanbanItemsQuery$data = {
  readonly leads: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appraisalPerceivedValue: number | null;
        readonly appraisalReason: LeadAppraisalReason;
        readonly broker: {
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly primaryImage: {
            readonly url: string;
          } | null;
        } | null;
        readonly buyHorizon: LeadBuyHorizon;
        readonly contact: {
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly primaryEmail: {
            readonly email: string;
          } | null;
        } | null;
        readonly id: string;
        readonly lot: {
          readonly id: string;
        } | null;
        readonly mandateProbability: number | null;
        readonly nextActivity: {
          readonly __typename: "ActivityAssignment";
          readonly dueAt: string | null;
          readonly overdue: boolean;
          readonly startDate: string | null;
        } | {
          readonly __typename: "ActivityCall";
          readonly dueAt: string | null;
          readonly overdue: boolean;
          readonly startDate: string | null;
        } | {
          readonly __typename: "ActivityTask";
          readonly dueAt: string | null;
          readonly overdue: boolean;
          readonly startDate: string | null;
        } | {
          readonly __typename: "ActivityVisit";
          readonly dueAt: string | null;
          readonly overdue: boolean;
          readonly startDate: string | null;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
        readonly predictedListingDate: string | null;
        readonly property: {
          readonly formattedAddress: string | null;
          readonly latestAppraisal: {
            readonly realadvisor: {
              readonly max: number | null;
              readonly min: number | null;
            } | null;
          } | null;
        } | null;
        readonly relationship: LeadRelationship;
        readonly saleHorizon: LeadSaleHorizon;
        readonly stage: {
          readonly id: string;
          readonly pipeline: {
            readonly id: string;
          } | null;
        } | null;
        readonly userCanViewLeadDetails: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"leadUpdatedTimeAgo_lead">;
      } | null;
    } | null> | null;
  } | null;
};
export type leadsKanbanItemsQuery = {
  response: leadsKanbanItemsQuery$data;
  variables: leadsKanbanItemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relationship",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appraisalReason",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appraisalPerceivedValue",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userCanViewLeadDetails",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleHorizon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyHorizon",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mandateProbability",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "predictedListingDate",
  "storageKey": null
},
v12 = [
  (v3/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadStage",
  "kind": "LinkedField",
  "name": "stage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Pipeline",
      "kind": "LinkedField",
      "name": "pipeline",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedAddress",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "RealadvisorAppraisal",
  "kind": "LinkedField",
  "name": "realadvisor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "max",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "min",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dueAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "startDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "overdue",
    "storageKey": null
  }
],
v22 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityAssignment",
  "abstractKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityCall",
  "abstractKey": null
},
v24 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityVisit",
  "abstractKey": null
},
v25 = {
  "kind": "InlineFragment",
  "selections": (v21/*: any*/),
  "type": "ActivityTask",
  "abstractKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Lot",
  "kind": "LinkedField",
  "name": "lot",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "leadsKanbanItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "leadUpdatedTimeAgo_lead"
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Email",
                        "kind": "LinkedField",
                        "name": "primaryEmail",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "primaryImage",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "property",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appraisal",
                        "kind": "LinkedField",
                        "name": "latestAppraisal",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "nextActivity",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v26/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "leadsKanbanItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LeadConnectionConnection",
        "kind": "LinkedField",
        "name": "leads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Lead",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requalifiedAt",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Email",
                        "kind": "LinkedField",
                        "name": "primaryEmail",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "broker",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "primaryImage",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "property",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appraisal",
                        "kind": "LinkedField",
                        "name": "latestAppraisal",
                        "plural": false,
                        "selections": [
                          (v19/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "nextActivity",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v26/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee75ab24ec77c88d8966aa0e6a46dfeb",
    "id": null,
    "metadata": {},
    "name": "leadsKanbanItemsQuery",
    "operationKind": "query",
    "text": "query leadsKanbanItemsQuery(\n  $first: Int!\n  $filters: LeadsFilters!\n) {\n  leads(first: $first, filters: $filters) {\n    edges {\n      node {\n        id\n        ...leadUpdatedTimeAgo_lead\n        relationship\n        appraisalReason\n        appraisalPerceivedValue\n        userCanViewLeadDetails\n        saleHorizon\n        buyHorizon\n        mandateProbability\n        predictedListingDate\n        stage {\n          id\n          pipeline {\n            id\n          }\n        }\n        contact {\n          firstName\n          lastName\n          primaryEmail {\n            email\n            id\n          }\n          id\n        }\n        broker {\n          firstName\n          lastName\n          primaryImage {\n            url\n            id\n          }\n          id\n        }\n        property {\n          formattedAddress\n          latestAppraisal {\n            realadvisor {\n              max\n              min\n            }\n            id\n          }\n          id\n        }\n        nextActivity {\n          __typename\n          ... on ActivityAssignment {\n            dueAt\n            startDate\n            overdue\n          }\n          ... on ActivityCall {\n            dueAt\n            startDate\n            overdue\n          }\n          ... on ActivityVisit {\n            dueAt\n            startDate\n            overdue\n          }\n          ... on ActivityTask {\n            dueAt\n            startDate\n            overdue\n          }\n          id\n        }\n        lot {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment leadUpdatedTimeAgo_lead on Lead {\n  createdAt\n  requalifiedAt\n}\n"
  }
};
})();

(node as any).hash = "6bb8e9d7c1e4854bf090b217a476c9eb";

export default node;
