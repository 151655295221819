// @flow

import * as React from 'react';

import {
  Button,
  CardActions,
  FormLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, useFormik } from '@realadvisor/form';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useSystem } from 'react-system';

import { Checkbox } from '../controls/checkbox';
import { ScrollToError } from '../controls/scroll-to-error';
import { useLocale } from '../hooks/locale';

import type { propertyImageForm_image$key } from './__generated__/propertyImageForm_image.graphql';
import type { propertyImageFormUpdateMutation } from './__generated__/propertyImageFormUpdateMutation.graphql';
import { PreviewImage } from './preview-image';

type Props = {|
  image: propertyImageForm_image$key,
  onClose: () => void,
|};

export const PropertyImageForm = (props: Props): React.Node => {
  const image = useFragment(
    graphql`
      fragment propertyImageForm_image on PropertyImage {
        id
        title
        description
        size
        crop
        backgroundColor
        displayOnListing
        displayOnBrochure
        isFloorPlan
        landscapeSize
        landscapeCrop
        landscapeBackgroundColor
        image {
          id
          ...previewImage_image
        }
      }
    `,
    props.image,
  );
  const [updatePropertyImage, updating] =
    useMutation<propertyImageFormUpdateMutation>(
      graphql`
        mutation propertyImageFormUpdateMutation(
          $input: UpdatePropertyImageInput!
        ) {
          updatePropertyImage(input: $input) {
            propertyImage {
              ...propertyImageForm_image
            }
          }
        }
      `,
    );

  const { media } = useSystem();
  const { t } = useLocale();
  const form = useFormik({
    initialValues: {
      title: image.title ?? '',
      description: image.description ?? '',
      size: image.size,
      crop: image.crop,
      backgroundColor: image.backgroundColor,
      displayOnListing: image.displayOnListing,
      displayOnBrochure: image.displayOnBrochure,
      isFloorPlan: image.isFloorPlan,
      landscapeSize: image.landscapeSize,
      landscapeCrop: image.landscapeCrop,
      landscapeBackgroundColor: image.landscapeBackgroundColor,
    },
    validate: () => ({}),
    onSubmit: values => {
      const input = {
        id: image.id,
        title: values.title.length === 0 ? null : values.title,
        description:
          values.description.length === 0 ? null : values.description,
        size: values.size,
        crop: values.crop,
        backgroundColor: values.backgroundColor,
        displayOnListing: values.displayOnListing,
        displayOnBrochure: values.displayOnBrochure,
        isFloorPlan: values.isFloorPlan,
        landscapeSize: values.landscapeSize,
        landscapeCrop: values.landscapeCrop,
        landscapeBackgroundColor: values.landscapeBackgroundColor,
      };
      updatePropertyImage({ variables: { input }, onCompleted: props.onClose });
    },
  });

  const GAP = 16;
  const gridStyles = {
    display: 'grid',
    gridTemplateColumns: [`repeat(2, 1fr)`, `repeat(2, 1fr)`, `repeat(3, 1fr)`],
    gap: GAP,
  };
  const grid = media(gridStyles);
  const layoutGrid = media({
    ...gridStyles,
    marginTop: '3rem',
  });
  const fullRowWidth = { gridColumn: '1 / -1' };

  return (
    <Form onSubmit={form.submitForm}>
      <ScrollToError errors={form.errors} />
      <div css={grid}>
        <TextField
          css={fullRowWidth}
          variant="filled"
          label={t('title')}
          value={form.values.title}
          onChange={event => form.setValues({ title: event.target.value })}
        />

        <TextField
          css={fullRowWidth}
          variant="filled"
          label={t('description')}
          value={form.values.description}
          onChange={event =>
            form.setValues({ description: event.target.value })
          }
        />
        <div
          css={{
            display: 'grid',
            gap: GAP,
            alignContent: 'start',
          }}
        >
          <div>
            <FormLabel>{t('layoutOptions')}</FormLabel>
          </div>
          <TextField
            variant="filled"
            select={true}
            label={t('size')}
            value={form.values.size}
            onChange={event =>
              form.setValues({ size: (event.target.value: any) })
            }
          >
            <MenuItem value="quarter_page">{t('quarterPage')}</MenuItem>
            <MenuItem value="half_page">{t('halfPage')}</MenuItem>
            <MenuItem value="full_page">{t('fullPage')}</MenuItem>
          </TextField>

          <TextField
            variant="filled"
            select={true}
            label={t('crop')}
            value={form.values.crop}
            onChange={event =>
              form.setValues({ crop: (event.target.value: any) })
            }
          >
            <MenuItem value="fit">{t('fit')}</MenuItem>
            <MenuItem value="fill">{t('fill')}</MenuItem>
          </TextField>

          {form.values.crop === 'fit' && (
            <TextField
              variant="filled"
              select={true}
              label={t('backgroundColor')}
              value={form.values.backgroundColor}
              onChange={event =>
                form.setValues({ backgroundColor: (event.target.value: any) })
              }
            >
              <MenuItem value="black">{t('black')}</MenuItem>
              <MenuItem value="white">{t('white')}</MenuItem>
            </TextField>
          )}
        </div>
        <div>
          <div css={{ marginBottom: GAP }}>
            <FormLabel>{t('layoutPreview')}</FormLabel>
          </div>
          <PreviewImage
            image={image.image}
            size={form.values.size}
            backgroundColor={form.values.backgroundColor}
            crop={form.values.crop}
            brochure="portrait"
          />
        </div>
        <div>
          <FormLabel>{t('displayOptions')}</FormLabel>
          <Checkbox
            color="primary"
            checked={form.values.displayOnListing}
            onChange={checked => form.setValues({ displayOnListing: checked })}
          >
            {t('displayOnListing')}
          </Checkbox>
          <Checkbox
            color="primary"
            checked={form.values.displayOnBrochure}
            onChange={checked => form.setValues({ displayOnBrochure: checked })}
          >
            {t('displayOnBrochure')}
          </Checkbox>
          <Checkbox
            color="primary"
            checked={form.values.isFloorPlan}
            onChange={checked => form.setValues({ isFloorPlan: checked })}
          >
            {t('isFloorPlan')}
          </Checkbox>
        </div>
      </div>
      <div css={layoutGrid}>
        <div
          css={{
            display: 'grid',
            gap: GAP,
            alignContent: 'start',
          }}
        >
          <div>
            <FormLabel>{t('landscapeLayoutOptions')}</FormLabel>
          </div>
          <TextField
            variant="filled"
            select={true}
            label={t('size')}
            value={form.values.landscapeSize}
            onChange={event =>
              form.setValues({ landscapeSize: (event.target.value: any) })
            }
          >
            <MenuItem value="quarter_page">{t('quarterPage')}</MenuItem>
            <MenuItem value="half_page">{t('halfPage')}</MenuItem>
            <MenuItem value="full_page">{t('fullPage')}</MenuItem>
          </TextField>

          <TextField
            variant="filled"
            select={true}
            label={t('crop')}
            value={form.values.landscapeCrop}
            onChange={event =>
              form.setValues({ landscapeCrop: (event.target.value: any) })
            }
          >
            <MenuItem value="fit">{t('fit')}</MenuItem>
            <MenuItem value="fill">{t('fill')}</MenuItem>
          </TextField>
          {form.values.landscapeCrop === 'fit' && (
            <TextField
              variant="filled"
              select={true}
              label={t('backgroundColor')}
              value={form.values.landscapeBackgroundColor}
              onChange={event =>
                form.setValues({
                  landscapeBackgroundColor: (event.target.value: any),
                })
              }
            >
              <MenuItem value="black">{t('black')}</MenuItem>
              <MenuItem value="white">{t('white')}</MenuItem>
            </TextField>
          )}
        </div>
        <div>
          <div css={{ marginBottom: GAP }}>
            <FormLabel>{t('layoutPreview')}</FormLabel>
          </div>
          <PreviewImage
            image={image.image}
            size={form.values.landscapeSize}
            backgroundColor={form.values.landscapeBackgroundColor}
            crop={form.values.landscapeCrop}
            brochure="landscape"
          />
        </div>
      </div>

      <CardActions>
        <Button disabled={!form.changed} onClick={form.resetForm}>
          {t('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!form.changed || updating}
          onClick={form.submitForm}
        >
          {t('save')}
        </Button>
      </CardActions>
    </Form>
  );
};
