import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';

import { useMutation } from '@apollo/client';
import {
  DndContext,
  type DragEndEvent,
  DragOverlay,
  type useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AdsClickOutlined from '@mui/icons-material/AdsClickOutlined';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, useTheme } from '@mui/material';
import { createPortal } from 'react-dom';

import { useLocale } from '../../../../src/hooks/locale';
import {
  STEP_LISTINGS_COMPARE_FRAGMENT,
  UPDATE_CMA_REPORTS_COMPARABLES,
} from '../cmaReportsQueries';

import ListingCardDraggable from './ListingCardDraggable';
import { type ComparableListing } from './StepListingsCompare';

type ComparableListingsSectionProps = {
  cmaReportId: string;
  listings: ComparableListing[];
  runningAutomaticAlgo: boolean;
  sensors: ReturnType<typeof useSensors>;
  setListings: Dispatch<SetStateAction<ComparableListing[]>>;
  setManualSelectionOpen: (open: boolean) => void;
  setRefreshOpen: (open: boolean) => void;
  generateComparable: () => void;
};

const reorder = (
  list: ComparableListing[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ComparableListingsSection = ({
  cmaReportId,
  listings,
  runningAutomaticAlgo,
  sensors,
  setListings,
  setManualSelectionOpen,
  setRefreshOpen,
  generateComparable,
}: ComparableListingsSectionProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const [activeNode, setActiveNode] = useState<null | ComparableListing>(null);

  const [updateCmaReportsComparable] = useMutation(
    UPDATE_CMA_REPORTS_COMPARABLES,
  );

  const onDragEndDnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over) {
        return;
      }
      const sourceIndex = listings.findIndex(
        listing => listing.id === active.id,
      );
      const destinationIndex = listings.findIndex(
        listing => listing.id === over.id,
      );
      if (sourceIndex === destinationIndex) {
        return;
      }

      const reorderedListings = reorder(
        listings,
        sourceIndex,
        destinationIndex,
      );

      setListings(reorderedListings);
      updateCmaReportsComparable({
        variables: {
          cma_report_id: cmaReportId,
          listing_id: active.id,
          order_nr: destinationIndex,
        },
        update: (cache, { data: newData }) => {
          const id = cache.identify({
            __typename: 'cma_reports',
            id: cmaReportId,
          });
          const updatedListings =
            newData?.update_cma_reports_comparables?.returning[0]?.cma_report
              ?.comparable_listings ?? [];

          const existingData = cache.readFragment({
            fragment: STEP_LISTINGS_COMPARE_FRAGMENT,
            fragmentName: 'StepListingsCompare',
            id,
          });

          const mergedData = {
            ...existingData,
            updated_at: newData?.update_cma_reports_by_pk?.updated_at,
            comparable_listings: updatedListings,
          };

          cache.writeFragment({
            fragment: STEP_LISTINGS_COMPARE_FRAGMENT,
            fragmentName: 'StepListingsCompare',
            id,
            data: mergedData,
          });
        },
        onCompleted: () => setActiveNode(null),
      });
    },
    [cmaReportId, listings, setListings, updateCmaReportsComparable],
  );

  return (
    <Stack gap={1} p={2} flexGrow={1} height={0} overflow="auto">
      <div>
        <div style={{ ...theme.typography.body2 }}>
          {t('choosePropertiesToCompareSubtitle')}
        </div>
      </div>
      <Stack gap={2} direction={'row'}>
        <Button
          startIcon={<AdsClickOutlined />}
          onClick={() => setManualSelectionOpen(true)}
          variant="contained"
          fullWidth
          disabled={runningAutomaticAlgo}
        >
          {t('selectManually')}
        </Button>
        <LoadingButton
          startIcon={<AutoAwesome />}
          fullWidth
          variant="outlined"
          onClick={() => {
            if (listings.length > 0) {
              setRefreshOpen(true);
            } else {
              generateComparable();
            }
          }}
          loading={runningAutomaticAlgo}
        >
          {t('Automatically')}
        </LoadingButton>
      </Stack>
      <Stack gap={2} sx={{ mt: 1 }}>
        {listings.length === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.palette.text.secondary,
              ...theme.typography.body2,
            }}
          >
            {t('noPropertiesSelected')}
          </div>
        ) : (
          <>
            <div
              style={{
                ...theme.typography.subtitle2,
                fontWeight: theme.text.font.bold,
              }}
            >
              {t('choosePropertiesToCompare')}
            </div>

            <DndContext
              modifiers={[restrictToParentElement]}
              sensors={sensors}
              onDragStart={({ active }) =>
                setActiveNode(
                  listings.find(listing => listing.id === active?.id) ?? null,
                )
              }
              onDragEnd={onDragEndDnd}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={listings.map(listing => listing.id)}
              >
                {listings.map((listing, index) => (
                  <ListingCardDraggable
                    key={listing.id}
                    cmaReportId={cmaReportId}
                    listing={listing}
                    index={index}
                    setListings={setListings}
                    id={listing.id}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </>
        )}

        {createPortal(
          <DragOverlay zIndex={1200} modifiers={[restrictToParentElement]}>
            {activeNode != null && (
              <ListingCardDraggable
                cmaReportId={cmaReportId}
                listing={activeNode}
                id={activeNode.id}
                index={0}
                setListings={setListings}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </Stack>
    </Stack>
  );
};

export default ComparableListingsSection;
