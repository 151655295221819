type Deployment = 'localhost' | 'test' | 'production';

export type App =
  | 'auth'
  | 'ch'
  | 'ch_api'
  | 'ch_lead_fisher'
  | 'ch_price_page'
  | 'fr'
  | 'fr_api'
  | 'fr_lead_fisher'
  | 'fr_price_page'
  | 'de'
  | 'de_api'
  | 'email_service'
  | 'es'
  | 'es_api'
  | 'es_lead_fisher'
  | 'es_price_page'
  | 'gb'
  | 'gb_api'
  | 'it'
  | 'it_api'
  | 'it_lead_fisher'
  | 'it_price_page'
  | 'crm'
  | 'api_crm'
  | 'prices'
  | 'api'
  | 'services'
  | 'hasura'
  | 'hasura_scrapers'
  | 'mobile_app'
  | 'docs'
  | 'docs_gcp'
  | 'vr_tours_ch'
  | 'vr_tours_fr'
  | 'vr_tours_es'
  | 'vr_tours_us'
  | 'system_n8n';

type PredefinedStage = 'localhost' | 'production';

type AppConfig = {
  /**
   * will check if origin has corresponding app name in origins map.
   * if there's no such origin in origins map but origin has port defined then
   * check if there's corresponding app name in ports map then return if so
   */
  parse: (origin: string) => null | string;
  serialize: (stage: string) => string;
  isInternal: boolean;
};

const extendConfig = (
  config: AppConfig,
  ...extensions: ReadonlyArray<[PredefinedStage, string]>
): AppConfig => {
  const stages = new Map<string, string>();
  const origins = new Map<string, string>();
  const ports = new Set<string>();
  for (const [stage, origin] of extensions) {
    const { port } = new URL(origin);
    if (stage === 'localhost' && port) {
      ports.add(port);
    }
    stages.set(stage, origin);
    origins.set(origin, stage);
  }
  return {
    parse: origin => {
      if (origin.includes('host.docker.internal')) {
        origin = origin.replace('host.docker.internal', 'localhost');
      }
      let matched = origins.get(origin);

      if (matched != null) {
        return matched;
      }

      const { port } = new URL(origin);
      if (ports.has(port)) {
        matched = stages.get('localhost');
        if (matched != null) {
          return matched;
        }
      }

      return config.parse(origin);
    },
    serialize: stage => {
      const matched = stages.get(stage);
      if (matched != null) {
        return matched;
      }
      return config.serialize(stage);
    },
    isInternal: config.isInternal ?? false,
  };
};

const compilePattern = (pattern: string) => {
  // escape dots which have special meaning in regexp
  const escaped = pattern.replace(/\./g, '\\.');
  // replace wildcard with named group to extract stage name like
  // stage-something
  // for example *-crm.reaaldvisor.com
  const parameterized = escaped.replace('*', '(?<stage>[\\w-]+)');
  // match exact host resticting start and end
  return new RegExp(`^${parameterized}$`);
};

const buildFromPattern = (pattern: string, isInternal = false): AppConfig => {
  const regexp = compilePattern(pattern);
  return {
    parse: origin => {
      const matched = origin.match(regexp);
      if (matched) {
        // fallback to test when no parameter specified
        return matched?.groups?.['stage'] ?? 'test';
      }
      return null;
    },
    serialize: stage => pattern.replace('*', stage),
    isInternal,
  };
};

const auth = extendConfig(
  buildFromPattern('https://auth-test.realadvisor.com'),
  ['production', 'https://auth.realadvisor.com'],
  ['localhost', 'https://local-auth.realadvisor.com:8001'],
);

const ch = extendConfig(
  buildFromPattern('https://*.app.realadvisor.ch'),
  ['production', 'https://realadvisor.ch'],
  ['localhost', 'http://localhost:3000'],
);

const ch_api = extendConfig(ch, ['localhost', 'http://localhost:8080']);

const ch_price_page = extendConfig(
  buildFromPattern('https://*.app.realadvisor.ch'),
  ['production', 'https://realadvisor.ch'],
  ['localhost', 'http://localhost:3201'],
);

const ch_lead_fisher: AppConfig = {
  parse: origin => {
    if (origin === 'http://localhost:3042') {
      return 'localhost';
    } else if (
      origin.endsWith('valuation-dev.realadvisor.ch') ||
      origin.endsWith('--lead-fisher.netlify.app')
    ) {
      return 'test';
    } else if (origin.endsWith('valuation.realadvisor.ch')) {
      return 'production';
    }

    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for ch_lead_fisher app');
  },
  isInternal: false,
};

const crm: AppConfig = {
  parse: origin => {
    if (origin.includes('local-crm.realadvisor.com:4000')) {
      return 'localhost';
    } else if (origin.endsWith('dev-crm.realadvisor.com')) {
      //extract stage and tenantSlug from origin , named group are forbidden in regexp if no target is specified
      const [, stage, tenantSlug] =
        origin.match(
          /^https:\/\/(?:([\w-]{1,27})--)?([\w-]{1,34})\.dev-crm\.realadvisor\.com$/,
        ) ?? [];

      if (stage != null) {
        return stage;
      }

      return tenantSlug ?? null;
    } else if (origin.endsWith('crm.realadvisor.com')) {
      return 'production';
    }
    return null;
  },
  serialize: stage => {
    if (stage === 'localhost') {
      return 'https://local-crm.realadvisor.com:4000';
    } else if (stage === 'production') {
      return 'https://crm.realadvisor.com';
    }
    return `https://${stage}.dev-crm.realadvisor.com`;
  },
  isInternal: false,
};

const es_lead_fisher: AppConfig = {
  parse: origin => {
    if (origin === 'http://localhost:3044') {
      return 'localhost';
    } else if (origin.endsWith('valuation-dev.realadvisor.es')) {
      return 'test';
    } else if (origin.endsWith('valuation.realadvisor.es')) {
      return 'production';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for es_lead_fisher app');
  },
  isInternal: false,
};

const it_lead_fisher: AppConfig = {
  parse: origin => {
    if (origin === 'http://localhost:3045') {
      return 'localhost';
    } else if (origin.endsWith('valuation-dev.realadvisor.it')) {
      return 'test';
    } else if (origin.endsWith('valuation.realadvisor.it')) {
      return 'production';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for it_lead_fisher app');
  },
  isInternal: false,
};

const fr_lead_fisher: AppConfig = {
  parse: origin => {
    if (origin === 'http://localhost:3043') {
      return 'localhost';
    } else if (origin.endsWith('valuation-dev.realadvisor.fr')) {
      return 'test';
    } else if (origin.endsWith('valuation.realadvisor.fr')) {
      return 'production';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for fr_lead_fisher app');
  },
  isInternal: false,
};

const fr = extendConfig(
  buildFromPattern('https://*.app.realadvisor.fr'),
  ['production', 'https://realadvisor.fr'],
  ['localhost', 'http://localhost:3001'],
);

const fr_api = extendConfig(fr, ['localhost', 'http://localhost:8080']);

const fr_price_page = extendConfig(
  buildFromPattern('https://*.app.realadvisor.fr'),
  ['production', 'https://realadvisor.fr'],
  ['localhost', 'http://localhost:3201'],
);

const de = extendConfig(
  buildFromPattern('https://*.app.realadvisor.de'),
  ['production', 'https://realadvisor.de'],
  ['localhost', 'http://localhost:3002'],
);

const de_api = extendConfig(de, ['localhost', 'http://localhost:8080']);

const email_service = extendConfig(
  buildFromPattern('https://*.email-service.realadvisor.com'),
  ['production', 'https://email-service.realadvisor.com'],
  ['localhost', 'http://localhost:7777'],
);

const email_service_gcp = extendConfig(
  buildFromPattern('https://email-service-*-hidgdg2rjq-ew.a.run.app', true),
  ['production', 'https://email-service-zyvsllcdca-ew.a.run.app'],
  ['localhost', 'http://localhost:7777'],
);

const es = extendConfig(
  buildFromPattern('https://*.app.realadvisor.es'),
  ['production', 'https://realadvisor.es'],
  ['localhost', 'http://localhost:3003'],
);

const es_api = extendConfig(es, ['localhost', 'http://localhost:8080']);

const es_price_page = extendConfig(
  buildFromPattern('https://*.app.realadvisor.es'),
  ['production', 'https://realadvisor.es'],
  ['localhost', 'http://localhost:3201'],
);

const gb = extendConfig(
  buildFromPattern('https://*.app.realadvisor.co.uk'),
  ['production', 'https://realadvisor.co.uk'],
  ['localhost', 'http://localhost:3004'],
);

const gb_api = extendConfig(gb, ['localhost', 'http://localhost:8080']);

const it = extendConfig(
  buildFromPattern('https://*.app.realadvisor.it'),
  ['production', 'https://realadvisor.it'],
  ['localhost', 'http://localhost:3005'],
);

const it_api = extendConfig(it, ['localhost', 'http://localhost:8080']);

const it_price_page = extendConfig(
  buildFromPattern('https://*.app.realadvisor.it'),
  ['production', 'https://realadvisor.it'],
  ['localhost', 'http://localhost:3201'],
);

const prices = extendConfig(
  buildFromPattern('https://*.prices.realadvisor.com'),
  ['production', 'https://prices.realadvisor.com'],
  ['localhost', 'http://localhost:4001'],
);

const docs = extendConfig(
  buildFromPattern('https://*.docs.realadvisor.com'),
  ['production', 'https://docs.realadvisor.com'],
  ['localhost', 'http://localhost:4005'],
);
const docs_gcp = extendConfig(
  buildFromPattern('https://docs-*-hidgdg2rjq-ew.a.run.app', true),
  ['production', 'https://docs-zyvsllcdca-ew.a.run.app'],
);

const api = extendConfig(
  buildFromPattern('https://*.api.realadvisor.com'),
  ['production', 'https://api.realadvisor.com'],
  ['localhost', 'http://localhost:8080'],
);

const services = extendConfig(
  buildFromPattern('https://*.services.realadvisor.com'),
  ['production', 'https://services-zyvsllcdca-ew.a.run.app'],
  ['production', 'https://services.realadvisor.com'],
  ['localhost', 'http://localhost:8080'],
);

const api_crm = extendConfig(
  buildFromPattern('https://api-crm-*-hidgdg2rjq-ew.a.run.app'),
  ['production', 'https://api-crm-zyvsllcdca-ew.a.run.app'],
  ['localhost', 'http://localhost:8080'],
);

const hasura = extendConfig(
  buildFromPattern('https://*.hasura.realadvisor.com'),
  ['localhost', 'http://localhost:8081'],
  ['production', 'https://hasura.realadvisor.com'],
);

const hasura_scrapers = extendConfig(
  buildFromPattern('https://hasura-scrapers-ldpycpultq-ew.a.run.app'),
  ['localhost', 'https://hasura-scrapers-ldpycpultq-ew.a.run.app'],
  ['production', 'https://hasura-scrapers-fqs3j3myvq-ew.a.run.app'],
);

const mobile_app: AppConfig = {
  parse: origin => {
    if (origin === 'https://realadvisor-app.local') {
      return 'localhost';
    } else if (origin === 'https://realadvisor-app.test') {
      return 'test';
    } else if (origin === 'https://realadvisor-app.prod') {
      return 'production';
    }
    return null;
  },
  serialize: stage => {
    if (stage === 'localhost') {
      return 'https://realadvisor-app.local';
    } else if (stage === 'test') {
      return 'https://realadvisor-app.test';
    } else if (stage === 'production') {
      return 'https://realadvisor-app.prod';
    }
    throw Error('Unknown stage for custom app');
  },
  isInternal: false,
};

const vr_tours_ch: AppConfig = {
  parse: origin => {
    if (origin === 'https://vr.realadvisor.ch') {
      return 'production';
    }
    if (
      origin === 'https://dev-vr.realadvisor.ch' ||
      origin === 'http://localhost:3210' ||
      origin.endsWith('realadvisor-vr.netlify.app') ||
      origin.endsWith('dev-vr.netlify.app')
    ) {
      // use stage db
      return 'stage';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for vr_tours_ch app');
  },
  isInternal: false,
};

const vr_tours_fr: AppConfig = {
  parse: origin => {
    if (origin === 'https://vr.realadvisor.fr') {
      return 'production';
    }
    if (
      origin === 'https://dev-vr.realadvisor.fr' ||
      origin === 'http://localhost:3209'
    ) {
      // use stage db
      return 'stage';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for vr_tours_fr app');
  },
  isInternal: false,
};

const vr_tours_es: AppConfig = {
  parse: origin => {
    if (origin === 'https://vr.realadvisor.es') {
      return 'production';
    }
    if (
      origin === 'https://dev-vr.realadvisor.es' ||
      origin === 'http://localhost:3208'
    ) {
      // use stage db
      return 'stage';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for vr_tours_es app');
  },
  isInternal: false,
};

const vr_tours_us: AppConfig = {
  parse: origin => {
    if (origin === 'https://vr.realadvisor.com') {
      return 'production';
    }
    if (
      origin === 'https://dev-vr.realadvisor.com' ||
      origin === 'http://localhost:3207'
    ) {
      // use stage db
      return 'stage';
    }
    return null;
  },
  serialize: () => {
    throw Error('Serialize should not be used for vr_tours_us app');
  },
  isInternal: false,
};

const system_n8n = extendConfig(
  buildFromPattern('https://*.system-n8n.realadvisor.com'),
  ['localhost', 'http://localhost:5678'],
  ['production', 'https://system-n8n.realadvisor.com'],
);

const apps = new Map<App, AppConfig[]>([
  // match client app first and then api
  // to make getAppByOrigin work for country detection
  ['auth', [auth]],
  ['ch', [ch]],
  ['ch_api', [ch_api]],
  ['ch_lead_fisher', [ch_lead_fisher]],
  ['ch_price_page', [ch_price_page]],
  ['fr', [fr]],
  ['fr_api', [fr_api]],
  ['fr_lead_fisher', [fr_lead_fisher]],
  ['fr_price_page', [fr_price_page]],
  ['de', [de]],
  ['de_api', [de_api]],
  ['email_service', [email_service, email_service_gcp]],
  ['es', [es]],
  ['es_api', [es_api]],
  ['es_lead_fisher', [es_lead_fisher]],
  ['es_price_page', [es_price_page]],
  ['gb', [gb]],
  ['gb_api', [gb_api]],
  ['it', [it]],
  ['it_api', [it_api]],
  ['it_lead_fisher', [it_lead_fisher]],
  ['it_price_page', [it_price_page]],
  ['crm', [crm]],
  ['api_crm', [api_crm]],
  ['prices', [prices]],
  ['api', [api]],
  ['services', [services]],
  ['hasura', [hasura]],
  ['hasura_scrapers', [hasura_scrapers]],
  ['mobile_app', [mobile_app]],
  ['docs', [docs, docs_gcp]],
  ['vr_tours_ch', [vr_tours_ch]],
  ['vr_tours_fr', [vr_tours_fr]],
  ['vr_tours_es', [vr_tours_es]],
  ['vr_tours_us', [vr_tours_us]],
  ['system_n8n', [system_n8n]],
]);

export const getDeploymentByOrigin = (uri: string): Deployment => {
  const { origin } = new URL(uri);
  for (const [, app] of apps) {
    for (const config of app) {
      const stage = config.parse(origin);
      if (stage === 'production') {
        return 'production';
      }
      if (stage === 'localhost') {
        return 'localhost';
      }
      if (stage != null) {
        return 'test';
      }
    }
  }
  throw Error('Unknown deployment by origin');
};

// used to extract country from origin
export const getAppByOrigin = (uri: string): App => {
  const { origin } = new URL(uri);
  for (const [name, app] of apps) {
    for (const config of app) {
      const stage = config.parse(origin);
      if (stage != null) {
        return name;
      }
    }
  }
  throw Error('Unknown app by origin');
};

export const getAnotherAppOrigin = (
  uri: string,
  targetApp: App,
  prioritizeInternal: boolean = false,
): string => {
  const { origin } = new URL(uri);
  let stage: string | null = null;
  for (const [, app] of apps) {
    for (const config of app) {
      stage = config.parse(origin);
      if (stage != null) {
        const targetAppConfig = apps.get(targetApp);
        if (stage != null && targetAppConfig != null) {
          if (prioritizeInternal && targetAppConfig.length > 1) {
            // Try internal config first if available
            const internalConfig = targetAppConfig.find(
              a => a.isInternal === true,
            );
            if (internalConfig != null) {
              return internalConfig.serialize(stage);
            }
          }
          if (targetAppConfig[0] != null) {
            return targetAppConfig[0].serialize(stage);
          }
        }
      }
    }
  }

  throw Error('Unknown another app by origin');
};

export const isAppOrigin = (uri: string, targetApp: App): boolean => {
  try {
    const { origin } = new URL(uri);
    const targetAppConfigs = apps.get(targetApp);
    if (targetAppConfigs?.[0] != null) {
      return targetAppConfigs[0].parse(origin) != null;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};
