/**
 * @generated SignedSource<<11ebd650cb5c6a26a9850a68b6c3235e>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
export type financingLeadCreationDrawerQuery$variables = {||};
export type financingLeadCreationDrawerQuery$data = {|
  +pipelines: $ReadOnlyArray<{|
    +stages: $ReadOnlyArray<{|
      +id: string,
      +label: string,
      +status: LeadStageStatus,
    |}>,
  |}>,
|};
export type financingLeadCreationDrawerQuery = {|
  response: financingLeadCreationDrawerQuery$data,
  variables: financingLeadCreationDrawerQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "name",
    "value": "financing"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LeadStage",
  "kind": "LinkedField",
  "name": "stages",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "financingLeadCreationDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": "pipelines(name:\"financing\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "financingLeadCreationDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Pipeline",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": "pipelines(name:\"financing\")"
      }
    ]
  },
  "params": {
    "cacheID": "4a34e101b3906bae14a468dc61f48a74",
    "id": null,
    "metadata": {},
    "name": "financingLeadCreationDrawerQuery",
    "operationKind": "query",
    "text": "query financingLeadCreationDrawerQuery {\n  pipelines(name: \"financing\") {\n    stages {\n      id\n      label\n      status\n    }\n    id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "7960d3d1babfa7638897360313fe921a";

export default node;
