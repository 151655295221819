import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import type { ActivityFieldsFragment } from '../../__generated__/graphql';
import { Feed } from '../../components/feed/Feed';
import { FeedSkeleton } from '../../components/feed/FeedSkeleton';

import { LEAD_FEED } from './leadsQueries';

interface LeadFeedProps {
  contact_id: string;
  email_addresses?: string[];
  lead_id?: string;
}

export const LeadFeed = ({
  contact_id,
  email_addresses,
  lead_id,
}: LeadFeedProps) => {
  const skip_email_activities = !email_addresses?.length;

  const { data, loading, refetch } = useQuery(LEAD_FEED, {
    variables: { user_id: contact_id, email_addresses, skip_email_activities },
    notifyOnNetworkStatusChange: true,
  });

  const activities: ActivityFieldsFragment[] = useMemo(
    () =>
      [
        ...(data?.email_activities ?? []),
        ...(data?.lead_activites ?? []),
        ...(data?.user_activities ?? []),
      ].sort((a, b) => {
        if (a?.created_at > b?.created_at) {
          return -1;
        }
        if (a?.created_at < b?.created_at) {
          return 1;
        }
        return 0;
      }),
    [data],
  );

  if (loading) {
    return <FeedSkeleton />;
  }

  return (
    <Feed activities={activities} lead_id={lead_id} reload={() => refetch()} />
  );
};
