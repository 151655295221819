import { useState } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Image } from '@realadvisor/image';

import { useLocale } from '../../../src/hooks/locale';
import type { GetLeadAgentsQuery } from '../../__generated__/graphql';
import { TimeAgo } from '../../components/TimeAgo';
import { UserSelect } from '../../components/user-select/UserSelect';

import { ADD_LEAD_AGENT, DELETE_LEAD_AGENT } from './leadsQueries';

export const GET_LEAD_AGENTS = gql`
  query GetLeadAgents($lead_id: uuid!) {
    lead_agents(
      where: { lead_id: { _eq: $lead_id } }
      order_by: { created_at: asc }
    ) {
      id
      created_at
      claimed_at
      passed_at
      source
      user {
        id
        first_name
        last_name
        default_team {
          name
        }
        user_images {
          image {
            url
          }
        }
      }
      lead {
        id
        tenant_id
      }
    }
  }
`;

interface LeadAgentsProps {
  lead_id: string;
}

export const LeadAgents = ({ lead_id }: LeadAgentsProps) => {
  const { t } = useLocale();
  const [mutationError, setMutationError] = useState<string | null>(null);
  const [showUserSelect, setShowUserSelect] = useState<boolean>(false);

  const { data: { lead_agents } = {}, loading } = useQuery<GetLeadAgentsQuery>(
    GET_LEAD_AGENTS,
    {
      variables: { lead_id },
    },
  );

  const [addLeadAgent] = useMutation(ADD_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed', 'GetLeadAgents'],
  });
  const [DeleteLeadAgent] = useMutation(DELETE_LEAD_AGENT, {
    refetchQueries: ['GetLead', 'LeadFeed', 'GetLeadAgents'],
  });

  return (
    <Stack spacing={1}>
      {loading &&
        Array.from({ length: 3 }).map((_, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={1}
            alignItems={'center'}
            width="100%"
          >
            <Avatar />
            <Stack direction="row" flexGrow={1}>
              <Stack direction="column" width="70%" px={1}>
                <Typography variant="body1">
                  <Skeleton width="80%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="50%" />
                </Typography>
              </Stack>

              <Stack direction="column" width="30%" px={1}>
                <Typography variant="body1">
                  <Skeleton width="80%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="50%" />
                </Typography>
              </Stack>
            </Stack>
            <IconButton className="delete-button" disabled>
              <DeleteOutline />
            </IconButton>
          </Stack>
        ))}

      <TableContainer>
        <Table size="small">
          <TableBody>
            {lead_agents?.map(lead_agent => (
              <TableRow key={lead_agent.id}>
                <TableCell sx={{ width: '40px', pr: 1, pl: 0 }}>
                  <Avatar>
                    {lead_agent.user.user_images?.[0]?.image.url ? (
                      <Image
                        src={lead_agent.user.user_images?.[0]?.image.url}
                        srcSize={[[40, 40]]}
                      />
                    ) : (
                      <>
                        {lead_agent.user.first_name?.[0]}{' '}
                        {lead_agent.user.last_name?.[0]}
                      </>
                    )}
                  </Avatar>
                </TableCell>
                <TableCell sx={{ px: 1, maxWidth: '150px' }}>
                  <Typography variant="body2" noWrap>
                    {lead_agent.user.first_name} {lead_agent.user.last_name}
                  </Typography>
                  <Typography variant="body2" noWrap color="text.secondary">
                    {lead_agent.user.default_team?.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '20px', px: 2 }}>
                  <Typography variant="body2" noWrap>
                    {lead_agent.source}
                  </Typography>
                  <Typography variant="body2" noWrap color="text.secondary">
                    <TimeAgo dateString={lead_agent.created_at} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '25px', px: 1 }}>
                  <Typography variant="body2" noWrap>
                    {lead_agent.claimed_at
                      ? t('Claimed')
                      : lead_agent.passed_at
                      ? t('Passed')
                      : t('Pending')}
                  </Typography>
                  <Typography variant="body2" noWrap color="text.secondary">
                    <TimeAgo
                      dateString={
                        lead_agent.claimed_at ??
                        lead_agent.passed_at ??
                        lead_agent.created_at
                      }
                    />
                  </Typography>
                </TableCell>
                <TableCell sx={{ lx: 2, pr: 0, textAlign: 'right' }}>
                  <IconButton
                    size="small"
                    disabled={lead_agent.source === 'result_page'}
                    className="delete-button"
                    onClick={() =>
                      DeleteLeadAgent({
                        variables: { id: lead_agent.id },
                      }).catch(error => {
                        setMutationError(error.message);
                      })
                    }
                  >
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {lead_agents?.length === 0 && <div>{t('No agent')}</div>}

      {mutationError && <Alert severity="error">{mutationError}</Alert>}
      <Stack spacing={1}>
        {showUserSelect && (
          <UserSelect
            autoFocus
            InputProps={{
              onBlur: () => setShowUserSelect(false),
            }}
            onBlur={() => setShowUserSelect(false)}
            onChange={user_id => {
              if (!user_id) {
                return;
              }
              addLeadAgent({
                variables: {
                  lead_id,
                  user_id,
                },
                onError: error => {
                  setMutationError(error.message);
                },
              }).then(() => setShowUserSelect(false));
            }}
            filters={{
              is_broker: { _eq: true },
              id: { _nin: lead_agents?.map(agent => agent.user.id) },
            }}
          />
        )}
        {!showUserSelect && (
          <Button onClick={() => setShowUserSelect(true)}>
            <PersonAdd sx={{ mr: 1 }} />
            {t('Add agent')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
