import { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { type LayoutProps, useCMAEditor } from './CMAReportEditorWorkflow';
import MobilePreview from './MobilePreview';

const PADDING_BOTTOM = 45;

const MobileLayout = (props: LayoutProps) => {
  const { editor } = props;
  const editorEl = useRef<HTMLDivElement>(null);
  const viewerEl = useRef<HTMLDivElement>(null);
  const { mobileTab, cmaReport } = useCMAEditor();

  useEffect(() => {
    if (mobileTab === 'editor') {
      editorEl.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (mobileTab === 'viewer') {
      viewerEl.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mobileTab]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingBottom: `${PADDING_BOTTOM}px`,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            scrollSnapType: 'x mandatory',
            display: 'flex',
            overflow: 'hidden',
            flex: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box
            ref={editorEl}
            css={{
              position: 'relative',
              overflowY: 'auto',
              minWidth: '100vw',
              scrollSnapAlign: 'center',
            }}
          >
            {editor}
          </Box>
          <div
            ref={viewerEl}
            css={{
              minWidth: '100vw',
              position: 'relative',
              overflow: 'hidden',
              scrollSnapAlign: 'center',
            }}
          >
            {cmaReport && <MobilePreview {...props} />}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default MobileLayout;
