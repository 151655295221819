import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { HelpOutline } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { type LineSeriesType } from '@mui/x-charts';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart';
import type { AxisConfig } from '@mui/x-charts/models';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { differenceInCalendarDays, format, parse, subDays } from 'date-fns';

import { useLocale } from '../../../src/hooks/locale';
import type { GetTeamVisibilityStatsGraphQueryVariables } from '../../__generated__/graphql';

import { GET_TEAM_VISIBILITY_STATS_GRAPH } from './visibilityStatsQueries';

const formatString = 'yyyyMMdd';
type Props = {
  teamId: string | undefined;
  isAdmin?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  hostname: string | null;
};

export const VisibilityGraph = ({
  teamId,
  isAdmin,
  startDate,
  endDate,
  hostname,
}: Props) => {
  const { t, locale } = useLocale();
  const { text } = useTheme();

  const [activeLines, setActiveLines] = useState({
    impressions: true,
    views: true,
    phoneClicks: false,
    ctr: false,
    averagePosition: false,
  });

  const toggleLine = (line: keyof typeof activeLines) => {
    setActiveLines(prevState => ({
      ...prevState,
      [line]: !prevState[line],
    }));
  };

  const variables: GetTeamVisibilityStatsGraphQueryVariables = useMemo(
    () => ({
      teamId: teamId ?? null,
      agentId: null,
      startDate: startDate ? format(startDate, formatString) : null,
      endDate: endDate ? format(endDate, formatString) : null,
      groupBy: 'date' as GetTeamVisibilityStatsGraphQueryVariables['groupBy'],
      hostname,
    }),
    [teamId, startDate, endDate, hostname],
  );

  const { data, loading } = useQuery(GET_TEAM_VISIBILITY_STATS_GRAPH, {
    variables,
    skip: !teamId && !isAdmin,
  });

  // oldest date in the graph
  const today = new Date();
  const oldestDate = data?.impressions_graph_data?.data?.[0]?.name
    ? parse(
        data?.impressions_graph_data?.data?.[0]?.name,
        'yyyyMMdd',
        new Date(),
      )
    : subDays(today, 28);

  const numberOfDays = differenceInCalendarDays(
    endDate ?? today,
    startDate ?? oldestDate,
  );

  const xLabels = [...Array(numberOfDays + 1).keys()]
    .map(x => format(subDays(endDate ?? today, x), 'd/M/yyyy'))
    .reverse();

  // const map impressions data to x axis
  const impressionsData = xLabels.map(x => {
    const col = data?.impressions_graph_data?.data?.find(
      y => y.name === format(parse(x, 'd/M/yyyy', new Date()), formatString),
    );
    return Number(col?.count ?? 0);
  });

  // const map views data to x axis
  const viewsData = xLabels.map(x => {
    const col = data?.views_graph_data?.data?.find(
      y => y.name === format(parse(x, 'd/M/yyyy', new Date()), formatString),
    );
    return Number(col?.user_count ?? 0);
  });

  // const map show phone number data to x axis
  const showPhoneNumberData = xLabels.map(x => {
    const col = data?.show_phone_number_data?.data?.find(
      y => y.name === format(parse(x, 'd/M/yyyy', new Date()), formatString),
    );
    return Number(col?.count ?? 0);
  });

  // const map average position data to x axis
  const averagePositionData = xLabels.map(x => {
    const col = data?.average_position?.data?.find(
      y => y.name === format(parse(x, 'd/M/yyyy', new Date()), formatString),
    );
    return Number(col?.average_position ?? 0);
  });

  // sum impressions data
  const totalImpressions =
    impressionsData?.reduce((acc, curr) => (curr ? acc + curr : acc), 0) ?? 0;

  // sum views data
  const totalProfileViews =
    viewsData?.reduce((acc, curr) => (curr ? acc + curr : acc), 0) ?? 0;

  // sum show phone number data
  const totalShowPhoneNumber =
    showPhoneNumberData?.reduce((acc, curr) => (curr ? acc + curr : acc), 0) ??
    0;

  // Calculate weighted average position, ignoring null average positions
  const { sum, totalCount } = data?.average_position?.data?.reduce(
    (acc, curr) => {
      const count = Number(curr.count ?? 0);
      const position = curr.average_position
        ? Number(curr.average_position)
        : null;

      if (position != null) {
        acc.sum += count * position;
        acc.totalCount += count;
      }

      return acc;
    },
    { sum: 0, totalCount: 0 },
  ) ?? { sum: 0, totalCount: 0 };

  const averagePosition = totalCount > 0 ? sum / totalCount : 0;

  const KPIs = [
    {
      title: t('Total Impressions'),
      value: totalImpressions.toLocaleString(locale),
      color: '#4285f4',
      description: t(
        'Number of times your profile link was displayed to users on RealAdvisor',
      ),
      line: 'impressions',
    },
    {
      title: t('Profile Clicks'),
      value: totalProfileViews.toLocaleString(locale),
      color: '#5e35b1',
      description: t(
        'Number of unique users who viewed your agent or agency profile',
      ),
      line: 'views',
    },
    {
      title: t('CTR'),
      value:
        ((totalProfileViews / totalImpressions) * 100).toLocaleString(locale, {
          maximumFractionDigits: 1,
        }) + '%',
      color: '#00897b',
      description: t(
        'Click-through rate, the ratio of users who click on a your profile to the number of total users who view your profile',
      ),
      line: 'ctr',
    },
    {
      title: t('Phone Clicks'),
      value: totalShowPhoneNumber.toLocaleString(locale),
      color: '#31A64C',
      description: t(
        'Number of times users clicked your phone number on RealAdvisor',
      ),
      line: 'phoneClicks',
    },
    {
      title: t('Avg. Position'),
      value: averagePosition?.toFixed(1) ?? '0',
      color: '#e8710a',
      description: t(
        'The average position of your profile in search results on RealAdvisor',
      ),
      line: 'averagePosition',
    },
  ];

  const formatTickLabel = (value: number) => {
    return value >= 1000 ? `${(value / 1000).toFixed(0)}K` : value.toString();
  };

  const series: LineSeriesType[] = [];

  if (activeLines.views) {
    series.push({
      data: viewsData,
      label: 'Views',
      type: 'line',
      yAxisKey: 'viewsAxisId',
      curve: 'linear',
      color: '#5e35b1',
    });
  }
  if (activeLines.impressions) {
    series.push({
      data: impressionsData,
      label: 'Impressions',
      type: 'line',
      yAxisKey: 'impressionsAxisId',
      curve: 'linear',
      color: '#4285f4',
    });
  }
  if (activeLines.phoneClicks) {
    series.push({
      data: showPhoneNumberData,
      label: 'Phone Clicks',
      type: 'line',
      yAxisKey: 'phoneClicksAxisId',
      curve: 'linear',
      color: '#31A64C',
    });
  }
  if (activeLines.ctr) {
    series.push({
      data: xLabels.map((_, index) =>
        totalImpressions
          ? (viewsData[index] / impressionsData[index]) * 100
          : 0,
      ),
      label: 'CTR',
      type: 'line',
      yAxisKey: 'ctrAxisId',
      curve: 'linear',
      color: '#00897b',
    });
  }
  if (activeLines.averagePosition) {
    series.push({
      data: averagePositionData,
      label: 'Avg. Position',
      type: 'line',
      yAxisKey: 'averagePositionAxisId',
      curve: 'linear',
      color: '#e8710a',
    });
  }

  return (
    <>
      <Grid container sx={{ width: 'calc(100% + 1px)' }}>
        {KPIs.map((kpi, index) => (
          <>
            <Grid
              key={index}
              item
              p={2}
              xs={6}
              md={3}
              sx={{
                background: activeLines[kpi.line as keyof typeof activeLines]
                  ? kpi.color
                  : '#ffffff',
                color: activeLines[kpi.line as keyof typeof activeLines]
                  ? '#ffffff'
                  : 'rgba(0,0,0,.54)',
                position: 'relative',
                cursor: 'pointer',
                borderRight: activeLines[kpi.line as keyof typeof activeLines]
                  ? 'none'
                  : '1px solid lightgray',
                borderBottom: activeLines[kpi.line as keyof typeof activeLines]
                  ? 'none'
                  : '1px solid lightgray',
              }}
              onClick={() => toggleLine(kpi.line as keyof typeof activeLines)}
            >
              <Typography variant="body2" sx={text.ellipsis}>
                {kpi.title}
              </Typography>
              <Typography variant="h5">
                {loading ? <Skeleton animation="pulse" /> : kpi.value}
              </Typography>
              <Tooltip
                title={
                  <>
                    <Typography variant="subtitle2">{kpi.title}</Typography>
                    <Typography variant="body2">{kpi.description}</Typography>
                  </>
                }
                sx={{
                  position: 'absolute',
                  bottom: 4,
                  right: 4,
                  cursor: 'pointer',
                  opacity: 0.8,
                }}
              >
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Grid>
          </>
        ))}
      </Grid>
      {loading && (
        <Box
          justifyContent="center"
          height="300px"
          display="flex"
          alignItems="center"
        >
          <CircularProgress disableShrink />
        </Box>
      )}
      {!loading && !teamId && !isAdmin && (
        <Box
          justifyContent="center"
          height="300px"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body1">{t('Select a team')}</Typography>
        </Box>
      )}
      {!loading && data && (teamId || isAdmin) && (
        <ResponsiveChartContainer
          series={series}
          xAxis={[
            {
              data: xLabels,
              scaleType: 'point',
              id: 'xAxisId',
            },
          ]}
          yAxis={
            [
              activeLines.views && {
                id: 'viewsAxisId',
                scaleType: 'linear',
                valueFormatter: formatTickLabel,
              },
              activeLines.impressions && {
                id: 'impressionsAxisId',
                scaleType: 'linear',
                valueFormatter: formatTickLabel,
              },
              activeLines.phoneClicks && {
                id: 'phoneClicksAxisId',
                scaleType: 'linear',
                valueFormatter: formatTickLabel,
              },
              activeLines.ctr && {
                id: 'ctrAxisId',
                scaleType: 'linear',
                valueFormatter: formatTickLabel,
              },
              activeLines.averagePosition && {
                id: 'averagePositionAxisId',
                scaleType: 'linear',
                valueFormatter: formatTickLabel,
              },
            ].filter(Boolean) as AxisConfig[]
          }
          height={300}
        >
          {activeLines.impressions && (
            <ChartsYAxis
              position="left"
              axisId="impressionsAxisId"
              disableLine={true}
            />
          )}
          {[
            activeLines.views,
            activeLines.phoneClicks,
            activeLines.ctr,
            activeLines.averagePosition,
          ].filter(Boolean).length < 2 && (
            <>
              {activeLines.views && (
                <ChartsYAxis
                  position="right"
                  axisId="viewsAxisId"
                  disableLine={true}
                />
              )}
              {activeLines.phoneClicks && (
                <ChartsYAxis
                  position="right"
                  axisId="phoneClicksAxisId"
                  disableLine={true}
                />
              )}
              {activeLines.ctr && (
                <ChartsYAxis
                  position="right"
                  axisId="ctrAxisId"
                  disableLine={true}
                />
              )}
              {activeLines.averagePosition && (
                <ChartsYAxis
                  position="right"
                  axisId="averagePositionAxisId"
                  disableLine={true}
                />
              )}
            </>
          )}
          <ChartsXAxis
            position="bottom"
            axisId="xAxisId"
            disableTicks={true}
            tickLabelStyle={{
              textAnchor: 'start',
            }}
          />
          <ChartsAxisHighlight x="line" />
          <LinePlot />
          <ChartsTooltip />
          <LineHighlightPlot />
        </ResponsiveChartContainer>
      )}
    </>
  );
};
