import {
  type FC,
  type ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { type ApolloError } from '@apollo/client';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Chat from '@mui/icons-material/Chat';
import Description from '@mui/icons-material/Description';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Groups from '@mui/icons-material/Groups';
import Home from '@mui/icons-material/Home';
import HomeWork from '@mui/icons-material/HomeWork';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Leaderboard from '@mui/icons-material/Leaderboard';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Settings from '@mui/icons-material/Settings';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../../shared/global-id';
import { type Language, useLocale } from '../../../../src/hooks/locale';
import { MutationErrorSnackbar } from '../../../components/MutationErrorModal';
import type { CMAReportComponentProps, PageId } from '../shared';
import StepHedonisticValuation from '../step-listing-appraise/StepHedonisticValuation';
import StepListingsCompareWrapper from '../step-listings-compare/StepListingsCompare';
import StepAppendFiles from '../StepAppendFiles';
import StepCoverPhoto from '../stepCoverPhoto/StepCoverPhoto';
import StepDescription from '../StepDescription';
import StepFinal from '../StepFinal';
import StepIntroduction from '../StepIntroduction';
import StepOtherValuations from '../StepOtherValuations';
import StepPotentialBuyers from '../StepPotentialBuyers';
import StepPropertyDetails from '../StepPropertyDetails';
import StepSettings from '../StepSettings';

import type { CMAPropertyTypes, CMAReportType } from './CMAReportEditor';

type CMAEditorContextType = {
  mobileTab: 'viewer' | 'editor';
  setMobileTab: (value: 'viewer' | 'editor') => void;
  setCurrentStepByIndex: (index: number) => void;
  documentId: string;
  templateId: string;
  viewerPageId: PageId;
  setViewerPageId: (pageId: PageId) => void;
  steps: StepType[];
  cmaReport: CMAReportType;
  currentStepData: StepType;
  currentStepIndex: number;
  propertyTypes: CMAPropertyTypes;
  setError: (error: ApolloError) => void;
  cmaLanguage: Language;
};

type StepPosition = 'main' | 'footer';

type BaseStepType = {
  title: string;
  icon: ReactNode;
};

export type ComponentStep = BaseStepType & {
  Component: FC<CMAReportComponentProps>;
  pageId: PageId;
  active?: boolean;
  databaseToggleField?: string;
  onClick?: never;
  position: StepPosition;
};

type ActionStep = BaseStepType & {
  Component?: never;
  pageId?: never;
  onClick?: () => void;
  position: StepPosition;
};

export type StepType = ComponentStep | ActionStep;

export const CMAEditorContext = createContext<CMAEditorContextType | null>(
  null,
);

const getDefaultStep = (
  steps: StepType[],
  pageId: PageId | undefined,
): PageId => {
  const componentSteps = steps.filter(
    (step): step is ComponentStep => 'pageId' in step,
  );

  return (
    componentSteps.find(step => step.pageId === pageId)?.pageId ||
    componentSteps[0].pageId
  );
};

export const CMAEditorProvider = ({
  children,
  cmaReport,
  propertyTypes,
}: {
  children: ReactNode;
  cmaReport: CMAReportType;
  propertyTypes: CMAPropertyTypes;
}) => {
  const { t, countryCode } = useLocale();
  const navigate = useNavigate();
  const [mobileTab, setMobileTab] = useState<'viewer' | 'editor'>('editor');
  const { pageId } = useParams<{ pageId: PageId }>();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<ApolloError | null>(null);

  const documentId = toGlobalId('CMAReport', cmaReport.id);
  const templateId = cmaReport.doc_template?.id
    ? toGlobalId('DocTemplate', cmaReport.doc_template.id)
    : '';

  const handleSaveAndExit = useCallback(() => {
    navigate({
      pathname: '/cma-reports',
      search: searchParams.toString(),
    });
  }, [navigate, searchParams]);

  const steps = useMemo(
    () => [
      {
        title: t('Cover'),
        Component: StepCoverPhoto,
        pageId: 'page-cover' as const,
        icon: <InsertDriveFile />,
        active: cmaReport.include_cover,
        databaseToggleField: 'include_cover',
        position: 'main' as const,
      },
      {
        title: t('Introduction'),
        Component: StepIntroduction,
        pageId: 'page-introduction' as const,
        icon: <Description />,
        active: cmaReport.include_introduction,
        databaseToggleField: 'include_introduction',
        position: 'main' as const,
      },
      {
        title: t('Property'),
        Component: StepPropertyDetails,
        pageId: 'page-property' as const,
        icon: <Home />,
        active: cmaReport.include_property,
        databaseToggleField: 'include_property',
        position: 'main' as const,
      },
      {
        title: t('Description'),
        Component: StepDescription,
        pageId: 'page-description' as const,
        icon: <Chat />,
        active: cmaReport.include_description,
        databaseToggleField: 'include_description',
        position: 'main' as const,
      },
      {
        title: t('AVM'),
        Component: StepHedonisticValuation,
        pageId: 'page-hedonistic-valuation' as const,
        icon: <Home />,
        active: cmaReport.include_hedonistic_valuation,
        databaseToggleField: 'include_hedonistic_valuation',
        position: 'main' as const,
      },
      {
        title: t('Comparable'),
        Component: StepListingsCompareWrapper,
        pageId: 'page-comparables-list' as const,
        icon: <HomeWork />,
        active: cmaReport.include_comparables_valuation,
        databaseToggleField: 'include_comparables_valuation',
        position: 'main' as const,
      },
      {
        title: t('Other valuations'),
        Component: StepOtherValuations,
        pageId: 'page-other-valuations' as const,
        icon: <Leaderboard />,
        active: cmaReport.include_valuation_methods,
        databaseToggleField: 'include_valuation_methods',
        position: 'main' as const,
      },
      {
        title: t('Final value'),
        Component: StepFinal,
        pageId: 'page-final' as const,
        icon: <AttachMoney />,
        active: cmaReport?.include_final_value,
        databaseToggleField: 'include_final_value',
        position: 'main' as const,
      },
      ...(countryCode === 'CH'
        ? [
            {
              title: t('Potential buyers'),
              Component: StepPotentialBuyers,
              pageId: 'page-potential-buyers' as const,
              icon: <Groups />,
              active: cmaReport?.include_potential_buyers,
              databaseToggleField: 'include_potential_buyers',
              position: 'main' as const,
            },
          ]
        : []),
      {
        title: t('Append files'),
        Component: StepAppendFiles,
        pageId: 'page-append-files' as const,
        icon: <NoteAdd />,
        active: cmaReport.include_append_files,
        databaseToggleField: 'include_append_files',
        position: 'main' as const,
      },
      {
        title: t('Settings'),
        Component: StepSettings,
        pageId: 'page-settings' as const,
        icon: <Settings />,
        position: 'footer' as const,
      },
      {
        title: t('Save and exit'),
        icon: <ExitToApp />,
        onClick: handleSaveAndExit,
        position: 'footer' as const,
      },
    ],
    [cmaReport, countryCode, handleSaveAndExit, t],
  );

  const defaultPageId = useMemo(
    () => getDefaultStep(steps, pageId),
    [steps, pageId],
  );
  const [editorPageId, setEditorPageId] = useState<PageId>(defaultPageId);
  const [viewerPageId, setViewerPageId] = useState<PageId>(defaultPageId);

  const currentStepIndex = useMemo(
    () =>
      steps.findIndex(step => 'pageId' in step && step.pageId === editorPageId),
    [steps, editorPageId],
  );

  const currentStepData = steps[currentStepIndex];

  const setCurrentStepByIndex = useCallback(
    (index: number) => {
      const pageId = steps[index].pageId;
      if (!pageId) {
        return;
      }

      navigate({
        pathname: `./../${pageId}`,
        search: searchParams.toString(),
      });
      setEditorPageId(pageId);
      setViewerPageId(pageId);
    },
    [navigate, searchParams, steps],
  );

  const memoizedValue = useMemo(
    () => ({
      steps,
      mobileTab,
      setMobileTab,
      documentId,
      templateId,
      cmaReport,
      currentStepData,
      currentStepIndex,
      setCurrentStepByIndex,
      viewerPageId,
      setViewerPageId,
      propertyTypes,
      setError,
      cmaLanguage: cmaReport.language,
    }),
    [
      steps,
      mobileTab,
      documentId,
      templateId,
      cmaReport,
      currentStepData,
      currentStepIndex,
      setCurrentStepByIndex,
      viewerPageId,
      propertyTypes,
      setError,
    ],
  );

  return (
    <CMAEditorContext.Provider value={memoizedValue}>
      {children}
      <MutationErrorSnackbar error={error} onClose={() => setError(null)} />
    </CMAEditorContext.Provider>
  );
};
