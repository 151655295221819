import { type MutableRefObject } from 'react';

import { Box } from '@mui/material';

import type { PageId } from '../shared';

import { type LayoutProps, useCMAEditor } from './CMAReportEditorWorkflow';
import DesktopPreview from './DesktopPreview';

export type DesktopLayoutProps = LayoutProps & {
  scrollToPageRef: MutableRefObject<(pageId: PageId) => void>;
};

const DesktopLayout = ({
  iFrameRef,
  editor,
  scrollToPageRef,
}: DesktopLayoutProps) => {
  const { cmaReport } = useCMAEditor();

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        transition: 'width 0.2s ease',
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: 0,
          }}
        >
          {editor}
        </Box>
      </Box>
      {cmaReport && (
        <DesktopPreview
          iFrameRef={iFrameRef}
          scrollToPageRef={scrollToPageRef}
        />
      )}
    </Box>
  );
};

export default DesktopLayout;
