import * as React from 'react';

import { IconButton, MenuItem } from '@material-ui/core';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useLocation, useNavigate } from 'react-router-dom';

import { Menu } from '../../controls/popup';
import { useLocale } from '../../hooks/locale';
import { MoreVert } from '../../icons/more-vert';
import { LeadDeleteDialog } from '../../shared/lead-delete-dialog';

import type { leadControls_lead$key } from './__generated__/leadControls_lead.graphql';
import type { leadControls_root$key } from './__generated__/leadControls_root.graphql';
import type { leadControlsUpdateLeadMutation } from './__generated__/leadControlsUpdateLeadMutation.graphql';

type Props = {
  root: leadControls_root$key;
  lead: leadControls_lead$key;
  onActivityCreate: () => void;
  onDelete?: () => void;
};

export const LeadControls = (props: Props) => {
  const root = useFragment(
    graphql`
      fragment leadControls_root on Query {
        me {
          isAdmin
        }
        # this should not be hardcoded
        # if we move mortgages back to the leads table
        pipelines(name: "brokerage") {
          stages {
            id
            name
          }
        }
      }
    `,
    props.root,
  );
  const lead = useFragment(
    graphql`
      fragment leadControls_lead on Lead {
        id
        ...leadDeleteDialog_lead
        completed
        stage {
          id
        }
      }
    `,
    props.lead,
  );

  const [updateLead] = useMutation<leadControlsUpdateLeadMutation>(graphql`
    mutation leadControlsUpdateLeadMutation($input: UpsertLeadInput!) {
      upsertLead(input: $input) {
        lead {
          ...leadControls_lead
          ...leadPipelineCard_lead
        }
      }
    }
  `);

  const { t } = useLocale();
  const navigate = useNavigate();
  const [menu, setMenu] = React.useState(false);
  const menuRef = React.useRef(null);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const { me } = root;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const onDeleteHandler = () => {
    searchParams.delete('leadId');
    navigate({
      pathname: `/leads`,
      search: `?${searchParams.toString()}`,
    });
    props.onDelete?.();
  };

  return (
    <>
      <IconButton
        ref={menuRef}
        title={t('leadActions')}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setMenu(v => !v);
        }}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu referenceRef={menuRef} open={menu} onClose={() => setMenu(false)}>
        {lead.completed === false && me?.isAdmin && (
          <MenuItem
            onClick={() => {
              const assignStage = (root.pipelines[0].stages || []).find(
                stage => stage.name === 'assign',
              );
              updateLead({
                variables: {
                  input: {
                    lead: {
                      id: lead.id,
                      completed: true,
                      stageId: lead.stage?.id ?? assignStage?.id,
                    },
                  },
                },
              });
            }}
          >
            {t('markAsCompleted')}
          </MenuItem>
        )}

        {root.me?.isAdmin === true && (
          <MenuItem onClick={() => setDeleteDialog(true)}>
            {t('deleteLead')}
          </MenuItem>
        )}
      </Menu>
      {root.me?.isAdmin === true && (
        <LeadDeleteDialog
          lead={lead}
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onDelete={() => onDeleteHandler()}
        />
      )}
    </>
  );
};
