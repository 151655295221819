import { useState } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Skeleton, Stack } from '@mui/material';

import { useLocale } from '../../../src/hooks/locale';
import { Drawer } from '../../components/drawer/Drawer';
import { PropertyForm } from '../../components/property-form/PropertyForm';

import { STEP_PROPERTY_DETAILS_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActionsComponentWithoutForm,
  useUpdateCmaReport,
} from './shared';

const StepPropertyDetails = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const [open, setOpen] = useState(false);

  const { t } = useLocale();
  const [updateCmaReport] = useUpdateCmaReport(cmaReportId, 'page-property');

  const { data, complete } = useApolloFragment({
    fragment: STEP_PROPERTY_DETAILS_FRAGMENT,
    fragmentName: 'StepPropertyDetails',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  return (
    <>
      {data?.lead?.property != null && (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          title={t('Edit property')}
          sx={{ width: ['100vw', '70vw', '60vw', '50vw'], zIndex: 1300 }}
        >
          <PropertyForm
            propertyId={data.lead.property.id}
            onCancel={() => setOpen(false)}
            onSave={() => {
              setOpen(false);
              updateCmaReport({});
            }}
          />
        </Drawer>
      )}
      {!complete ? (
        <Stack gap={3}>
          <Stack gap={1}>
            <Skeleton variant="rounded" width={'30%'} height={20} />
            <Skeleton variant="rounded" width={'50%'} height={30} />
          </Stack>
        </Stack>
      ) : (
        <Stack height="100%" justifyContent="space-between">
          <Box p={2} height="100%">
            <Button
              fullWidth
              endIcon={<ChevronRight />}
              onClick={() => setOpen(true)}
              variant="contained"
            >
              {t('editPropertyDetails')}
            </Button>
          </Box>

          <FooterActionsComponentWithoutForm updating={false} {...props} />
        </Stack>
      )}
    </>
  );
};

export default StepPropertyDetails;
