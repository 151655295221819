import { type MutableRefObject, useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import { type PageId } from '../shared';

import ActionBox from './ActionBox';
import useCMATemplateRenderer from './useCMATemplateRenderer';
import useMarkupRenderer from './useMarkupRenderer';

type DesktopPreviewProps = {
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
  scrollToPageRef: MutableRefObject<(pageId: PageId) => void>;
};

const DesktopPreview = ({
  iFrameRef,
  scrollToPageRef,
}: DesktopPreviewProps) => {
  const { markup, dataReady, loading } = useCMATemplateRenderer();

  const { scrollToPage } = useMarkupRenderer(iFrameRef, markup);

  useEffect(() => {
    scrollToPageRef.current = scrollToPage;
  }, [scrollToPage, scrollToPageRef]);

  return (
    <>
      {(!dataReady || loading) && (
        <CircularProgress
          css={{
            position: 'absolute',
            top: 120,
            right: 64,
            zIndex: 1,
            opacity: dataReady ? 0.4 : 1,
          }}
          variant="indeterminate"
          disableShrink
        />
      )}
      <iframe
        ref={iFrameRef}
        css={{
          height: '100%',
          width: '100%',
          border: 0,
          filter: dataReady ? 'none' : 'brightness(0.8) grayscale(1)',
          transition: 'filter 1s ease',
          pointerEvents: dataReady ? 'initial' : 'none',
        }}
        sandbox="allow-same-origin allow-scripts allow-modals"
      />
      <ActionBox iFrameRef={iFrameRef} />
    </>
  );
};

export default DesktopPreview;
