import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { useLocale } from '../hooks/locale';

type Props = {
  open: boolean;
  onClaim: () => void;
  onClose: () => void;
};

export const LeadClaimDialog = ({
  open,
  onClaim,
  onClose,
}: Props): JSX.Element => {
  const { t } = useLocale();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('claimThisLeadTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('claimThisLeadMessage')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button color="primary" variant="contained" onClick={onClaim}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
