import { useMemo } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Alert, CircularProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import { getUUID } from '../../../shared/global-id';
import { type Translate, useLocale } from '../../../src/hooks/locale';
import type { GetUserSettingsQuery } from '../../__generated__/graphql';
import { Dictionaries_Types_Enum_Enum } from '../../__generated__/graphql';
import { type FormDefinitionType, RaForm } from '../../components/form/RaForm';
import type { IFormError } from '../../utils/parseError';

import { UserCatchmentArea } from './UserCatchmentArea';
import { GET_USER_SETTINGS, UPDATE_USER_SETTINGS } from './userQueries';

const UserFormDefinition: FormDefinitionType<Record<string, any>> = ({ t }) => [
  {
    name: 'user-details',
    label: t('User details'),
    type: 'category-title',
  },
  {
    name: 'first_name',
    label: t('First name'),
    type: 'text',
  },
  {
    name: 'last_name',
    label: t('Last name'),
    type: 'text',
  },
  {
    name: 'social_links',
    label: t('Social links'),
    type: 'category-title',
  },
  {
    name: 'facebook_profile',
    label: 'Facebook',
    type: 'text',
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'linked_in_profile',
    label: 'LinkedIn',
    type: 'text',
    gridProps: { xs: 12, md: 6 },
  },
  {
    name: 'agent-settings',
    label: t('Agent settings'),
    type: 'category-title',
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'agent_slug',
    label: t('Slug (agency pages)'),
    type: 'text',
    gridProps: { xs: 12, md: 6 },
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'description',
    label: t('Description'),
    type: 'text',
    required: false,
    multiline: true,
    render: ({ is_broker }) => is_broker,
    gridProps: { xs: 12 },
  },
  {
    name: 'services',
    label: t('services'),
    type: 'dictionary',
    dictionaryType: Dictionaries_Types_Enum_Enum.Services,
    multiple: true,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'languages',
    label: t('languages'),
    type: 'dictionary',
    dictionaryType: Dictionaries_Types_Enum_Enum.Languages,
    multiple: true,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'options',
    label: t('Options'),
    type: 'checkbox-group',
    checkboxes: [
      {
        name: 'show_in_agency_pages',
        label: t('Show in agency pages'),
        type: 'checkbox',
      },
      {
        name: 'has_free_crm_access',
        label: t('Free CRM access'),
        type: 'checkbox',
      },
    ],
    required: false,
    render: ({ is_broker }) => is_broker,
  },
  {
    name: 'meta',
    label: t('Metadata'),
    type: 'category-title',
  },
  {
    name: 'created_at',
    label: t('Created at'),
    type: 'date',
    disabled: () => true,
  },
  {
    name: 'created_by',
    label: t('Created by'),
    type: 'user',
    disabled: () => true,
  },
];

const extractMessagesFromError = (
  error: Error,
  _: any,
  t: Translate,
): IFormError<any>[] => {
  if (!(error instanceof ApolloError)) {
    return [];
  }

  const regex = new RegExp(/"users_show_in_agency_pages_agent_slug_check"$/);

  const isShowInAgencyPagesError = error.graphQLErrors.find(graphQLError => {
    const msg = graphQLError.message;

    // Message in the form: "Check constraint violation. new row for relation \"users\" violates check constraint \"users_show_in_agency_pages_agent_slug_check\""
    return msg.includes('check constraint') && regex.test(msg);
  });

  return isShowInAgencyPagesError
    ? [
        {
          field: 'agent_slug',
          errorMessage: t(
            'You cannot activate show in agency pages without a valid slug',
          ),
        },
      ]
    : [];
};

export const UserSettings = () => {
  const { t } = useLocale();
  const { userId } = useParams() as { userId: string };
  const id = getUUID(userId);

  const { data, loading, error } = useQuery<GetUserSettingsQuery>(
    GET_USER_SETTINGS,
    {
      variables: { id },
    },
  );

  const user = data?.users_by_pk;

  const defaultValues = useMemo(() => user ?? undefined, [user]);

  const [updateUser] = useMutation(UPDATE_USER_SETTINGS);

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <CircularProgress disableShrink />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (!user) {
    return <Alert severity="error">{t('User not found')}</Alert>;
  }

  const onSubmit = (formData: any) =>
    updateUser({
      variables: {
        id,
        user: {
          ...formData,
          services: Object.values(formData.services ?? {}),
          languages: Object.values(formData.languages ?? {}),
        },
      },
    });

  return (
    <>
      {user.is_broker && <UserCatchmentArea userId={id} />}
      <RaForm
        formDefinition={UserFormDefinition}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        customExtractMessagesFromError={extractMessagesFromError}
      />
    </>
  );
};
