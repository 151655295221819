import type { ReactNode } from 'react';

import { Box, useTheme } from '@mui/material';

export type IndicatorStatus = 'won' | 'lost' | 'active';

type IndicatorLeadStageProps = {
  status?: IndicatorStatus;
  size?: number;
  className?: string;
  children?: ReactNode;
};

export const IndicatorLeadStage = ({
  status,
  size = 8,
  className,
  children,
}: IndicatorLeadStageProps) => {
  const theme = useTheme();

  const getColor = () => {
    switch (status) {
      case 'won':
        return theme.palette.success.light;
      case 'lost':
        return theme.palette.error.light;
      default:
        return theme.palette.primary.light;
    }
  };

  const color = getColor();

  return (
    <Box
      className={className}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <span
        style={{
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
      {children}
    </Box>
  );
};

export default IndicatorLeadStage;
