import * as React from 'react';

import {
  Accordion,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
} from '@material-ui/core';
import {
  type RefetchFnDynamic,
  graphql,
  useMutation,
  useRefetchableFragment,
} from 'react-relay';

import { useCreateCMAReport } from '../../../apollo/pages/cma-reports/create-cma-report/utils';
import { fromGlobalId } from '../../../shared/global-id';
import { TimeAgo } from '../../components/TimeAgo';
import { Menu } from '../../controls/popup';
import { ProgressButton } from '../../controls/progress-button';
import { TemplateViewerDialog } from '../../controls/template-viewer-dialog';
import { useLocale } from '../../hooks/locale';
import { DeleteForever } from '../../icons/delete-forever';
import { Edit } from '../../icons/edit';
import { Eye } from '../../icons/eye';
import { FilePdf } from '../../icons/file-pdf';
import { MoreVert } from '../../icons/more-vert';
import { PlusCircleOutline } from '../../icons/plus-circle-outline';
import { AccordionCardSummary } from '../../shared/accordion-card-summary';

import type {
  leadCmaCard_lead$data,
  leadCmaCard_lead$key,
} from './__generated__/leadCmaCard_lead.graphql';
import type { leadCmaCardDeleteCmaReportMutation } from './__generated__/leadCmaCardDeleteCmaReportMutation.graphql';
import type { leadCmaCardRefetchQuery } from './__generated__/leadCmaCardRefetchQuery.graphql';

type Props = {
  lead: leadCmaCard_lead$key;
  defaultExpanded: boolean;
};

const CmaItem = ({
  cmaReport,
  onEdit,
  refetch,
}: {
  cmaReport: NonNullable<leadCmaCard_lead$data['cmaReports']>[number];
  onEdit: () => void;
  refetch: RefetchFnDynamic<leadCmaCardRefetchQuery, leadCmaCard_lead$key>;
}) => {
  const { t } = useLocale();
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const menuRef = React.useRef(null);
  const [deleteCmaReport, deleting] =
    useMutation<leadCmaCardDeleteCmaReportMutation>(graphql`
      mutation leadCmaCardDeleteCmaReportMutation(
        $input: DeleteCmaReportInput!
      ) {
        deleteCmaReport(input: $input) {
          deletedNodeId
        }
      }
    `);
  const handleDelete = () => {
    deleteCmaReport({
      variables: { input: { id: cmaReport.id } },
      onCompleted: () => {
        refetch({}, { fetchPolicy: 'network-only' });
      },
    });
  };

  return (
    <>
      <TemplateViewerDialog
        templateId={cmaReport.docTemplate?.id ?? ''}
        documentId={cmaReport.id}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>{t('areYouSureYouWantToDeleteCmaReport')}</DialogTitle>
        <DialogActions>
          <Button>{t('Cancel')}</Button>
          <ProgressButton
            autoFocus={true}
            component="button"
            loading={deleting}
            color="secondary"
            onClick={() => {
              deleteCmaReport({
                variables: { input: { id: cmaReport.id } },
              });
            }}
          >
            {t('delete')}
          </ProgressButton>
        </DialogActions>
      </Dialog>
      <ListItem button={true} onClick={() => setOpen(true)}>
        <ListItemIcon>
          <FilePdf />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {t('report')} {t('by')} {cmaReport.createdBy?.displayName}
            </>
          }
          secondary={<TimeAgo addSuffix={true}>{cmaReport.updatedAt}</TimeAgo>}
        />
        <ListItemSecondaryAction>
          <IconButton
            ref={menuRef}
            edge="end"
            title={t('actions')}
            onClick={() => setMenu(v => !v)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            referenceRef={menuRef}
            open={menu}
            onClose={() => setMenu(false)}
          >
            <MenuItem onClick={() => setOpen(true)}>
              <ListItemIcon>
                <Eye />
              </ListItemIcon>
              {t('view')}
            </MenuItem>
            <MenuItem onClick={onEdit} divider={true}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              {t('edit')}
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteForever />
              </ListItemIcon>
              {t('delete')}
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export const LeadCmaCard = (props: Props) => {
  const { t } = useLocale();
  const createCMAReport = useCreateCMAReport();

  const [lead, refetch] = useRefetchableFragment<
    leadCmaCardRefetchQuery,
    leadCmaCard_lead$key
  >(
    graphql`
      fragment leadCmaCard_lead on Lead
      @refetchable(queryName: "leadCmaCardRefetchQuery") {
        id
        property {
          appraisable
        }
        contact {
          ...userInput_user @relay(mask: false)
        }
        cmaReports {
          id
          updatedAt
          createdBy {
            id
            displayName
          }
          docTemplate {
            id
          }
        }
      }
    `,
    props.lead,
  );

  const [expanded, setExpanded] = React.useState(true);

  if (!lead.property?.appraisable) {
    return null;
  }

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={(_event, expanded) => {
          setExpanded(expanded);
        }}
      >
        <AccordionCardSummary expanded={expanded} title={t('cmaReports')} />
        <React.Suspense fallback={null}>
          <List>
            {lead.cmaReports?.map(cmaReport => (
              <CmaItem
                cmaReport={cmaReport}
                key={cmaReport.id}
                onEdit={() => {
                  window.open(
                    `/cma-reports/${fromGlobalId(cmaReport.id)}/page-cover`,
                    '_blank',
                  );
                }}
                refetch={refetch}
              />
            ))}
          </List>
          <CardContent>
            <Button
              variant={lead.cmaReports?.length !== 0 ? 'outlined' : 'contained'}
              color="primary"
              startIcon={<PlusCircleOutline />}
              onClick={async () => {
                const newCmaReportId = await createCMAReport({
                  leadId: fromGlobalId(lead.id),
                  contactId: lead.contact?.id
                    ? fromGlobalId(lead.contact.id)
                    : undefined,
                });
                refetch({}, { fetchPolicy: 'network-only' });
                window.open(
                  `/cma-reports/${newCmaReportId}/page-cover`,
                  '_blank',
                );
              }}
            >
              {t('createCmaReport')}
            </Button>
          </CardContent>
        </React.Suspense>
      </Accordion>
    </>
  );
};
