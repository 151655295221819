/**
 * @generated SignedSource<<d20b61a83be493d1e374061e992c131d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type activityWorkflowDialogQuery$variables = {
  pipelineName: string;
};
export type activityWorkflowDialogQuery$data = {
  readonly lostStages: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly labelEN: string | null;
  }> | null;
  readonly wonStages: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
  }> | null;
};
export type activityWorkflowDialogQuery = {
  response: activityWorkflowDialogQuery$data;
  variables: activityWorkflowDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pipelineName"
  }
],
v1 = {
  "kind": "Variable",
  "name": "pipelineName",
  "variableName": "pipelineName"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = [
  {
    "alias": "lostStages",
    "args": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "status",
        "value": "lost"
      }
    ],
    "concreteType": "LeadStage",
    "kind": "LinkedField",
    "name": "stages",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labelEN",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "wonStages",
    "args": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "status",
        "value": "won"
      }
    ],
    "concreteType": "LeadStage",
    "kind": "LinkedField",
    "name": "stages",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "activityWorkflowDialogQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "activityWorkflowDialogQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "bb402b5791b2dca34a9bd8ca9e414195",
    "id": null,
    "metadata": {},
    "name": "activityWorkflowDialogQuery",
    "operationKind": "query",
    "text": "query activityWorkflowDialogQuery(\n  $pipelineName: String!\n) {\n  lostStages: stages(pipelineName: $pipelineName, status: \"lost\") {\n    id\n    label\n    labelEN\n  }\n  wonStages: stages(pipelineName: $pipelineName, status: \"won\") {\n    id\n    label\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fbc206467e2dae167d69a4f2182530b";

export default node;
