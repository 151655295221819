import { useMemo } from 'react';

import { Box, Fade, Menu } from '@mui/material';
import { keyframes } from '@mui/system';

import { useCMAEditor } from './CMAReportEditorWorkflow';
import StepperListItem from './StepperListItem';

const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MENU_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center',
} as const;

const MENU_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'center',
} as const;

type StepperMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  handleStepSelect: (index: number) => void;
};

const StepperMenu = ({
  anchorEl,
  onClose,
  handleStepSelect,
}: StepperMenuProps) => {
  const { steps, currentStepIndex } = useCMAEditor();
  const currentStepData = steps[currentStepIndex];

  const { mainSteps, footerSteps } = useMemo(() => {
    const mainSteps = steps.filter(step => step.position === 'main');
    const footerSteps = steps.filter(step => step.position === 'footer');
    return { mainSteps, footerSteps };
  }, [steps]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={MENU_ANCHOR_ORIGIN}
      transformOrigin={MENU_TRANSFORM_ORIGIN}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      sx={{
        '& .MuiMenu-paper': {
          width: 300,
          animation: `${slideDown} 0.3s ease-out`,
          transformOrigin: 'top',
          mt: 1.5,
        },
      }}
    >
      <Box sx={{ overflow: 'auto', height: '50dvh', flexGrow: 1 }}>
        {mainSteps.map((item, index) => (
          <StepperListItem
            key={item.pageId}
            item={item}
            index={index}
            isActive={currentStepData.pageId === item.pageId}
            handleStepSelect={handleStepSelect}
          />
        ))}
      </Box>
      <Box>
        {footerSteps.map((item, index) => (
          <StepperListItem
            key={index}
            item={item}
            index={mainSteps.length + index}
            isActive={currentStepData.pageId === item.pageId}
            handleStepSelect={handleStepSelect}
          />
        ))}
      </Box>
    </Menu>
  );
};

export default StepperMenu;
