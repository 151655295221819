import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { useLocale } from '../../../src/hooks/locale';

interface RaArrayInputProps {
  name: string;
  label: string;
  required?: boolean;
}

export const RaArrayInput = ({ name, label, required }: RaArrayInputProps) => {
  const { control } = useFormContext();
  const { t } = useLocale();

  const rules = {
    required: required ? t('This field is required') : undefined,
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error}>
          <FormLabel style={{ marginBottom: '0.5rem', fontWeight: 500 }}>
            {label}
          </FormLabel>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            options={[]}
            value={value || []}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                error={!!error}
                variant="outlined"
                size="small"
                sx={{
                  bgcolor: 'background.paper',
                }}
              />
            )}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
