import * as React from 'react';

import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { graphql, useFragment, useMutation } from 'react-relay';

import { useLocale } from '../../hooks/locale';
import { UserAvatar } from '../../shared/user-avatar';

import type { FeedDeleteDialog_activity$key } from './__generated__/FeedDeleteDialog_activity.graphql';
import type { FeedDeleteDialogMutation } from './__generated__/FeedDeleteDialogMutation.graphql';

type Props = {
  activity: FeedDeleteDialog_activity$key;
  title: React.ReactNode;
  createdAt: null | string;
  open: boolean;
  invisible: null | boolean;
  onClose: () => void;
  onDelete: () => void;
  allowNotifyParticipants?: boolean;
};

export const FeedDeleteDialog = (props: Props) => {
  const { t, dateLocale } = useLocale();
  const [deleteFeedItem] = useMutation<FeedDeleteDialogMutation>(
    graphql`
      mutation FeedDeleteDialogMutation($input: DeleteActivityInput!) {
        deleteActivity(input: $input) {
          deletedActivityId
        }
      }
    `,
  );

  const [notifyParticipants, setNotifyParticipants] = React.useState(true);
  const activity = useFragment(
    graphql`
      fragment FeedDeleteDialog_activity on Activity {
        id
        createdBy {
          ...userAvatar_user
          id
        }
      }
    `,
    props.activity,
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      BackdropProps={{ invisible: props.invisible ?? false }}
    >
      <DialogTitle>{t('areYouSureToDeleteActivity')}</DialogTitle>
      <CardHeader
        avatar={<UserAvatar user={activity.createdBy} />}
        title={props.title}
        subheader={
          props.createdAt != null &&
          formatDistance(new Date(props.createdAt), Date.now(), {
            locale: dateLocale,
          })
        }
      />
      <DialogActions>
        {props.allowNotifyParticipants === true && (
          <FormControlLabel
            label={t('Notify participants')}
            control={
              <Switch
                checked={notifyParticipants}
                onChange={e => {
                  setNotifyParticipants(e.target.checked);
                }}
              />
            }
          />
        )}
        <Button onClick={props.onClose}>{t('Cancel')}</Button>
        <Button
          color="primary"
          onClick={() => {
            deleteFeedItem({
              variables: {
                input: { id: activity.id, notifyParticipants },
              },
              onCompleted: props.onDelete,
            });
          }}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
