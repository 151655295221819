// @flow

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Popover,
  TextField,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Form } from '@realadvisor/form';
import { EditorState, RichUtils } from 'draft-js';
import { Box, Flex, useResponsive } from 'react-system';

import { useLocale } from '../hooks/locale';
import { useTheme } from '../hooks/theme';
import { FormatBold } from '../icons/format-bold';
import { FormatItalic } from '../icons/format-italic';
import { FormatListBulleted } from '../icons/format-list-bulleted';
import { FormatListNumbered } from '../icons/format-list-numbered';
import { Link } from '../icons/link';
import { LinkOff } from '../icons/link-off';

import { createLink, getCurrentLinkUrl, removeLink } from './editor-link';

type ToolbarProps = {|
  focusEditor: (callback?: () => void) => void,
  editorState: EditorState,
  onChange: EditorState => void,
  children?: React.Node,
  postprocessLink?: (url: string) => string,
  cleanupLink?: (url: string) => string,
|};

type ToolbarDialogProps = {|
  anchorEl: Element | null,
  open: boolean,
  onClose: () => void,
  children: React.Node,
|};

export const ToolbarDialog = ({
  anchorEl,
  open,
  onClose,
  children,
}: ToolbarDialogProps): React.Node => {
  const responsive = useResponsive();
  return responsive([
    <Dialog
      key="mobile"
      css={{
        '.MuiDialog-paper': {
          marginLeft: '18px !important',
          marginRight: '18px !important',
        },
      }}
      // focus restore should use internal draft method to preserve cursor position
      disableRestoreFocus={true}
      open={open}
      onClose={onClose}
    >
      {children}
    </Dialog>,
    <Popover
      key="desktop"
      disableRestoreFocus={true}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <Paper style={{ overflow: 'auto', minWidth: 300 }}>{children}</Paper>
    </Popover>,
  ]);
};

const LinkDialogContent = ({ initialUrl, onSubmit, onClose }) => {
  const { t } = useLocale();
  const [urlValue, setUrlValue] = React.useState(initialUrl);
  const [urlTitle, setUrlTitle] = React.useState('');
  return (
    <Form onSubmit={() => onSubmit(urlValue, urlTitle)}>
      <DialogTitle>{t('insertLink')}</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Title"
          autoFocus={true}
          value={urlTitle}
          onChange={event => setUrlTitle(event.target.value)}
          margin="normal"
        />
        <TextField
          variant="filled"
          label="URL"
          autoFocus={true}
          value={urlValue}
          onChange={event => setUrlValue(event.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        {/* ripple effect seems to mess with events chain causing the link to not be inserted */}
        <Button type="submit" component="button" disableRipple={true}>
          {t('save')}
        </Button>
      </DialogActions>
    </Form>
  );
};

export const EditorToolbar = ({
  focusEditor,
  editorState,
  onChange,
  children,
  postprocessLink,
  cleanupLink,
}: ToolbarProps): React.Node => {
  const { colors } = useTheme();
  const currentStyle = editorState.getCurrentInlineStyle();
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);
  const [linkAnchorEl, setLinkAnchorEl] = React.useState(null);
  const currentUrl = getCurrentLinkUrl(editorState);

  return (
    <Box
      css={{ overflowX: 'auto', borderBottom: `1px solid ${colors.divider}` }}
    >
      <Flex>
        <ToggleButton
          tabIndex={-1}
          value="BOLD"
          selected={currentStyle.has('BOLD')}
          onMouseDown={event => {
            event.preventDefault();
            onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
          }}
        >
          <FormatBold />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          value="ITALIC"
          selected={currentStyle.has('ITALIC')}
          onMouseDown={event => {
            event.preventDefault();
            onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
          }}
        >
          <FormatItalic />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          value="ORDERED_LIST"
          selected={'ordered-list-item' === currentBlockType}
          onMouseDown={event => {
            event.preventDefault();
            onChange(
              RichUtils.toggleBlockType(editorState, 'ordered-list-item'),
            );
          }}
        >
          <FormatListNumbered />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          value="BULLER_LIST"
          selected={'unordered-list-item' === currentBlockType}
          onMouseDown={event => {
            event.preventDefault();
            onChange(
              RichUtils.toggleBlockType(editorState, 'unordered-list-item'),
            );
          }}
        >
          <FormatListBulleted />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          value="LINK"
          selected={currentUrl.length !== 0}
          onMouseDown={event => {
            event.preventDefault();
            setLinkAnchorEl(event.currentTarget);
          }}
        >
          <Link />
        </ToggleButton>

        <ToggleButton
          tabIndex={-1}
          value="LINK"
          selected={false}
          onMouseDown={event => {
            event.preventDefault();
            onChange(removeLink(editorState));
          }}
        >
          <LinkOff />
        </ToggleButton>

        <ToolbarDialog
          anchorEl={linkAnchorEl}
          open={linkAnchorEl != null}
          onClose={() => {
            setLinkAnchorEl(null);
            focusEditor();
          }}
        >
          <LinkDialogContent
            initialUrl={
              cleanupLink == null ? currentUrl : cleanupLink(currentUrl)
            }
            onSubmit={(urlValue, urlTitle) => {
              setLinkAnchorEl(null);
              focusEditor(() => {
                onChange(
                  urlValue.trim().length === 0
                    ? removeLink(editorState)
                    : createLink(
                        editorState,
                        urlValue,
                        postprocessLink,
                        urlTitle,
                      ),
                );
              });
            }}
            onClose={() => {
              setLinkAnchorEl(null);
              focusEditor();
            }}
          />
        </ToolbarDialog>

        {children ?? null}
      </Flex>
    </Box>
  );
};
