import { useCallback, useMemo } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Box, Skeleton, Stack } from '@mui/material';

import { GOOGLE_MAPS_TOKEN } from '../../../../src/config';
import { useDebouncedHandler } from '../../../../src/hooks/debounce';
import { useMap } from '../../../../src/hooks/map';
import { type StepCoverPhotoFragment } from '../../../__generated__/graphql';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../../components/form/RaForm';
import { STEP_COVER_PHOTO_FRAGMENT } from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from '../shared';

import CoverPhotoSelector from './CoverPhotoSelector';

const getSatelliteImageUrl = (lat: number, lng: number) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=16&size=400x200&maptype=satellite&markers=color:red|${lat},${lng}&style=feature:poi|element:labels|visibility:off&scale=2&key=${GOOGLE_MAPS_TOKEN}&channel=crm`;
};

type StepCoverForm = {
  report_title: string;
  broker_id: string;
  contact_id: string;
};

const StepCoverPhoto = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const localImages = useMap<string>();

  const { complete, data } = useApolloFragment({
    fragment: STEP_COVER_PHOTO_FRAGMENT,
    fragmentName: 'StepCoverPhoto',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-cover',
  );

  const update = useCallback(
    async (formData?: Partial<StepCoverForm>) => {
      await updateCmaReport({
        report_title: formData?.report_title,
        broker_id: formData?.broker_id,
        contact_id: formData?.contact_id,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepCoverForm> = useCallback(
    formData => {
      debouncedUpdate(formData);
    },
    [debouncedUpdate],
  );

  const satelliteImageUrl = useMemo(
    () =>
      getSatelliteImageUrl(
        data?.lead?.property?.lat ?? 0,
        data?.lead?.property?.lng ?? 0,
      ),
    [data?.lead?.property?.lat, data?.lead?.property?.lng],
  );

  const cmaStepCoverFormDefinition: FormDefinitionType<StepCoverForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'report_title',
          label: t('Report title'),
          type: 'text',
          gridProps: { md: 12 },
        },
        {
          name: 'contact_id',
          label: t('Report prepared for'),
          type: 'user',
          gridProps: { md: 12 },
        },
        {
          name: 'broker_id',
          label: t('Report created by'),
          type: 'user',
          gridProps: { sm: 12, md: 12 },
        },
        {
          type: 'custom',
          name: 'cover',
          element: (
            <CoverPhotoSelector
              satelliteImageUrl={satelliteImageUrl}
              data={data as StepCoverPhotoFragment}
              updateCmaReport={updateCmaReport}
              localImages={localImages}
            />
          ),
          gridProps: { md: 12 },
        },
      ],
      [data, localImages, satelliteImageUrl, updateCmaReport],
    );

  const onSubmit = useCallback(
    async (formData: StepCoverForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  if (!complete) {
    return (
      <Box sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto', p: 2 }}>
        <Stack gap={3}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Stack gap={1} key={`step-cover-skeleton-${index}`}>
              <Skeleton variant="rounded" height={20} width="30%" />
              <Skeleton variant="rounded" height={40} />
            </Stack>
          ))}
          <Skeleton variant="rectangular" height={200} />
        </Stack>
      </Box>
    );
  }

  return (
    <RaForm
      freezeInitialDefaultValues={true}
      formDefinition={cmaStepCoverFormDefinition}
      onSubmit={onSubmit}
      defaultValues={{
        report_title: data?.report_title ?? '',
        broker_id: data.broker?.id,
        contact_id: data.contact?.id,
      }}
      onChange={onChangeHandler}
      contentScrollable
      actionButtonsComponent={
        <FooterActions {...props} hideBackButton updating={updating} />
      }
    />
  );
};

export default StepCoverPhoto;
