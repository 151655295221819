import { type MutableRefObject, useCallback, useEffect, useState } from 'react';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import type { PageId } from '../shared';

import { useCMAEditor } from './CMAReportEditorWorkflow';
import InteractiveStepContainer from './InteractiveStepContainer';
import MobileStepperHeader from './MobileStepperHeader';
import StepperMenu from './StepperMenu';

type CMAReportEditorStepperComponentProps = {
  iFrameRef: MutableRefObject<HTMLIFrameElement | null>;
  handleScrollToPage: (pageId: PageId, index: number) => void;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
};

const CMAReportEditorStepperComponent = ({
  iFrameRef,
  handleScrollToPage,
  isExpanded,
  setIsExpanded,
}: CMAReportEditorStepperComponentProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isDesktop = useMediaQuery(breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    steps,
    currentStepIndex,
    currentStepData,
    setCurrentStepByIndex,
    cmaReport,
  } = useCMAEditor();

  const handlePreviousStep = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepByIndex(currentStepIndex - 1);
    }
  }, [currentStepIndex, setCurrentStepByIndex]);

  const handleNextStep = useCallback(() => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepByIndex(currentStepIndex + 1);
    }
  }, [currentStepIndex, setCurrentStepByIndex, steps.length]);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleStepSelect = useCallback(
    (index: number) => {
      setCurrentStepByIndex(index);
      handleMenuClose();
    },
    [handleMenuClose, setCurrentStepByIndex],
  );

  const handleInteraction = useCallback(() => {
    if (currentStepData.pageId) {
      handleScrollToPage(currentStepData.pageId, currentStepIndex);
    }
  }, [currentStepData.pageId, currentStepIndex, handleScrollToPage]);

  useEffect(() => {
    if (isTablet && isExpanded === false) {
      setIsExpanded(true);
    }
  }, [isTablet, isExpanded, setIsExpanded]);

  return (
    <InteractiveStepContainer
      isExpanded={isExpanded}
      onInteraction={handleInteraction}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          py: 1,
          px: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          justifyContent: 'space-between',
          height: '57px',
        }}
      >
        {isMobile ? (
          <MobileStepperHeader
            handleMenuClick={handleMenuClick}
            onPreviousStep={handlePreviousStep}
            onNextStep={handleNextStep}
          />
        ) : (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                height: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {currentStepData.title}
            </Typography>
            {isDesktop && (
              <IconButton
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{
                  ml: 'auto',
                }}
              >
                {isExpanded ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            )}
          </>
        )}
      </Box>

      <StepperMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        handleStepSelect={handleStepSelect}
      />

      {currentStepData.Component && (
        <Box
          data-step-container
          sx={{
            flexGrow: 1,
            position: 'relative',
            overflowY: 'auto',
            height: 0,
          }}
        >
          {'active' in currentStepData && currentStepData.active === false && (
            <Box
              sx={{
                position: 'absolute',
                inset: 0,
                backgroundColor: 'grey.200',
                opacity: 0.7,
                zIndex: 10,
              }}
            />
          )}

          <currentStepData.Component
            cmaReportId={cmaReport.id}
            iFrameRef={iFrameRef as MutableRefObject<HTMLIFrameElement>}
            setStep={setCurrentStepByIndex}
            step={currentStepIndex}
          />
        </Box>
      )}
    </InteractiveStepContainer>
  );
};

export default CMAReportEditorStepperComponent;
