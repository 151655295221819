/**
 * @generated SignedSource<<044b243fc249dc07dad53547a1ad79b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type financingWonDialogQuery$variables = {};
export type financingWonDialogQuery$data = {
  readonly wonStages: ReadonlyArray<{
    readonly id: string;
  }> | null;
};
export type financingWonDialogQuery = {
  response: financingWonDialogQuery$data;
  variables: financingWonDialogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "wonStages",
    "args": [
      {
        "kind": "Literal",
        "name": "pipelineName",
        "value": "financing"
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": "won"
      }
    ],
    "concreteType": "LeadStage",
    "kind": "LinkedField",
    "name": "stages",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": "stages(pipelineName:\"financing\",status:\"won\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "financingWonDialogQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "financingWonDialogQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "de0203bd4af27fa14471595a30839fd8",
    "id": null,
    "metadata": {},
    "name": "financingWonDialogQuery",
    "operationKind": "query",
    "text": "query financingWonDialogQuery {\n  wonStages: stages(pipelineName: \"financing\", status: \"won\") {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "45e17fc99685cc853d49200a32badffb";

export default node;
