/**
 * @generated SignedSource<<b5eea79af65ab97507a78935c799c3af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type leadControls_lead$data = {
  readonly completed: boolean;
  readonly id: string;
  readonly stage: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"leadDeleteDialog_lead">;
  readonly " $fragmentType": "leadControls_lead";
};
export type leadControls_lead$key = {
  readonly " $data"?: leadControls_lead$data;
  readonly " $fragmentSpreads": FragmentRefs<"leadControls_lead">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "leadControls_lead",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "leadDeleteDialog_lead"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadStage",
      "kind": "LinkedField",
      "name": "stage",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Lead",
  "abstractKey": null
};
})();

(node as any).hash = "3bda1bf469dea0c50270ce91ceaa6472";

export default node;
