import { useCallback, useMemo } from 'react';

import { useFragment as useApolloFragment, useQuery } from '@apollo/client';
import { Box, Skeleton, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';

import ResponsiveActionsLayout from './CMAReportEditor/ResponsiveActionsLayout';
import {
  DOC_TEMPLATES_CMA_REPORT,
  STEP_SETTINGS_FRAGMENT,
} from './cmaReportsQueries';
import { type CMAReportComponentProps, useUpdateCmaReport } from './shared';

type StepSettingsForm = {
  language: string;
  doc_template_id: string;
  font_family: string;
};

const StepSettings = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useLocale();

  const { data: docTemplatesData, loading: docTemplatesLoading } = useQuery(
    DOC_TEMPLATES_CMA_REPORT,
  );

  const { complete, data } = useApolloFragment({
    fragment: STEP_SETTINGS_FRAGMENT,
    fragmentName: 'StepSettings',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const [updateCmaReport] = useUpdateCmaReport(cmaReportId, 'page-settings');

  const update = useCallback(
    async (formData?: Partial<StepSettingsForm>) => {
      await updateCmaReport({
        doc_template_id: formData?.doc_template_id,
        language: formData?.language,
        font_family:
          formData?.font_family === 'default' ? null : formData?.font_family,
      });
    },
    [updateCmaReport],
  );

  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepSettingsForm> = useCallback(
    (formData, name) => {
      if (name === 'language' && formData?.language) {
        searchParams.set('language', formData.language);
        setSearchParams(searchParams);
      }

      debouncedUpdate(formData);
    },
    [debouncedUpdate, searchParams, setSearchParams],
  );

  const languageItems = useMemo(
    () => [
      { value: 'en', label: t('english') },
      { value: 'fr', label: t('french') },
      { value: 'it', label: t('italian') },
      { value: 'es', label: t('spanish') },
      { value: 'de', label: t('german') },
    ],
    [t],
  );

  const cmaStepSettingsFormDefinition: FormDefinitionType<StepSettingsForm> =
    useCallback(
      ({ t }) => [
        {
          name: 'language',
          type: 'select',
          label: t('Language'),
          gridProps: { md: 12 },
          options: () => languageItems,
        },
        {
          name: 'doc_template_id',
          type: 'select',
          label: t('Template'),
          options: () => {
            const filteredDocTemplates = docTemplatesData?.doc_templates
              .filter(template => template.active)
              .map(template => ({
                value: template.id,
                label: template.name,
              }));
            return filteredDocTemplates ?? [];
          },
          gridProps: { md: 12 },
          render: () => !docTemplatesLoading,
        },
        {
          name: 'font_family',
          gridProps: { md: 12 },
          type: 'select',
          label: t('Font'),
          options: () => [
            { value: 'default', label: 'Default' },
            { value: 'arial', label: 'Arial' },
            { value: 'helvetica', label: 'Helvetica' },
            { value: 'helvetica-neue', label: 'Helvetica Neue' },
            { value: 'times-new-roman', label: 'Times New Roman' },
            { value: 'georgia', label: 'Georgia' },
            { value: 'verdana', label: 'Verdana' },
            { value: 'calibri', label: 'Calibri' },
            { value: 'open-sans', label: 'Open Sans' },
            { value: 'roboto', label: 'Roboto' },
            { value: 'lato', label: 'Lato' },
            { value: 'montserrat', label: 'Montserrat' },
            { value: 'merriweather', label: 'Merriweather' },
            { value: 'garamond', label: 'Garamond' },
            { value: 'futura', label: 'Futura' },
          ],
        },
      ],
      [docTemplatesData?.doc_templates, docTemplatesLoading, languageItems],
    );

  if (!complete) {
    return (
      <Box sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto', p: 2 }}>
        <Stack gap={3}>
          <Stack gap={1}>
            <Skeleton variant="rounded" height={20} width="30%" />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
          </Stack>
          <Stack gap={1}>
            <Skeleton variant="rounded" height={20} width="30%" />
            <Skeleton variant="rounded" height={40} />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <RaForm
      freezeInitialDefaultValues={true}
      formDefinition={cmaStepSettingsFormDefinition}
      onSubmit={() => Promise.resolve()}
      defaultValues={{
        doc_template_id: data?.doc_template?.id,
        language: data?.language,
        font_family: data?.font_family ?? 'default',
      }}
      onChange={onChangeHandler}
      contentScrollable
      actionButtonsComponent={
        <ResponsiveActionsLayout>
          <></>
        </ResponsiveActionsLayout>
      }
    />
  );
};

export default StepSettings;
