import { useMemo } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, IconButton } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';

import { useCMAEditor } from './CMAReportEditorWorkflow';

type MobileStepperHeaderProps = {
  handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
};

const MobileStepperHeader = ({
  handleMenuClick,
  onPreviousStep,
  onNextStep,
}: MobileStepperHeaderProps) => {
  const { steps, currentStepIndex, currentStepData } = useCMAEditor();
  const { t } = useLocale();

  const isPageSettingsStep = useMemo(
    () => currentStepData.pageId === 'page-settings',
    [currentStepData.pageId],
  );

  const isFirstStep = useMemo(() => currentStepIndex === 0, [currentStepIndex]);
  const isLastStep = useMemo(
    () => currentStepIndex === steps.length - 3,
    [currentStepIndex, steps.length],
  );

  return (
    <>
      {!isPageSettingsStep ? (
        <IconButton onClick={onPreviousStep} disabled={isFirstStep}>
          <ChevronLeft />
        </IconButton>
      ) : (
        <Box />
      )}
      <Button
        variant="text"
        onClick={handleMenuClick}
        sx={{
          color: 'black',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {currentStepData.title}
        <ArrowDropDown />
        {'active' in currentStepData && !currentStepData.active && (
          <span style={{ fontSize: '0.75rem', opacity: 0.5 }}>
            ({t('HIDDEN')})
          </span>
        )}
      </Button>
      {!isPageSettingsStep ? (
        <IconButton onClick={onNextStep} disabled={isLastStep}>
          <ChevronRight />
        </IconButton>
      ) : (
        <Box />
      )}
    </>
  );
};

export default MobileStepperHeader;
