import { useCallback, useState } from 'react';

import { useFragment as useApolloFragment, useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Button, Skeleton, Stack } from '@mui/material';

import { useLocale } from '../../../../src/hooks/locale';
import type { GetPropertyAppraisableQuery } from '../../../__generated__/graphql';
import AlertPropertyAppraisable from '../../../components/AlertPropertyAppraisable';
import { Drawer } from '../../../components/drawer/Drawer';
import { PropertyForm } from '../../../components/property-form/PropertyForm';
import {
  GET_PROPERTY_APPRAISABLE,
  STEP_HEDONISTIC_VALUATION_FRAGMENT,
} from '../cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActionsComponentWithoutForm,
  useUpdateCmaReport,
} from '../shared';

import AppraisalSection from './AppraisalSection';

export type AppraisalResult = NonNullable<
  GetPropertyAppraisableQuery['property_appraisable']
>['appraisable'];

const StepHedonisticValuation = (props: CMAReportComponentProps) => {
  const { t } = useLocale();
  const [open, setOpen] = useState(false);
  const { cmaReportId } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_HEDONISTIC_VALUATION_FRAGMENT,
    fragmentName: 'StepHedonisticValuation',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });

  const latestAppraisal = data?.lead?.property?.latest_appraisal;

  const { data: propertyAppraisable, loading: appraisalLoading } = useQuery(
    GET_PROPERTY_APPRAISABLE,
    {
      variables: {
        propertyId: data?.lead?.property?.id ?? '',
      },
      skip: data?.lead?.property?.id == null,
    },
  );
  const appraisable = propertyAppraisable?.property_appraisable?.appraisable;

  const [updateCmaReport] = useUpdateCmaReport(
    cmaReportId,
    'page-hedonistic-valuation',
  );

  const handleAppraiseProperty = useCallback(async () => {
    updateCmaReport({});
  }, [updateCmaReport]);

  return (
    <>
      <Drawer
        title={t('editProperty')}
        open={open}
        PaperProps={{ sx: { maxWidth: 1200 } }}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 1300 }}
      >
        <PropertyForm
          propertyId={data?.lead?.property?.id}
          onCancel={() => setOpen(false)}
          onSave={() => {
            setOpen(false);
            updateCmaReport({});
          }}
        />
      </Drawer>
      {!complete ? (
        <Stack gap={3} p={2}>
          <Stack gap={1}>
            <Skeleton variant="rounded" width={100} height={24} />
            <Skeleton variant="rounded" width={160} height={24} />
          </Stack>
          <Stack gap={1}>
            <Skeleton variant="rounded" height={24} width={150} />
            <Skeleton variant="rounded" height={24} width={140} />
            <Skeleton variant="rounded" height={36} width={200} />
          </Stack>
        </Stack>
      ) : (
        <Stack height="100%" justifyContent="space-between">
          <Stack p={2} gap={2} height="100%">
            <Button
              fullWidth
              endIcon={<ChevronRight />}
              onClick={() => setOpen(true)}
              variant="contained"
            >
              {t('editPropertyDetails')}
            </Button>

            <AppraisalSection
              propertyId={data?.lead?.property?.id}
              latestAppraisalId={latestAppraisal?.id}
              appraisalsLength={data?.lead?.property?.appraisals?.length ?? 0}
              appraisalLoading={appraisalLoading}
              appraisable={appraisable}
              onAppraise={handleAppraiseProperty}
            />

            {appraisable?.result === false && (
              <AlertPropertyAppraisable errors={appraisable.errors} />
            )}
          </Stack>

          <FooterActionsComponentWithoutForm updating={false} {...props} />
        </Stack>
      )}
    </>
  );
};

export default StepHedonisticValuation;
