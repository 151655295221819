import * as React from 'react';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import { Form, type FormikHook } from '@realadvisor/form';
import {
  addDays,
  addHours,
  addMonths,
  differenceInDays,
  isBefore,
  isSameDay,
  roundToNearestMinutes,
  subDays,
} from 'date-fns';
import { Box, Flex, useResponsive, useSystem } from 'react-system';

import { MarkdownComposer } from '../../../shared/composer';
import { CollapsableDialog } from '../../controls/collapsable-dialog';
import { formatDate } from '../../controls/date-input';
import { ProgressButton } from '../../controls/progress-button';
import { Radio } from '../../controls/radio';
import { useLocale } from '../../hooks/locale';
import { useTheme } from '../../hooks/theme';
import { AccessTime } from '../../icons/access-time';
import { ArrowLeftOutline } from '../../icons/arrow-left-outline';
import { AssignmentOutline } from '../../icons/assignment-outline';
import { Lock } from '../../icons/lock';
import { LockOpen } from '../../icons/lock-open';
import { MapMarkerOutline } from '../../icons/map-marker-outline';
import { Notes } from '../../icons/notes';
import { PeopleOutline } from '../../icons/people-outline';
import { PhoneOutline } from '../../icons/phone-outline';
import { Today } from '../../icons/today';
import type { User } from '../../shared/user-input';

import { DialogWarning } from './dialog-warning';
import {
  FieldAssignedTo,
  FieldContacts,
  FieldCreatedBy,
  FieldLocation,
  FieldParent,
  FieldVisibility,
  InlineDateTime,
  useNow,
} from './Fields';
import {
  TimeRangePicker,
  activityOfTimeRange,
  timeRangeOfActivity,
} from './time-range-picker';
import { getDefaultSubject as getDefaultSubjectUtil } from './utils';

type Props = {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  disableDialogCollapse?: boolean;
  showUnfinishedWarning: boolean;
  // who is bad boy, who is bad boy
  form: FormikHook<any>;
  nylasEmail: null | string;
  isAdmin: boolean;
  isWorkflow: boolean;
  isCreating: boolean;
  activityType: 'visit' | 'call' | 'task';
  allowNotifyParticipants: boolean;
  canEditParent?: boolean;
  canEditVisibility: boolean;
  isUpserting: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onGoBack?: () => void;
};

const DateTimePreset = ({
  values,
  setValues,
}: {
  values: Props['form']['values'];
  setValues: Props['form']['setValues'];
}) => {
  const { t } = useLocale();
  const now = useNow();
  const timePresets = [
    { key: t('tomorrow'), date: addDays(now, 1) },
    { key: t('in2Days'), date: addDays(now, 2) },
    { key: t('in1Week'), date: addDays(now, 7) },
    { key: t('in1Month'), date: addMonths(now, 1) },
    { key: t('in3Months'), date: addMonths(now, 3) },
    { key: t('in6Months'), date: addMonths(now, 6) },
  ].map(preset => ({
    ...preset,
    date: roundToNearestMinutes(preset.date, { nearestTo: 15 }),
  }));

  return (
    <Flex flexWrap="wrap" ml={-3} pt={1}>
      {timePresets.map(preset => (
        <Box key={preset.key} pl={3}>
          <Radio
            color="primary"
            checked={values.startDate === formatDate(preset.date)}
            size="small"
            onChange={() =>
              setValues({
                startDate: formatDate(preset.date),
              })
            }
          >
            {preset.key}
          </Radio>
        </Box>
      ))}
    </Flex>
  );
};

const Subtitle = ({ children }: { children: React.ReactNode }) => {
  const { text } = useTheme();
  return (
    <Box pb={2} css={[text.subtitle2]}>
      {children}
    </Box>
  );
};

const NotifyParticipantsToggle = ({
  notifyParticipants,
  setValues,
}: {
  notifyParticipants: boolean;
  setValues: Props['form']['setValues'];
}) => {
  const { t } = useLocale();
  return (
    <FormControlLabel
      label={t('Notify participants')}
      control={
        <Switch
          checked={notifyParticipants}
          onChange={e => {
            setValues({
              notifyParticipants: e.target.checked,
            });
          }}
        />
      }
    />
  );
};

const MenuField = ({
  label,
  value,
  onChange,
  items,
}: {
  label: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  items: { key: string; done: boolean; success: boolean }[];
}) => {
  return (
    <TextField
      label={label}
      value={value}
      select={true}
      onChange={onChange}
      size="small"
    >
      {items.map(({ key, done, success }) => (
        <MenuItem key={key} value={JSON.stringify({ done, success })}>
          {key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const ActivityEventDialog = (props: Props) => {
  const now = useNow();
  const { t } = useLocale();
  const { media } = useSystem();
  const responsive = useResponsive();
  const {
    openDialog,
    setOpenDialog,
    disableDialogCollapse,
    form,
    onSubmit,
    onGoBack,
    activityType,
    isUpserting,
    isWorkflow,
    isCreating,
    canEditParent,
    canEditVisibility,
    showUnfinishedWarning,
    allowNotifyParticipants,
    nylasEmail,
    isAdmin,
  } = props;
  const [previousSubject, setPreviousSubject] = React.useState(
    form.values.subject,
  );
  const [expanded, toggleExpand] = React.useReducer(show => !show, false);

  const getDefaultSubject = (
    assignedToUser: null | User,
    targetUsers: User[],
  ) => {
    return getDefaultSubjectUtil({
      t,
      participants: targetUsers
        .filter(
          (u: User) =>
            u.primaryEmail?.email !== assignedToUser?.primaryEmail?.email &&
            u.primaryEmail?.email !== nylasEmail,
        )
        .map(p => ({
          name:
            p.firstName !== '' || p.lastName !== ''
              ? [p.firstName, p.lastName].filter(Boolean).join(' ')
              : p.primaryEmail?.email.split('@')[0],
        })) as { name: string }[],

      assignedTo: {
        firstName: assignedToUser?.firstName ?? null,
        lastName: assignedToUser?.lastName ?? null,
        organisationName: assignedToUser?.organisation?.name ?? null,
      },
      activityType,
    });
  };

  const isDefaultSubject = (assignedToUser: User, targetUsers: User[]) => {
    const subject = getDefaultSubject(assignedToUser, targetUsers);

    return subject === previousSubject || previousSubject === '';
  };

  const refreshDefaultSubject = (
    assignedToUser: null | User,
    targetUsers: User[],
  ) => {
    const subject = getDefaultSubject(assignedToUser, targetUsers);
    form.setValues({
      subject,
    });
    setPreviousSubject(subject);
  };

  let title;
  let unsavedWarning: string;
  switch (activityType) {
    case 'task':
      title = t('Schedule a task');
      unsavedWarning = t('activityTaskNotSavedMessage');
      break;
    case 'call':
      title = t('Schedule a call');
      unsavedWarning = t('activityCallNotSavedMessage');
      break;
    case 'visit':
      title = t('scheduleVisit');
      unsavedWarning = t('activityVisitNotSavedMessage');
  }

  const parentRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (expanded) {
      parentRef.current?.scrollIntoView();
    }
  }, [expanded]);

  let extraDialogActions = null;

  if (onGoBack != null) {
    extraDialogActions = [];
    extraDialogActions.push({
      onClick: onGoBack,
      title: t('Back'),
      icon: <ArrowLeftOutline />,
    });
  }

  const onCancel = () => {
    if (form.changed) {
      if (confirm(unsavedWarning)) {
        props.onCancel();
      }
    } else {
      props.onCancel();
    }
  };

  const actionItems = {
    visit: {
      icon: <Today />,
      items: [
        { key: t('planVisit'), done: false, success: false },
        { key: t('successfulVisit'), done: true, success: true },
        { key: t('cancelledVisit'), done: true, success: false },
      ],
    },
    call: {
      icon: <PhoneOutline />,
      items: [
        { key: t('planCall'), done: false, success: false },
        { key: t('successfulCall'), done: true, success: true },
        { key: t('missedCall'), done: true, success: false },
      ],
    },
  } as const;

  return (
    <CollapsableDialog
      open={openDialog}
      disableCollapse={disableDialogCollapse}
      onCollapse={() => setOpenDialog(false)}
      onClose={onCancel}
      fullScreen={responsive([true, false])}
      title={title ?? ''}
      extraActions={extraDialogActions ?? undefined}
    >
      <DialogContent>
        <Form
          onSubmit={form.submitForm}
          css={media({
            display: 'grid',
            gridColumnGap: [8, 12],
            gridRowGap: 12,
            alignItems: 'center',
            gridTemplateColumns: '24px 1fr',
          })}
        >
          {showUnfinishedWarning && <DialogWarning />}
          <Notes />
          <TextField
            label={t('Subject')}
            value={form.values.subject}
            onChange={v => {
              form.setValues({ subject: v.target.value });
              setPreviousSubject(v.target.value);
            }}
            error={form.errors.subject != null}
            helperText={form.errors.subject}
            size="small"
            required={true}
          />
          {(activityType === 'call' || activityType === 'task') && (
            <>
              <Box
                css={{
                  alignSelf: 'start',
                  height: 48,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AccessTime />
              </Box>
              <div>
                <InlineDateTime
                  values={form.values}
                  setValues={form.setValues}
                  errorText={form.errors.startDate ?? null}
                />
                <DateTimePreset
                  values={form.values}
                  setValues={form.setValues}
                />
              </div>
            </>
          )}
          {activityType === 'visit' && (
            <>
              <Box
                css={media({
                  alignSelf: ['start', 'center'],
                  paddingTop: [8, 0],
                })}
              >
                <AccessTime />
              </Box>
              <TimeRangePicker
                value={form.values.timeRange}
                errorText={form.errors.startDate ?? null}
                onChange={(timeRange, isChangingEndTime) => {
                  const oldDates = activityOfTimeRange(form.values.timeRange);
                  const newDates = activityOfTimeRange(timeRange);
                  let newTimeRange = timeRange;

                  // The total number of days the event spans over
                  const totalDaysOfEvent = differenceInDays(
                    oldDates.dueAt ?? now,
                    oldDates.endAt ?? now,
                  );

                  // Convert to numbers for better comparison below
                  const newStartTime = Number.parseInt(
                    timeRange.startTime.replace(':', ''),
                    10,
                  );
                  const newEndTime = Number.parseInt(
                    timeRange.endTime.replace(':', ''),
                    10,
                  );

                  // If the start and end date are the same and the user selects an end time sooner than the start time
                  // (but in the time picker it means it's the next day) then adjust the endAt to add one day
                  if (
                    isSameDay(newDates.dueAt ?? now, newDates.endAt ?? now) &&
                    newEndTime < newStartTime &&
                    isChangingEndTime
                  ) {
                    newTimeRange = timeRangeOfActivity({
                      dueAt: newDates.dueAt,
                      endAt: addDays(newDates.endAt ?? now, 1),
                    });

                    // Start date is changed to a previous date
                  } else if (
                    newDates.startDate == null &&
                    newDates.dueAt != null &&
                    newDates.endAt != null &&
                    oldDates.dueAt != null &&
                    oldDates.endAt != null &&
                    oldDates.dueAt > newDates.dueAt &&
                    !isSameDay(newDates.dueAt, oldDates.dueAt) &&
                    isBefore(newDates.dueAt, oldDates.dueAt)
                  ) {
                    // Adjust endAt to respect original difference in days
                    const newEndAt = subDays(
                      oldDates.endAt ?? now,
                      // Work out how many days the user has gone back
                      differenceInDays(oldDates.dueAt, newDates.dueAt),
                    );
                    newTimeRange = timeRangeOfActivity({
                      dueAt: newDates.dueAt,
                      endAt: newEndAt,
                    });

                    // Start date has been set after end date or set to same end date
                    // (if current start and end date span more than 1 day)
                  } else if (
                    !isChangingEndTime &&
                    newDates.startDate == null &&
                    newDates.dueAt != null &&
                    newDates.endAt != null &&
                    oldDates.dueAt != null &&
                    (newDates.dueAt > newDates.endAt ||
                      isSameDay(newDates.dueAt, newDates.endAt))
                  ) {
                    const newEndAt =
                      // If the event spans over more than 1 day and the new start date is set to end date,
                      // adjust end date to respect original difference
                      isSameDay(newDates.dueAt, newDates.endAt) &&
                      totalDaysOfEvent > 0
                        ? addDays(oldDates.endAt ?? now, totalDaysOfEvent)
                        : // If a new start date is set after current start date
                        !isSameDay(newDates.dueAt ?? now, oldDates.dueAt ?? now)
                        ? // Work out how many days the user has gone forward
                          addDays(
                            oldDates.endAt ?? now,
                            differenceInDays(
                              newDates.dueAt ?? now,
                              oldDates.dueAt ?? now,
                            ),
                          )
                        : addHours(newDates.dueAt ?? now, 1);

                    newTimeRange = timeRangeOfActivity({
                      dueAt: newDates.dueAt,
                      endAt: newEndAt,
                    });
                  }

                  form.setValues({ timeRange: newTimeRange });
                }}
              />
            </>
          )}
          <PeopleOutline />
          <FieldContacts
            error={form.errors.targetUsers != null}
            helperText={form.errors.targetUsers}
            values={form.values}
            setValues={v => {
              form.setValues(v);
              if (
                isDefaultSubject(
                  form.values.assignedTo,
                  form.values.targetUsers,
                )
              ) {
                refreshDefaultSubject(
                  form.values
                    .assignedTo as Props['form']['values']['assignedTo'],
                  v.targetUsers ?? [],
                );
              }
            }}
          />
          <AssignmentOutline />
          <FieldAssignedTo
            error={form.errors.assignedTo != null}
            helperText={form.errors.assignedTo}
            values={form.values}
            setValues={v => {
              form.setValues(v);
              if (
                isDefaultSubject(
                  form.values.assignedTo,
                  form.values.targetUsers,
                )
              ) {
                refreshDefaultSubject(
                  v.assignedTo ?? null,
                  form.values.targetUsers,
                );
              }
            }}
          />
          <MapMarkerOutline />
          <FieldLocation values={form.values} setValues={form.setValues} />
          {!isWorkflow && (
            <>
              {activityType in actionItems && (
                <>
                  {actionItems[activityType as keyof typeof actionItems].icon}
                  <MenuField
                    label={t('status')}
                    value={form.values.doneSuccess}
                    onChange={event =>
                      form.setValues({ doneSuccess: event.target.value })
                    }
                    items={actionItems[
                      activityType as keyof typeof actionItems
                    ].items.slice()}
                  />
                </>
              )}
              {activityType === 'task' && (
                <Box
                  css={[
                    { '.MuiCheckbox-root': { marginRight: 5 } },
                    { gridColumn: '1/-1' },
                  ]}
                >
                  <FormControlLabel
                    label={t('Task completed')}
                    control={
                      <Checkbox
                        color="primary"
                        checked={JSON.parse(form.values.doneSuccess).done}
                        onChange={event =>
                          form.setValues({
                            doneSuccess: JSON.stringify({
                              done: event.target.checked,
                              success: event.target.checked,
                            }),
                          })
                        }
                      />
                    }
                  />
                </Box>
              )}
            </>
          )}
          {canEditVisibility && (
            <>
              {(form.values.visibility === 'shared' ||
                form.values.visibility === 'organisation') && <LockOpen />}
              {form.values.visibility === 'private' && <Lock />}
              <FieldVisibility
                values={form.values}
                setValues={form.setValues}
              />
            </>
          )}
          <Box pt={2} css={{ gridColumn: '1/-1' }}>
            <Subtitle>{t('notes')}</Subtitle>
            <MarkdownComposer
              initialValue={form.values.note}
              onChange={value => form.setValues({ note: value })}
            />
            {responsive([
              <>
                {allowNotifyParticipants && (
                  <NotifyParticipantsToggle
                    notifyParticipants={form.values.notifyParticipants}
                    setValues={form.setValues}
                  />
                )}
              </>,
              null,
            ])}
            {expanded && (
              <>
                {isAdmin === true && (
                  <Box pt={3} ref={parentRef} css={{ gridColumn: '1/-1' }}>
                    <Subtitle>{t('createdBy')}</Subtitle>
                    <FieldCreatedBy
                      values={form.values}
                      setValues={form.setValues}
                    />
                  </Box>
                )}
                {canEditParent === true && (
                  <Box pt={3} ref={parentRef} css={{ gridColumn: '1/-1' }}>
                    <Subtitle>{t('parent')}</Subtitle>
                    <FieldParent
                      error={form.errors.parent != null}
                      helperText={form.errors.parent}
                      values={form.values}
                      setValues={form.setValues}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        {(isAdmin || canEditParent) && (
          <Box mr="auto">
            <Button onClick={toggleExpand}>
              {expanded ? t('showLess') : t('showMore')}
            </Button>
          </Box>
        )}
        {responsive([
          null,
          <>
            {allowNotifyParticipants && (
              <NotifyParticipantsToggle
                notifyParticipants={form.values.notifyParticipants}
                setValues={form.setValues}
              />
            )}
          </>,
        ])}
        <Button color="inherit" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <ProgressButton
          loading={isUpserting}
          disabled={!form.valid || (activityType === 'task' && !form.changed)}
          onClick={onSubmit}
        >
          {isWorkflow ? t('Confirm') : isCreating ? t('save') : t('create')}
        </ProgressButton>
      </DialogActions>
    </CollapsableDialog>
  );
};
