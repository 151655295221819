import {
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useGetDefaultApolloTemplate } from '../../../utils/template';
import {
  DOC_TEMPLATES_CMA_REPORT,
  UPDATE_CMA_REPORT_DOC_TEMPLATE,
} from '../cmaReportsQueries';
import { type PageId } from '../shared';

import { CMAEditorContext } from './CMAEditorContext';
import CMAReportEditorStepper from './CMAReportEditorStepper';
import CMAReportEditorStepperComponent from './CMAReportEditorStepperComponent';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

export type LayoutProps = {
  editor: ReactNode;
  iFrameRef: MutableRefObject<HTMLIFrameElement>;
};

export const useCMAEditor = () => {
  const context = useContext(CMAEditorContext);
  if (!context) {
    throw new Error('useCMAEditor must be used within CMAEditorProvider');
  }
  return context;
};

const CMAReportEditorWorkflow = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [isStepComponentExpanded, setIsStepComponentExpanded] = useState(true);

  const { setCurrentStepByIndex, cmaReport } = useCMAEditor();

  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const scrollToPageRef = useRef<(pageId: PageId) => void>(() => {});
  const { getDefaultTemplate } = useGetDefaultApolloTemplate();

  const { data: docTemplatesData } = useQuery(DOC_TEMPLATES_CMA_REPORT, {
    skip: cmaReport.doc_template != null,
    fetchPolicy: 'network-only',
  });
  const [updateCmaReport] = useMutation(UPDATE_CMA_REPORT_DOC_TEMPLATE);

  const handleScrollToPage = useCallback(
    (pageId: PageId, index: number) => {
      scrollToPageRef.current(pageId);
      setCurrentStepByIndex(index);
    },
    [setCurrentStepByIndex],
  );

  const [isInitializingTemplate, setIsInitializingTemplate] = useState(
    !cmaReport?.doc_template,
  );

  const initializeTemplate = useCallback(async () => {
    try {
      if (!cmaReport?.doc_template && docTemplatesData?.doc_templates?.length) {
        const defaultTemplateId = getDefaultTemplate(
          docTemplatesData?.doc_templates,
        )?.id;

        if (defaultTemplateId) {
          await updateCmaReport({
            variables: {
              id: cmaReport?.id,
              input: {
                doc_template_id: defaultTemplateId,
              },
            },
            update: (cache, { data: mutationData }) => {
              cache.modify({
                id: cache.identify({
                  __typename: 'cma_reports',
                  id: cmaReport?.id,
                }),
                fields: {
                  doc_template: () =>
                    mutationData?.update_cma_reports_by_pk?.doc_template,
                },
              });
            },
          });
        }
      }
    } finally {
      setIsInitializingTemplate(false);
    }
  }, [
    cmaReport?.doc_template,
    cmaReport?.id,
    docTemplatesData?.doc_templates,
    getDefaultTemplate,
    updateCmaReport,
    setIsInitializingTemplate,
  ]);

  // If the doc_template is not set, fetch the default template and update the cma_report
  useEffect(() => {
    initializeTemplate();
  }, [initializeTemplate]);

  const editor = (
    <CMAReportEditorStepper
      setIsStepComponentExpanded={setIsStepComponentExpanded}
      isStepComponentExpanded={isStepComponentExpanded}
    >
      <CMAReportEditorStepperComponent
        iFrameRef={iFrameRef}
        handleScrollToPage={handleScrollToPage}
        isExpanded={isStepComponentExpanded}
        setIsExpanded={setIsStepComponentExpanded}
      />
    </CMAReportEditorStepper>
  );

  const layoutProps: LayoutProps = {
    editor,
    iFrameRef: iFrameRef as MutableRefObject<HTMLIFrameElement>,
  };

  if (isInitializingTemplate) {
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center" p={3}>
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return isTablet ? (
    <MobileLayout {...layoutProps} />
  ) : (
    <DesktopLayout {...layoutProps} scrollToPageRef={scrollToPageRef} />
  );
};

export default CMAReportEditorWorkflow;
