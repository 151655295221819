import { ApolloError } from '@apollo/client';
import type { Translate } from '@realadvisor/translator/engine';
import type { FieldPath } from 'react-hook-form';

export interface IFormError<
  TFieldValues extends Record<string, any> = Record<string, any>,
> {
  field: FieldPath<TFieldValues> | `root.${string}` | 'root';
  errorMessage: string;
}

const extractFieldFromError = <
  TFieldValues extends Record<string, any> = Record<string, any>,
>(
  errorMsg: string,
  regex: RegExp,
  formDataFields: TFieldValues,
): FieldPath<TFieldValues> | null => {
  const indexPart = regex.exec(errorMsg)?.[1];

  if (!indexPart) {
    return null;
  }

  const matchingKeys = Object.keys(formDataFields).filter(key =>
    indexPart.includes(key),
  ) as FieldPath<TFieldValues>[];

  // If we have multiple matching keys, we can't know which one is the culprit.
  return matchingKeys.length === 1 ? matchingKeys[0] : null;
};

export const extractMessageFromError = <
  TFieldValues extends Record<string, any> = Record<string, any>,
>(
  error: Error,
  formDataFields: TFieldValues,
  t: Translate,
): IFormError<TFieldValues>[] => {
  const defaultError: IFormError<TFieldValues> = {
    field: 'root',
    errorMessage: 'defaultError',
  };

  if (!(error instanceof ApolloError)) {
    return [defaultError];
  }

  const errors: IFormError<TFieldValues>[] = [];

  error.graphQLErrors.forEach(graphQLError => {
    const msg = graphQLError.message;

    if (msg.includes('duplicate key')) {
      // Message in the form: "Uniqueness violation. duplicate key value violates unique constraint \"unique_agency_slug_index\""
      const fieldName = extractFieldFromError(msg, /"(.*)"/, formDataFields);

      if (fieldName) {
        errors.push({
          field: fieldName,
          errorMessage: t('The value entered for this field is already used'),
        });
      }
    } else if (msg.includes('check constraint')) {
      // Message in the form: "Check constraint violation. new row for relation \"teams\" violates check constraint \"teams_agency_slug_check\""
      const fieldName = extractFieldFromError(
        msg,
        /"([a-zA-Z0-9_-]+)"$/,
        formDataFields,
      );

      if (fieldName) {
        errors.push({
          field: fieldName,
          errorMessage: t('The value entered for this field is not valid'),
        });
      }
    }
  });

  return errors.length > 0 ? errors : [defaultError];
};

export enum ZodIssueCode {
  invalid_type = 'invalid_type',
  custom = 'custom',
  invalid_union = 'invalid_union',
  invalid_enum_value = 'invalid_enum_value',
  unrecognized_keys = 'unrecognized_keys',
  invalid_arguments = 'invalid_arguments',
  invalid_return_type = 'invalid_return_type',
  invalid_date = 'invalid_date',
  invalid_string = 'invalid_string',
  too_small = 'too_small',
  too_big = 'too_big',
}

export type ZodError = {
  path: (string | number)[];
  message: string;
  code: ZodIssueCode;
} & Record<string, any>;

export const isZodError = (error: any): error is ZodError => {
  return 'path' in error && 'message' in error && 'code' in error;
};
