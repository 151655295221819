import { type ReactNode, useCallback, useMemo } from 'react';

import Edit from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import {
  Box,
  Divider,
  Paper,
  type SxProps,
  Tab,
  Tabs,
  type Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useConstant } from '@realadvisor/hooks';
import { createPortal } from 'react-dom';

import { useLocale } from '../../../../src/hooks/locale';
import { customPalette } from '../../../../src/styles';

import { useCMAEditor } from './CMAReportEditorWorkflow';

export type ResponsiveActionsLayoutProps = {
  children?: ReactNode;
};

const ResponsiveActionsLayout = ({
  children,
}: ResponsiveActionsLayoutProps) => {
  const { t } = useLocale();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const portalElement = useConstant(() => {
    const elem = document.createElement('div');
    document.body.appendChild(elem);
    return elem;
  });
  const { mobileTab, setMobileTab } = useCMAEditor();

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, value: 'viewer' | 'editor') => {
      setMobileTab(value);
    },
    [setMobileTab],
  );

  const tabStyles: SxProps<Theme> = useMemo(
    () => ({
      '.MuiButtonBase-root': {
        textTransform: 'none',
        minWidth: '0',
      },
      '.MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0 0 3px 3px',
        top: 0,
      },
      '.Mui-selected': {
        color: theme.palette.common.black,
        fontWeight: '500',
      },
    }),
    [theme.palette],
  );

  if (!isTablet) {
    return (
      <>
        <Divider />
        {children}
      </>
    );
  }

  return (
    <>
      <Box height={3} />
      {createPortal(
        <Paper
          square
          elevation={4}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: customPalette.superLightBlue,
            zIndex: 1300, // override parent dialog
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Tabs sx={tabStyles} value={mobileTab} onChange={handleTabChange}>
              <Tab
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Edit fontSize="small" sx={{ marginRight: 1 }} />
                    {t('editor')}
                  </Box>
                }
                value="editor"
              />
              <Tab
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Visibility fontSize="small" sx={{ marginRight: 1 }} />
                    {t('preview')}
                  </Box>
                }
                value="viewer"
              />
            </Tabs>
          </Box>
          <Divider />
          {children}
        </Paper>,
        portalElement,
      )}
    </>
  );
};

export default ResponsiveActionsLayout;
