import { useCallback } from 'react';

import { useFragment as useApolloFragment } from '@apollo/client';
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { Controller, type UseFormSetError } from 'react-hook-form';

import { useDebouncedHandler } from '../../../src/hooks/debounce';
import { useLocale } from '../../../src/hooks/locale';
import { getCurrencyByCountryCode } from '../../../src/locale';
import {
  type FormDefinitionType,
  RaForm,
  type RaFormOnChange,
} from '../../components/form/RaForm';

import { STEP_FINAL_FRAGMENT } from './cmaReportsQueries';
import {
  type CMAReportComponentProps,
  FooterActions,
  useUpdateCmaReport,
} from './shared';

export const PriceTypeTabs = () => {
  const { t } = useLocale();
  return (
    <Controller
      name="is_price_range"
      defaultValue={false}
      render={({ field: { value, onChange } }) => (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value ? 1 : 0}
            onChange={(_, tabValue) => onChange(tabValue === 1)}
          >
            <Tab sx={{ flexGrow: 1 }} label={t('Single Value')} value={0} />
            <Tab sx={{ flexGrow: 1 }} label={t('Range')} value={1} />
          </Tabs>
        </Box>
      )}
    />
  );
};

type StepFinalForm = {
  positive_comments: string;
  negative_comments: string;
  suggested_market_value?: number | null;
  is_price_range: boolean;
  price_range_min?: number | null;
  price_range_max?: number | null;
  show_probability: boolean;
  probability_min: number;
  probability_max: number;
  probability_time_period: number;
};

const StepFinal = (props: CMAReportComponentProps) => {
  const { cmaReportId } = props;

  const { data, complete } = useApolloFragment({
    fragment: STEP_FINAL_FRAGMENT,
    fragmentName: 'StepFinal',
    from: {
      __typename: 'cma_reports',
      id: cmaReportId,
    },
  });
  const [updateCmaReport, updating] = useUpdateCmaReport(
    cmaReportId,
    'page-final',
  );
  const { countryCode } = useLocale();
  const { t } = useLocale();

  const stepFinalFormDefinition: FormDefinitionType<StepFinalForm> =
    useCallback(
      ({ t }) => [
        {
          type: 'category-title',
          name: 'positives',
          label: t('positives'),
          gridProps: { md: 12 },
        },
        {
          name: 'positive_comments',
          label: t('positives'),
          type: 'rich-text',
          gridProps: { md: 12 },
        },
        {
          type: 'category-title',
          name: 'negatives',
          label: t('negatives'),
          gridProps: { md: 12 },
        },
        {
          name: 'negative_comments',
          label: t('negatives'),
          type: 'rich-text',
          gridProps: { md: 12 },
        },
        {
          type: 'custom',
          name: 'price_type',
          element: <PriceTypeTabs />,
          gridProps: { md: 12 },
        },
        {
          name: 'suggested_market_value',
          prefix: getCurrencyByCountryCode(countryCode),
          label: t('suggestedMarketValue'),
          type: 'number',
          gridProps: { md: 12 },
          render: data => !data.is_price_range,
        },
        {
          name: 'price_range_min',
          prefix: getCurrencyByCountryCode(countryCode),
          label: t('Min'),
          type: 'number',
          gridProps: { md: 12 },
          render: data => data.is_price_range,
        },
        {
          name: 'price_range_max',
          prefix: getCurrencyByCountryCode(countryCode),
          label: t('Max'),
          type: 'number',
          gridProps: { md: 12 },
          render: data => data.is_price_range,
        },
        {
          name: 'show_probability',
          label: t('Show probability to sell'),
          type: 'checkbox',
          style: 'switch',
          gridProps: { md: 12 },
          render: data => data.is_price_range,
        },
        {
          name: 'probability_min',
          label: t('Probability at minimum price'),
          type: 'number',
          suffix: '%',
          required: true,
          gridProps: { md: 12 },
          min: 0,
          max: 100,
          render: data => data.is_price_range && data.show_probability,
        },
        {
          name: 'probability_max',
          label: t('Probability at maximum price'),
          type: 'number',
          suffix: '%',
          required: true,
          gridProps: { md: 12 },
          min: 0,
          max: 100,
          render: data => data.is_price_range && data.show_probability,
        },
        {
          name: 'probability_time_period',
          label: t('Time period (months)'),
          type: 'number',
          required: true,
          suffix: t('months'),
          gridProps: { md: 12 },
          min: 1,
          max: 12,
          render: data => data.is_price_range && data.show_probability,
        },
      ],
      [countryCode],
    );

  const update = useCallback(
    async (formData?: Partial<StepFinalForm>) => {
      await updateCmaReport({
        positive_comments: formData?.positive_comments,
        negative_comments: formData?.negative_comments,
        suggested_market_value: formData?.suggested_market_value,
        is_price_range: formData?.is_price_range ?? false,
        price_range_min: formData?.price_range_min,
        price_range_max: formData?.price_range_max,
        show_probability: formData?.show_probability ?? false,
        probability_min: formData?.probability_min,
        probability_max: formData?.probability_max,
        probability_time_period: formData?.probability_time_period,
      });
    },
    [updateCmaReport],
  );
  const debouncedUpdate = useDebouncedHandler(300, update);

  const onChangeHandler: RaFormOnChange<StepFinalForm> = useCallback(
    (formData, _, { runValidation }) => {
      if (runValidation()) {
        debouncedUpdate(formData);
      }
    },
    [debouncedUpdate],
  );

  const onSubmit = useCallback(
    async (formData: StepFinalForm) => {
      await update(formData);
      props.setStep(props.step + 1);
    },
    [update, props],
  );

  return !complete ? (
    <Stack gap={2}>
      <Stack gap={2}>
        {Array.from({ length: 2 }).map((_, index) => (
          <Skeleton variant="rounded" key={`feedback-${index}`} height={150} />
        ))}
      </Stack>
      <Stack gap={1}>
        <Skeleton variant="rounded" height={40} width={'40%'} />
        <Skeleton variant="rounded" height={40} />
      </Stack>
    </Stack>
  ) : (
    <RaForm
      freezeInitialDefaultValues={true}
      formDefinition={stepFinalFormDefinition}
      onSubmit={onSubmit}
      contentScrollable
      defaultValues={{
        positive_comments: data?.positive_comments ?? '',
        negative_comments: data?.negative_comments ?? '',
        suggested_market_value: data?.suggested_market_value,
        is_price_range: data?.is_price_range ?? false,
        price_range_min: data?.price_range_min,
        price_range_max: data?.price_range_max,
        show_probability: data?.show_probability ?? false,
        probability_min: data?.probability_min ?? 95,
        probability_max: data?.probability_max ?? 5,
        probability_time_period: data?.probability_time_period ?? 6,
      }}
      onChange={onChangeHandler}
      actionButtonsComponent={<FooterActions {...props} updating={updating} />}
      validate={(data: StepFinalForm) => {
        const errors: Parameters<UseFormSetError<StepFinalForm>>[] = [];

        if (data.is_price_range) {
          if (data.price_range_min != null && data.price_range_max != null) {
            if (data.price_range_min > data.price_range_max) {
              errors.push([
                'price_range_min',
                {
                  message: t(
                    'Minimum price must be less or equal to maximum price',
                  ),
                },
              ]);
            }
          }
        }

        if (data.show_probability) {
          if (data.probability_min < data.probability_max) {
            errors.push([
              'probability_min',
              {
                message: t(
                  'Minimum probability must be greater or equal to maximum probability',
                ),
              },
            ]);
          }
        }

        return errors;
      }}
    />
  );
};

export default StepFinal;
