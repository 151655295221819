import { type ReactNode, useCallback, useEffect, useRef } from 'react';

import { Box, keyframes, useMediaQuery, useTheme } from '@mui/material';

import { customPalette } from '../../../../src/styles';

type InteractiveStepContainerProps = {
  children: ReactNode;
  onInteraction: () => void;
  isExpanded: boolean;
};

const InteractiveStepContainer = ({
  children,
  onInteraction,
  isExpanded,
}: InteractiveStepContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const handleInteraction = useCallback(
    (e: Event) => {
      const target = e.target as HTMLElement;
      const cardElement = target.closest('[data-card-element]');

      if (cardElement) {
        return;
      }

      requestAnimationFrame(onInteraction);
    },
    [onInteraction],
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // We need to target the form inside the container without the footer buttons
    // If we use footer with RAForm, target the form > div
    // Otherwise, footer without RAForm
    const editorFormRef =
      containerRef.current.querySelector('form > div') ??
      containerRef.current.querySelector(
        'div[data-step-container] > div > div',
      );

    if (editorFormRef) {
      editorFormRef.addEventListener('click', handleInteraction);
      editorFormRef.addEventListener('keydown', handleInteraction);
    }
    return () => {
      if (editorFormRef) {
        editorFormRef.removeEventListener('click', handleInteraction);
        editorFormRef.removeEventListener('keydown', handleInteraction);
      }
    };
  }, [handleInteraction]);

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        width: isMobile ? '100%' : isExpanded ? '476px' : '0px',
        overflow: 'hidden',
        position: 'relative',
        transition: 'all 0.5s ease',
        backgroundColor: customPalette.superLightBlue,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '300px',
          height: '100%',
          transform: isExpanded ? 'translateX(0)' : 'translateX(-476px)',
          transition: 'transform 0.5s ease',
          animation: `${fadeIn} 0.5s ease`,
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default InteractiveStepContainer;
