import { useMemo } from 'react';

import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';

import ActionBox, { ACTION_BOX_HEIGHT } from './ActionBox';
import type { LayoutProps } from './CMAReportEditorWorkflow';
import useCMATemplateRenderer from './useCMATemplateRenderer';
import useMarkupRenderer from './useMarkupRenderer';

const MobilePreview = ({ iFrameRef }: LayoutProps) => {
  const { markup, dataReady, loading } = useCMATemplateRenderer();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  useMarkupRenderer(iFrameRef, markup);

  const iframeStyles = useMemo(
    () => ({
      position: 'absolute' as const,
      height: `calc(100% - ${isMobile ? ACTION_BOX_HEIGHT : 0}px)`,
      width: '100%',
      zIndex: 0,
      overflow: 'hidden',
      border: 0,
      filter: dataReady ? 'none' : 'brightness(0.8) grayscale(1)',
      transition: 'filter 1s ease',
      pointerEvents: dataReady ? ('initial' as const) : ('none' as const),
    }),
    [dataReady, isMobile],
  );

  return (
    <>
      {(!dataReady || loading) && (
        <LinearProgress
          variant="indeterminate"
          color="primary"
          sx={{
            zIndex: 2,
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            opacity: dataReady ? 0.4 : 1,
            height: 6,
          }}
        />
      )}
      <ActionBox iFrameRef={iFrameRef} />
      <iframe
        ref={iFrameRef}
        title="CMA Report Mobile Preview"
        style={iframeStyles}
        sandbox="allow-same-origin allow-scripts allow-modals"
      />
    </>
  );
};

export default MobilePreview;
