import { memo, useCallback, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { toGlobalId } from '../../../shared/global-id';
import { useLocale } from '../../../src/hooks/locale';
import { gql } from '../../__generated__';
import {
  type CreateListingMutationVariables,
  type GetListingDetailsQuery,
  type Lots_Set_Input,
  type Properties_Set_Input,
} from '../../__generated__/graphql';
import { HorizontalScrollContainer } from '../../pages/cma-reports/shared';
import {
  CREATE_LISTING,
  GET_LISTING_DETAILS,
  UPDATE_LISTING_WITH_PROPERTY,
} from '../../pages/listings/lotsQueries';
import { useAppData } from '../../providers/AppDataProvider';

import { ListingAvailabilityStep } from './ListingAvailabilityStep';
import { ListingFeaturesStep } from './ListingFeaturesStep';
import { ListingImagesStep } from './ListingImagesStep';
import { ListingPublicationStep } from './ListingPublicationStep';
import {
  ListingDescriptionStep,
  ListingMandateStep,
  ListingPriceStep,
} from './ListingRaFormStep';
import { ListingStepperFooterActions } from './ListingStepperFooterActions';
import { PropertyAdressStep } from './PropertyAdressStep';
import { PropertyDetailsStep } from './PropertyDetailsStep';

type ListingCreationStepId =
  | 'address'
  | 'details'
  | 'price'
  | 'mandate'
  | 'features'
  | 'description'
  | 'photos'
  | 'availability'
  | 'publication';

export type StepFormSubmit<ReturnType = any> = (
  formData:
    | {
        lot?: Lots_Set_Input;
        property?: Properties_Set_Input;
        action: 'update';
      }
    | (CreateListingMutationVariables & { action: 'create' }),
  finish?: boolean,
  skipUpsert?: boolean,
) => Promise<ReturnType>;

export interface ListingCreationStepComponentProps {
  listing: GetListingDetailsQuery['lots_by_pk'];
  footerActionsComponent: React.ReactNode;
  onFormSubmitted: StepFormSubmit;
}

export type ListingCreationStepComponent =
  React.FC<ListingCreationStepComponentProps>;

interface ListingCreationStep {
  id: ListingCreationStepId;
  label: string;
  optional: boolean;
  path: string;
  component: ListingCreationStepComponent;
}

interface ListingStepperNavProps {
  currentStep: string;
  steps: ListingCreationStep[];
  completedSteps: Set<string>;
  onStepClicked: (stepPath: string) => void;
  canGoToSteps: boolean;
  stepsToHide: string[];
}

const ListingStepperNav: React.FC<ListingStepperNavProps> = memo(
  ({
    currentStep,
    steps,
    completedSteps,
    onStepClicked,
    canGoToSteps,
    stepsToHide,
  }) => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const visibleSteps = steps.filter(({ id }) => !stepsToHide.includes(id));
    const activeStep = visibleSteps.findIndex(
      step => step.path === currentStep,
    );

    return (
      <Paper square={true} variant="outlined">
        <Stepper
          activeStep={activeStep}
          nonLinear
          alternativeLabel
          component={HorizontalScrollContainer}
          height={isMobile ? 90 : 100}
          nodeIndex={activeStep}
        >
          {visibleSteps.map(({ label, path }, index) => (
            <Step
              key={label}
              completed={completedSteps.has(path)}
              sx={{ minWidth: '90px', mt: 1 }}
            >
              <StepButton
                onClick={() => onStepClicked(path)}
                disabled={!canGoToSteps}
              >
                <StepLabel
                  sx={{
                    '& .MuiStepLabel-label': {
                      mt: { xs: 1, sm: 1.5 },
                      fontWeight: activeStep === index ? 'bold' : 'normal',
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Paper>
    );
  },
);

export const CreateListingStepper: React.FC<{
  developmentId?: string;
  onListingCreated?: (newListingId: string) => void;
}> = ({ developmentId, onListingCreated }) => {
  const { t } = useLocale();
  const urlParams = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { me } = useAppData();
  const { createStep, listingId } = urlParams;
  const [completedSteps, setCompletedSteps] = useState(new Set<string>());
  const [snackBarDisplayed, setSnackBarDisplayed] = useState(false);

  const [createListing] = useMutation(CREATE_LISTING);
  const [updateListing] = useMutation(UPDATE_LISTING_WITH_PROPERTY);

  // refoactor to use default when implemented in dictionnaries
  const origins = useQuery(
    gql(`query GetLotOrigins {
      origins: dictionaries(where: { type: { _eq: lot_origin_types } }) {
        id
        name
      }
    }
  `),
  );

  const defaultOrigin = origins.data?.origins.find(
    origin => origin.name === 'sourced_by_broker',
  );

  const steps: ListingCreationStep[] = useMemo(
    () => [
      {
        id: 'address',
        label: t('Address'),
        optional: false,
        path: '',
        component: PropertyAdressStep,
      },
      {
        id: 'details',
        label: t('Details'),
        optional: true,
        path: 'details',
        component: PropertyDetailsStep,
      },
      {
        id: 'price',
        label: t('Price'),
        optional: true,
        path: 'price',
        component: ListingPriceStep,
      },
      {
        id: 'mandate',
        label: t('Mandate'),
        optional: true,
        path: 'mandate',
        component: ListingMandateStep,
      },
      {
        id: 'features',
        label: t('Features'),
        optional: true,
        path: 'features',
        component: ListingFeaturesStep,
      },
      {
        id: 'description',
        label: t('Description'),
        optional: true,
        path: 'description',
        component: ListingDescriptionStep,
      },
      {
        id: 'photos',
        label: t('Photos'),
        optional: true,
        path: 'photos',
        component: ListingImagesStep,
      },
      {
        id: 'availability',
        label: t('Availability'),
        optional: true,
        path: 'availability',
        component: ListingAvailabilityStep,
      },
      {
        id: 'publication',
        label: t('Publication'),
        optional: true,
        path: 'publication',
        component: ListingPublicationStep,
      },
    ],
    [t],
  );

  const currentStepIndex = steps.findIndex(
    step => step.id === (createStep ?? 'address'),
  );

  const {
    data: listingData,
    error,
    loading,
  } = useQuery<GetListingDetailsQuery>(GET_LISTING_DETAILS, {
    variables: { id: listingId },
    skip: currentStepIndex === -1 || listingId == null,
  });

  const upsertListing: StepFormSubmit<{ id: string } | null> = useCallback(
    async (data, finish = false) => {
      if (currentStepIndex === 0 && data.action === 'create') {
        const { data: createListingData } = await createListing({
          variables: {
            lot: {
              ...data.lot,
              broker_id: me?.id,
              created_by: me?.id,
              origin_id: defaultOrigin?.id,
              development_id: developmentId,
              dummy_living_surface:
                me?.tenant.country_code === 'ES'
                  ? 'built_surface'
                  : 'living_surface',
            },
          },
          onError: () => {
            setSnackBarDisplayed(true);
          },
        });

        if (createListingData?.insert_lots_derived == null) {
          return null;
        }

        onListingCreated?.(createListingData.insert_lots_derived.id);

        return { id: createListingData.insert_lots_derived.id };
      } else if (listingData?.lots_by_pk != null && data.action === 'update') {
        // Update for all step > 1 and for the first step if listing already created.
        // We do not await the update to have a fast transition.
        const updatePromise = updateListing({
          variables: {
            id: listingData.lots_by_pk.id,
            prop_id: listingData.lots_by_pk.property.id,
            lot: data.lot ?? {},
            property: data.property ?? {},
          },
          onError: () => {
            setSnackBarDisplayed(true);
          },
        });

        if (finish) {
          // Only await for the last step to update before navigating out.
          await updatePromise;
        }

        return { id: listingData.lots_by_pk?.id };
      }

      // Should never happen.
      return null;
    },
    [
      createListing,
      updateListing,
      listingData,
      currentStepIndex,
      defaultOrigin?.id,
      developmentId,
      me?.id,
      me?.tenant.country_code,
      onListingCreated,
    ],
  );

  const onFormSubmitted: StepFormSubmit = useCallback(
    async (data, finish = false, skipUpsert = false) => {
      if (finish || currentStepIndex + 1 >= steps.length) {
        let listingIdToNavigate: string | null = listingId ?? null;

        if (!skipUpsert) {
          const result = await upsertListing(data, true);

          if (result == null) {
            return;
          }

          listingIdToNavigate = result.id;
        }

        navigate({
          pathname:
            developmentId != null
              ? `/developments/${toGlobalId(
                  'Development',
                  developmentId,
                )}/lots/${listingIdToNavigate}`
              : `/listings/${listingIdToNavigate}`,
          search: searchParams.toString(),
        });

        return;
      }

      let listingIdToNavigate: string | null = null;

      if (!skipUpsert) {
        const result = await upsertListing(data);

        if (result == null) {
          return;
        }

        listingIdToNavigate = result.id;
      }

      const nextPath = steps[currentStepIndex + 1].path;
      const currentPath = steps[currentStepIndex].path;

      setCompletedSteps(
        prevCompleted => new Set([...prevCompleted, currentPath]),
      );

      const listingIdPathParam =
        data.action === 'create' && listingIdToNavigate != null
          ? `${listingIdToNavigate}/`
          : '';

      navigate({
        pathname:
          currentStepIndex === 0
            ? `${listingIdPathParam}${nextPath}`
            : `./../${nextPath}`,
        search: searchParams.toString(),
      });
    },
    [
      upsertListing,
      navigate,
      searchParams,
      currentStepIndex,
      listingId,
      steps,
      developmentId,
    ],
  );

  if (currentStepIndex === -1 || (currentStepIndex > 0 && listingId == null)) {
    return null;
  }

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <CircularProgress disableShrink />
      </Grid>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }

  if (listingId != null && listingData?.lots_by_pk == null) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {t('Listing not found')}
      </Alert>
    );
  }

  const currentStep = steps[currentStepIndex];
  const CurrentStepComponent = currentStep.component;

  const handleBack = () => {
    if (currentStepIndex === 0) {
      return;
    }

    const prevPath = steps[currentStepIndex - 1].path;

    navigate({
      pathname: `./../${prevPath}`,
      search: searchParams.toString(),
    });
  };

  const handleGoToStep = (path: string) => {
    if (listingId == null) {
      return;
    }

    navigate({
      pathname: currentStepIndex === 0 ? path : `./../${path}`,
      search: searchParams.toString(),
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
      }}
    >
      <ListingStepperNav
        currentStep={currentStep.path}
        completedSteps={completedSteps}
        steps={steps}
        onStepClicked={handleGoToStep}
        canGoToSteps={listingId != null}
        stepsToHide={
          listingData?.lots_by_pk?.property.__property_type?.main_type !==
          'PROP'
            ? []
            : ['features']
        }
      />
      <Box sx={{ flex: 1, minHeight: 0, overflow: 'hidden' }}>
        <CurrentStepComponent
          listing={listingData?.lots_by_pk}
          onFormSubmitted={onFormSubmitted}
          footerActionsComponent={
            <ListingStepperFooterActions
              canNavigateBack={currentStepIndex > 0}
              isLastStep={currentStepIndex === steps.length - 1}
              onBtnBackClicked={handleBack}
            />
          }
        />
      </Box>
      <Snackbar
        open={snackBarDisplayed}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnackBarDisplayed(false)}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {listingId == null
            ? t('An error occured while creating the listing')
            : t('An error occured while updating the listing')}
        </Alert>
      </Snackbar>
    </Box>
  );
};
