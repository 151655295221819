import { useCallback, useEffect } from 'react';

import AddLocationAltOutlined from '@mui/icons-material/AddLocationAltOutlined';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import { useTheme } from '@mui/material';
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useLocale } from '../../../../src/hooks/locale';
import { Drawer } from '../../../components/drawer/Drawer';
import {
  type DrawerTab,
  generateTab,
} from '../../../components/drawer/DrawerTopBar';

import CreateCMAFromLead from './CreateCMAFromLead';
import CreateCMAFromScratch from './CreateCMAFromScratch';
import { useCreateCMAReport } from './utils';

type CreateCMAReportProps = {
  onClose: () => void;
  onCreated: (newCMAReportId: string) => void;
};

const CreateCMAReport = ({ onClose, onCreated }: CreateCMAReportProps) => {
  const { t } = useLocale();
  const { breakpoints } = useTheme();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const leadId = searchParams.get('id');
  const createCMAReport = useCreateCMAReport();

  const tabsDef: [string, string, React.ReactElement?][] = [
    [t('From scratch'), 'scratch', <AddLocationAltOutlined />],
    [t('From lead'), 'lead', <HomeWorkOutlined />],
  ];

  const tabs: DrawerTab[] = tabsDef.map(([label, value, icon]) =>
    generateTab([searchParams.toString(), label, value, icon]),
  );

  const handleCreateCMA = useCallback(
    async (leadId: string, contactId?: string) => {
      const newCMAReportId = await createCMAReport({
        leadId,
        contactId,
      });

      if (newCMAReportId) {
        onCreated(newCMAReportId);
      } else {
        throw new Error('Failed to create CMA report: No ID returned');
      }
    },
    [createCMAReport, onCreated],
  );

  useEffect(() => {
    if (leadId) {
      handleCreateCMA(leadId);
    }
  }, [leadId, handleCreateCMA]);

  // If the leadId is in the URL, we don't need to render anything
  if (leadId) {
    return null;
  }

  return (
    <Drawer
      onClose={onClose}
      title={t('Create CMA Report')}
      tabs={tabs}
      currentTab={params?.['*'] ? params?.['*'] : 'scratch'}
      maxWidth={breakpoints.values.md}
    >
      <Routes>
        <Route
          index
          element={
            <Navigate
              to={{ pathname: 'scratch', search: searchParams.toString() }}
            />
          }
        />
        <Route
          path="scratch"
          element={
            <CreateCMAFromScratch onClose={onClose} onCreated={onCreated} />
          }
        />
        <Route
          path="lead"
          element={
            <CreateCMAFromLead
              onClose={onClose}
              handleCreateCMA={handleCreateCMA}
            />
          }
        />
      </Routes>
    </Drawer>
  );
};

export default CreateCMAReport;
