import { type Dispatch, type SetStateAction, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Close from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Card, CircularProgress, IconButton, useTheme } from '@mui/material';

import {
  DELETE_CMA_REPORTS_COMPARABLES,
  STEP_LISTINGS_COMPARE_FRAGMENT,
} from '../cmaReportsQueries';

import ListingCardInfo from './ListingCardInfo';
import { type ComparableListing } from './StepListingsCompare';

type ListingCardDraggableProps = {
  cmaReportId: string;
  id: number;
  index: number;
  listing: ComparableListing;
  setListings: Dispatch<SetStateAction<ComparableListing[]>>;
};

const ListingCardDraggable = ({
  cmaReportId,
  id,
  index,
  listing,
  setListings,
}: ListingCardDraggableProps) => {
  const {
    isDragging,
    setNodeRef,
    transform,
    transition,
    listeners,
    attributes,
  } = useSortable({ id });
  const { palette } = useTheme();
  const offline = listing.offline_since != null;

  const [deleteCmaReportsComparables, { loading: deleteLoading }] = useMutation(
    DELETE_CMA_REPORTS_COMPARABLES,
  );

  const handleRemove = useCallback(
    async (listingId: number) => {
      await deleteCmaReportsComparables({
        variables: {
          cma_report_id: cmaReportId ?? '',
          listing_id: listingId,
        },
        update: (cache, { data: newData }) => {
          const id = cache.identify({
            __typename: 'cma_reports',
            id: cmaReportId,
          });
          const updatedListings =
            newData?.delete_cma_reports_comparables?.returning[0]?.cma_report
              ?.comparable_listings ?? [];

          const existingData = cache.readFragment({
            fragment: STEP_LISTINGS_COMPARE_FRAGMENT,
            fragmentName: 'StepListingsCompare',
            id,
          });

          const mergedData = {
            ...existingData,
            updated_at: newData?.update_cma_reports_by_pk?.updated_at,
            comparable_listings: updatedListings,
          };

          cache.writeFragment({
            fragment: STEP_LISTINGS_COMPARE_FRAGMENT,
            fragmentName: 'StepListingsCompare',
            id,
            data: mergedData,
          });
        },
        onCompleted: () => {
          setListings(prevListings =>
            prevListings.filter(listing => listing.id !== listingId),
          );
        },
      });
    },
    [cmaReportId, deleteCmaReportsComparables, setListings],
  );
  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'pointer',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    >
      {!isDragging && (
        <div
          css={{
            position: 'absolute',
            top: -12,
            left: -12,
            height: 30,
            width: 30,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 600,
            borderRadius: '50%',
            color: '#fff',
            background: offline ? palette.error.main : palette.success.main,
            border: '3px #fff solid',
            zIndex: 1,
          }}
        >
          {index + 1}
        </div>
      )}
      <Card
        data-card-element
        css={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          background: '#fff',
        }}
        elevation={isDragging ? 8 : 2}
      >
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            right: 2,
            paddingBottom: 2,
            display: 'inline-flex',
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
        >
          <IconButton size="small" {...attributes} {...listeners}>
            <DragIndicatorIcon
              css={{
                transform: 'rotate(90deg)',
                cursor: isDragging ? 'grabbing' : 'grab',
              }}
            />
          </IconButton>
        </div>
        <div
          css={{
            position: 'absolute',
            top: 2,
            right: 2,
            padding: 0,
            display: 'inline-flex',
          }}
        >
          <IconButton
            size="small"
            onClick={async evt => {
              evt.stopPropagation();
              evt.preventDefault();
              await handleRemove(listing.id);
            }}
            onMouseDown={evt => {
              evt.stopPropagation();
            }}
            disabled={deleteLoading}
          >
            {deleteLoading ? (
              <CircularProgress
                sx={{ color: palette.text.secondary, opacity: 0.5 }}
                size={20}
              />
            ) : (
              <Close />
            )}
          </IconButton>
        </div>
        <ListingCardInfo listing={listing} variant="horizontal" />
      </Card>
    </div>
  );
};

export default ListingCardDraggable;
