/**
 * @generated SignedSource<<8d7204adf666981aa9e9159b9de8b15f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LeadStageStatus = "active" | "lost" | "won";
export type activityWorkflowDialogUpdateLeadMutation$variables = {
  id: string;
  stageId: string;
};
export type activityWorkflowDialogUpdateLeadMutation$data = {
  readonly upsertLead: {
    readonly lead: {
      readonly id: string;
      readonly stage: {
        readonly id: string;
        readonly status: LeadStageStatus;
      } | null;
    } | null;
  } | null;
};
export type activityWorkflowDialogUpdateLeadMutation = {
  response: activityWorkflowDialogUpdateLeadMutation$data;
  variables: activityWorkflowDialogUpdateLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stageId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "id"
              },
              {
                "kind": "Variable",
                "name": "stageId",
                "variableName": "stageId"
              }
            ],
            "kind": "ObjectValue",
            "name": "lead"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "UpsertLeadPayload",
    "kind": "LinkedField",
    "name": "upsertLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "lead",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadStage",
            "kind": "LinkedField",
            "name": "stage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f461ef11fedb47843591bba4373564fe",
    "id": null,
    "metadata": {},
    "name": "activityWorkflowDialogUpdateLeadMutation",
    "operationKind": "mutation",
    "text": "mutation activityWorkflowDialogUpdateLeadMutation(\n  $id: ID!\n  $stageId: ID!\n) {\n  upsertLead(input: {lead: {id: $id, stageId: $stageId}}) {\n    lead {\n      id\n      stage {\n        id\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d5a788d627a9e0635a5fe6cf41bec93";

export default node;
