import { Alert, type AlertColor, Portal, Snackbar } from '@mui/material';

type SnackbarProps = {
  open: boolean;
  message: string;
  onClose: () => void;
  severity?: AlertColor;
  autoHideDuration?: number;
};

const RASnackbar = ({
  open,
  message,
  onClose,
  severity = 'error',
  autoHideDuration = 5000,
}: SnackbarProps) => (
  <Portal>
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  </Portal>
);

export default RASnackbar;
