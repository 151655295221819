import * as React from 'react';

import { sanitizeHtml } from '@realadvisor/html-sanitizer';
import { setupRenderer } from '@realadvisor/template-renderer';
import { useSearchParams } from 'react-router-dom';

import { GOOGLE_MAPS_TOKEN } from '../../config';
import { useLocale } from '../../hooks/locale';
import { type Language, getLocale } from '../../locale';
import { createDataUrl } from '../../utils/url';

type Props = {
  key?: number;
  documentId: null | string;
  content: string;
  filenameTemplate: string;
  countryCode: string;
  documentType: null | string;
  translations: Record<Language, Record<string, string>>;
};

export function useTemplateRenderer(props: Props): {
  filename: null | string;
  markup: null | string;
  dataReady: boolean;
  loading: boolean;
} {
  const {
    key,
    documentId,
    content,
    filenameTemplate,
    countryCode,
    documentType,
    translations,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [markup, setMarkup] = React.useState<null | string>(null);
  const [filename, setFilename] = React.useState<null | string>(null);
  const [data, setData] = React.useState(null);
  const { language } = useLocale();
  const [searchParams] = useSearchParams();

  const overrideLanguage = (searchParams.get('language') ??
    language) as Language;

  const { render } = React.useMemo(
    () =>
      setupRenderer({
        mainLocale: getLocale(countryCode, overrideLanguage, true),
        dateFnsLocale: overrideLanguage,
        translations: translations[overrideLanguage],
        sanitize: str =>
          sanitizeHtml(str, {
            tagsBlacklist: ['script'],
            attrsBlacklist: ['onclick'],
            attrsWithScheme: ['href'],
            allowedSchemes: ['http', 'https', 'mailto', 'tel'],
            selfClosingTags: [],
          }),
      }),
    [countryCode, overrideLanguage, translations],
  );

  React.useEffect(() => {
    if (documentId != null && documentType != null) {
      setLoading(true);
      const url = createDataUrl(documentId, documentType, overrideLanguage);
      const controller = new AbortController();
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        signal: controller.signal,
      })
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.error != null) {
            console.error(result.error);
          } else {
            setData(result.data);
            setLoading(false);
          }
        })
        .catch(console.error);
      return () => {
        controller.abort();
      };
    }
  }, [key, documentId, documentType, overrideLanguage]);

  // render template
  React.useEffect(() => {
    let mounted = true;
    render({
      content,
      data,
      meta: {
        title: 'Document draft',
        language,
        googleMapsChannel: 'crm',
        googleMapsKey: GOOGLE_MAPS_TOKEN,
      },
    })
      .then(markup => {
        if (mounted) {
          setMarkup(markup);
        }
      })
      // TODO expose error
      .catch(() => {});
    return () => {
      mounted = false;
    };
  }, [render, data, content, language]);

  // render filename
  React.useEffect(() => {
    let mounted = true;
    render({
      content: filenameTemplate,
      data,
      meta: {
        language,
      },
    })
      .then(filename => {
        if (mounted) {
          setFilename(filename);
        }
      })
      // TODO expose error
      .catch(() => {});
    return () => {
      mounted = false;
    };
  }, [render, data, filenameTemplate, language]);

  return { markup, filename, dataReady: data != null, loading };
}
